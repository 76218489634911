import React, { Component } from "react";
import { SeriesNav } from "./Series";
import axios from "axios";
import { hyphen2space, canonicalLink } from "./HelperFunctions";
import { getCDNUrl } from "./HelperFunctions";
import { Globals } from "./constants";

import { Helmet } from "react-helmet";

class Squads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesTitle: "",
      isLoading: true,
      teams: [],
      selectedTeam: -1,
      selectedTeamPlayers: [],
    };
  }

  getSelectedTeamPlayers = async (id) => {
    this.setState({ selectedTeam: id, isLoading: true });
    try {
      const URL = `${Globals.ApiUrl}api/v4/series/${
        this.props.match.params.id
      }/squad/${id}`;
      const resp = await axios.get(URL);
      if (resp.status === 200) {
        this.setState({ selectedTeamPlayers: resp.data });
      }
    } catch (e) {
      console.log("Error in fetching team players", e.message);
      this.setState({ selectedTeamPlayers: [] });
    }
    this.setState({ isLoading: false });
  };

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let seriesTitle = this.props.match.params.title;
    this.setState({ seriesTitle: seriesTitle });

    try {
      const url =
        Globals.ApiUrl +
        "api/v4/series/" +
        this.props.match.params.id +
        "/series_teams?app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
              ? Globals.appName
              : Globals.appName
        }`;
      let response = await axios.get(url);
      if (response.status === 200) {
        response = response.data.series_teams;
        this.setState({
          teams: response,
          selectedTeam: response[0].id,
          isLoading: false,
        });
        this.getSelectedTeamPlayers(response[0].id);
        let elem = document.getElementsByClassName("none-message");
        if (elem.length > 0) {
          elem[0].classList.remove("d-none");
        }
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <section id="main-section" className="px-lg-0 jc-inner-page">
        <Helmet>
          <title>
            {hyphen2space(this.state.seriesTitle)} Squads for All Teams -{" "}
            {Globals.product}
          </title>
          <meta
            name="description"
            content={
              "Check out the" +
              hyphen2space(this.state.seriesTitle) +
              " squads for all teams only on " +
              Globals.product
            }
          />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div>
            <div className="card p-3">
              <h1 className="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">
                {hyphen2space(this.state.seriesTitle)} Squads
              </h1>
              <SeriesNav path={this.props.match} />
            </div>
            <div className="card mt-2 p-3">
              <div className="d-flex overflow-x-auto py-3 border-bottom">
                {this.state.teams &&
                  this.state.teams.map((t) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.getSelectedTeamPlayers(t.id);
                        }}
                      >
                        <p
                          className={`font-weight-semi-bold p-2 ml-4 text-capitalize  ${
                            t.id === this.state.selectedTeam
                              ? "  border-bottom border-danger"
                              : "text-secondary"
                          }`}
                          style={{ fontSize: "1.5rem" }}
                        >
                          {" "}
                          <img src={t.team.full_flag_url} width="20px" />{" "}
                          {t.team.short_name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              {this.state.selectedTeamPlayers.length > 0 ? (
                <div className="pt-4 row">
                  {this.state.selectedTeamPlayers &&
                    this.state.selectedTeamPlayers.map((p) => {
                      return getPlayersCard(p);
                    })}
                </div>
              ) : (
                <h2 className="mt-4 text-center text-uppercase">
                  No squad available
                </h2>
              )}
            </div>
          </div>
        )}
      </section>
    );
  }
}

function getPlayersCard(p) {
  return (
    <div className="d-flex col-sm-12 col-md-6 py-4">
      <div className="w-45">
        <div
          className="one-by-one-square bg-center-cover"
          style={{ backgroundImage: "url(" + p.full_display_picture_url + ")" }}
        />
      </div>
      <div className="pl-3">
        <p className="mb-0" style={{ fontSize: "1.25rem", fontWeight: "600" }}>
          {p.name}
        </p>
        <p className="mb-1">{p.is_captain ? "Captain" : ""}</p>
        <p className="mb-1">{p.is_wicket_keeper ? "Wicket Keeper" : ""}</p>
        <p className="mb-1">
          {!p.is_wicket_keeper && !p.is_captain ? p.playingrole : ""}
        </p>

        <p className="text-secondary mb-0 font-sm">
          Age <span className="text-black"> {p.age} </span>{" "}
        </p>

        <p className="text-secondary mb-0 font-sm">Batting </p>
        <p className="mb-1 font-sm">{p.batting}</p>
      </div>
    </div>
  );
}

export default Squads;
