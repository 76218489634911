import React, { Component } from "react";
import axios from "axios";
import { getCDNUrl, loginUser } from "./HelperFunctions";
import { Globals } from "./constants";
const queryString = require("query-string");

class Cgauth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      btnTxt: "Continue",
      btnDisbaled: false,
      redirection: "login",
    };
    this.login = this.continue.bind(this);
    this.apiPath = Globals.NodeApi + "api/";
    // this.apiPath = "http://api.cricboom.com/userapi/";
  }

  async componentWillMount() {
    let cgObject = JSON.parse(localStorage.getItem("cgObject")),
      redirectTo = "/login",
      logRequest = { cgObject: cgObject, params: this.props.location.search };
    // axios.get(this.apiPath+"log?function=auth_callback&req="+JSON.stringify(logRequest));
    setTimeout(async () => {
      if (cgObject) {
        let params = queryString.parse(this.props.location.search);
        let phone = "";
        let serverResponse = {};

        if (cgObject.telco === "du") {
          // const parsed = queryString.parse(params);
          if (params.MSISDN) {
            phone = params.MSISDN;

            if (
              params.Result === "FAIL" &&
              params.Reason !== "User_is_already_subscribed"
            ) {
              if (params.Reason === "User_running_with_Low_balance") {
                this.setState({
                  message:
                    "You do not have enough balance. Please recharge and try again.",
                });
              } else if (params.Reason === "user_given_incorrect_input") {
                this.setState({
                  message:
                    "The value you entered was incorrect. Please try again.",
                });
              } else if (params.Reason === "Success_denied_by_user") {
                this.setState({
                  message:
                    "Success denied by user. Please recharge and try again.",
                });
              } else if (params.Reason === "Consent_Request_Timeout") {
                this.setState({
                  message: "The request timeout. Please try again.",
                });
              } else {
                this.setState({
                  message: "Something went wrong. Please try again.",
                });
              }

              let generic_response = {};
              generic_response.call_type = "CGStatus";
              generic_response.subscribed = 1;
              generic_response.phone = phone;
              generic_response.telco = "zain";
              localStorage.setItem("user_logged_in", true);
              localStorage.setItem("user", JSON.stringify(generic_response));
              loginUser(generic_response);
              this.setState({
                redirect: cgObject.referer,
              });
              // window.location.href = cgObject.referer;
              // return false;//remove this
              redirectTo = cgObject.referer;
            } else if (
              params.Result === "SUCCESS" ||
              (params.Result === "FAIL" &&
                params.Reason === "User_is_already_subscribed")
            ) {
              try {
                const url =
                  this.apiPath +
                  "appstart?telco=" +
                  cgObject.telco +
                  "&phone=" +
                  phone +
                  "&app_name=" +
                  `${
                    localStorage.getItem("domain")
                      ? localStorage.getItem("domain")
                      : Globals.telco
                        ? Globals.appName
                        : Globals.appName
                  }`;
                // const url = this.apiPath + "appstart?telco=zain&phone="+phone;
                serverResponse = await axios.get(url);
                serverResponse = serverResponse.data;
              } catch (err) {
                console.log("authentication Error logging in", err);
              }

              if (serverResponse) {
                // axios.get(this.apiPath+"log?function=auth_callback_appstart&req="+JSON.stringify(logRequest)+"&res="+JSON.stringify(serverResponse));
                let generic_response = {};
                if (
                  Object.keys(serverResponse).length === 0 &&
                  serverResponse.constructor === Object
                ) {
                } else if (!serverResponse.status) {
                } else {
                  generic_response = serverResponse.generic_response;
                }
                if (generic_response.subscribed) {
                  generic_response.phone = phone;
                  generic_response.telco = "zain";
                  localStorage.setItem("user_logged_in", true);
                  localStorage.setItem(
                    "user",
                    JSON.stringify(generic_response)
                  );
                  loginUser(generic_response);

                  // window.location.href = cgObject.referer;
                  this.setState({
                    redirect: cgObject.referer,
                  });
                  // return false;//remove this
                  redirectTo = cgObject.referer;
                }
                if (params.Reason === "User_is_already_subscribed") {
                  this.setState({
                    message: "You are already subscribed!",
                  });
                } else {
                  this.setState({
                    message:
                      "Congratulations! You are successfully subscribed!",
                  });
                }
              } else {
                console.log("authentication no server response");
              }
            }
          } else {
            console.log("authentication", "no msisdn");
          }
        } else {
          console.log("authentication invalid telco");
        }
      } else {
        console.log("authentication cgobject not found");
      }

      // localStorage.removeItem('cgObject');
      // window.location.href=redirectTo;
      this.state.redirection = redirectTo;
      console.log("authentication redirect to ", redirectTo);
    }, 5000);
  }

  async continue() {
    const phone = this.state.phone;
    let serverResponse = {};
    this.setState({
      btnTxt: "Please Wait ...",
      btnDisbaled: true,
      message: "",
    });
    window.location.href = this.state.redirect;
  }

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        {this.state.message.trim() == "" ? (
          <div className="main-loader card">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
            <h1 className="pb-3 text-black border-bottom mb-5 font-weight-semi-bold m-f-14">
              {this.state.message}
            </h1>
            <div>
              <p className="heading pt-2 pb-0 mb2 mt-lg-4 text-black fs-1p2 m-f-13">
                {this.state.message}
              </p>
              <button
                className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
                onClick={this.login}
                disabled={this.state.btnDisbaled}
              >
                {this.state.btnTxt}
              </button>
            </div>
          </div>
        )}
        <a
          href="javascript:;"
          className="app-download my-lg-5 py-lg-5 mt-5"
          onClick={this.props.downloadApp}
        >
          <img className="w-100" src={Globals.login.image} alt="" />
        </a>
      </section>
    );
  }
}

export default Cgauth;
