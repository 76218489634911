import React, { Component } from "react";
import { Globals } from "./constants";
import { DGlobals } from "./dConstants";
import Axios from "axios";
import { getUnsubPath } from "./HelperFunctions";
import { Helmet } from "react-helmet";
import { canonicalLink } from "./HelperFunctions";

class UnSub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlPath: "",
      telco: "",
      secondServer: Globals.secondServer ? true : false,
      selectedTelco: "Choose operator",
      inputName: "phone",
      inputValue: "",
      inputName1: "",
      inputValue1: "",
      phone: "",
      pin: "",

      btnText: "CONTINUE",
      btnDisbaled: false,
      message: "",
      serverResponse: null,
      pinSent: false,

      wait: false, // not implemented

      loginPhonePlaceholder: "",
      loginPinPlaceholder: "",
      loginEnterPin: "",
      loginInvalidPhone: "",
      regex: "",
      sucessRedirectFromDigital: "",
      telcoDisclamerText: [],

      globalTelco: null,

      modal: false,
    };

    this.handleKeypress = this.handleKeypress.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    // this.enableDisableField  = this.enableDisableField.bind();
    this.toggle = this.toggle.bind(this);

    this.textInput = React.createRef();
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handlePhoneChange = (e) => {
    this.setState({ inputValue: e.target.value });
    // if(this.state.ph_number.length !== 12){
    //     this.setState({ message: '' });
    // }
  };

  componentDidMount = async () => {
    let telcoName = this.props.match.params.telco;
    if (telcoName === "tp") {
      telcoName = "telenor";
    } else if (Globals.telco === "mobilink") {
      let heFetch = await fetch(
        `http://cricket.jazz.com.pk/test/get_header_phone/`
      );
      let heResp = await heFetch.json(); // {status: 0/1, phone: '03xxxxxxxxx'}

      this.setState({ btnDisbaled: true, btnTxt: "Please wait ..." });

      if (heResp.status === 1) {
        var MSISDN = heResp.phone;

        this.setState({
          // isHE: true,
          inputValue: MSISDN,
          phone: MSISDN,
        });
      }
    }
    this.setState(() => {
      return { telco: telcoName };
    });
    this.setTelco(telcoName);
  };

  componentWillReceiveProps = async (nextProps) => {
    let telcoName = nextProps.match.params.telco;
    if (telcoName === "tp") {
      telcoName = "telenor";
    }
    this.setState(() => {
      return { telco: telcoName };
    });
    this.setTelco(telcoName);
  };

  handleKeypress = function(e) {
    if (e.key === "Enter") {
      this.handleUnsub();
    }
  };

  setTelco = async (telco) => {
    var globalTelco = DGlobals.Telcos.filter((x) => x.telco === telco);

    await this.setState(() => {
      return {
        globalTelco: globalTelco[0],
        selectedTelco: telco.name,
        loginPhonePlaceholder: globalTelco[0].loginPhonePlaceholder,
        loginPinPlaceholder: globalTelco[0].loginPinPlaceholder,
        loginEnterPin: globalTelco[0].loginEnterPin,
        loginInvalidPhone: globalTelco[0].loginInvalidPhone,
        loginPinPlaceholder: globalTelco[0].loginPinPlaceholder,
        regex: globalTelco[0].regex,
        sucessRedirectFromDigital: globalTelco[0].sucessRedirectFromDigital,
        isSelectedTelco: true,
      };
    });
  };

  getUnSubStatus = async () => {
    const telco = this.state.telco;
    const phone = this.state.inputValue;
    const secondServer = this.state.secondServer;
    const user = { telco: telco, phone: phone, secondServer: secondServer };

    let heStartFetch = await fetch(
      Globals.NodeApi +
        `appstart?web_user=1&phone=${user.phone}&telco=${user.telco}`
    );
    let heStartResp = await heStartFetch.json();
    let authToken = heStartResp.generic_response.authToken;

    if (heStartResp.generic_response.subscribed) {
      let resp = {},
        url = getUnsubPath(user);
      try {
        resp = await Axios.get(url.path, {
          headers: {
            "auth-token": authToken,
          },
        });
        alert("User successfully unsubscribed!.");
        this.logout();
      } catch (err) {
        this.setState(() => {
          return {
            message: "Some thing went wrong....",
            btnText: "CONTINUE",
            btnDisbaled: false,
          };
        });
      }
    } else {
      alert("you are already unsubscribed!.");
      this.setState(() => {
        return {
          btnText: "CONTINUE",
          btnDisbaled: false,
        };
      });
    }
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_logged_in");
    window.location.href = "/";
  };

  handleUnsub = async () => {
    const {
      inputValue,
      regex,
      loginInvalidPhone,
      globalTelco,
      sucessRedirectFromDigital,
    } = this.state;
    this.setState(() => {
      return { btnText: "Please Wait....", btnDisbaled: true };
    });

    console.log(this.state.btnText);

    if (!globalTelco) {
      this.setState(() => {
        return {
          message: " Please select telco",
          btnText: "CONTINUE",
          btnDisbaled: false,
        };
      });
      return;
    }

    if (inputValue.trim().length === 0) {
      this.setState(() => {
        return {
          message: loginInvalidPhone,
          btnText: "CONTINUE",
          btnDisbaled: false,
        };
      });
      return;
    }

    let result = this.validatePhone(inputValue.replace(/ /g, ""), regex);

    if (!result) {
      this.setState(() => {
        return {
          message: loginInvalidPhone,
          btnText: "CONTINUE",
          btnDisbaled: false,
        };
      });
      return;
    }

    await this.getUnSubStatus();
  };

  validatePhone(phoneNumber, regexString) {
    if (!phoneNumber) {
      return false;
    } else if (!regexString.test(phoneNumber)) {
      return false;
    } else {
      return true;
    }
  }

  //==================================

  renderLogin = () => {
    const {
      loginPhonePlaceholder,
      inputName,
      inputValue,
      message,
    } = this.state;

    return (
      <div>
        <div className={this.state.showPhone}>
          <input
            name={inputName}
            className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h2 m-0"
            type="text"
            ref={this.textInput}
            placeholder={loginPhonePlaceholder}
            value={inputValue}
            onChange={this.handlePhoneChange}
            onKeyPress={this.handleKeypress}
          />

          <p className="m-0 message mt-2 text-danger">{message}</p>

          <input type="hidden" name="flow" value={this.state.task} />

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.handleUnsub}
            disabled={
              this.validatePhone(inputValue.replace(/ /g, ""), this.state.regex)
                ? false
                : this.state.btnDisbaled
            }
          >
            {this.state.btnText}
          </button>
        </div>
      </div>
    );
  };
  //=====================================================

  render() {
    return (
      <div id="main-section">
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
          <h1 className="pb-3 text-black border-bottom mb-5 font-weight-semi-bold m-f-14">
            User Unsubscribe{" "}
          </h1>

          <div>{this.renderLogin()}</div>

          <a
            href="javascript:;"
            className="app-download my-lg-5 py-lg-5 mt-5"
            onClick={this.props.downloadApp}
          >
            <img className="w-100" src={Globals.login.image} alt="" />
          </a>
        </div>
      </div>
    );
  }
}

export default UnSub;
