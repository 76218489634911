import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import { SeriesNav } from "./Series";

import BackgroundImage from "react-background-image-loader";
import ReactJWPlayer from "react-jw-player";

import JWPlayerOnScrollViewResize from "./JWPlayerOnScrollViewResize";

import {
  getVideoPath,
  VideoDescriptionCardNew,
  VideoDescriptionCardTopOne,
  hyphen2space,
  space2hyphen,
  space2hyphenForSlug,
  updatedCanonical,
  getCDNUrl,
} from "./HelperFunctions";

import { Globals } from "./constants";

class SingleVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      apiPath: Globals.ApiUrl + "api/v4/",
      currentVideo: {},
      videos: [],
      videoId: this.props.match.params.videoId,
      playlistId: this.props.match.params.playlistId,
      seriesId: this.props.match.params.id,
      videoType: "",
      page: 0,
      pagesEnd: false,
      playlistTitle: "",
      totalPlaylistVideos: 0,
      msisdn: "",
      seriesTitle: this.props.match.params.title,
    };

    this.setCurrentVideo = this.setCurrentVideo.bind(this);

    this.playerReady = this.playerReady.bind(this);
    this.videoLoad = this.videoLoad.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.onLikeClicked = this.onLikeClicked.bind(this);

    this.isMobile = window.innerWidth < 992;

    if (localStorage.getItem("minVideoLength"))
      this.minVideoLength = localStorage.getItem("minVideoLength");
  }

  componentWillMount() {
    if (this.props.msisdn && this.props.msisdn.length > 0) {
      this.setState({ msisdn: this.props.msisdn });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        this.setState({ msisdn: user.phone });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.videoId !== this.props.match.params.videoId) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.setCurrentVideo(nextProps.match.params.videoId);
    }
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.loadVideos().then((response) => {
      let that = this;
      if (
        response.status === 200 &&
        response.data &&
        ((that.props.isHighlights && response.data) || response.data.resp)
      ) {
        if (that.state.videoType === "playlist") {
          let playlist = response.data.resp;
          let playListVideos = [];

          if (playlist.play_list_items.length > 0) {
            playListVideos = playlist.play_list_items;
          }

          that.setState({
            isLoading: false,
            videos: that.state.videos.concat(playListVideos),
            playlistTitle: playlist.title,
            totalPlaylistVideos: playlist.total_videos,
          });
        } else if (that.state.videoType === "featured") {
          let featured = response.data.resp;
          let featuredVideos = [];

          if (featured.length > 0) {
            featuredVideos = response.data.resp;
          }

          that.setState({
            isLoading: false,
            videos: that.state.videos.concat(featuredVideos),
          });
        } else if (that.state.videoType === "series") {
          let series = response.data.resp;
          let seriesVideos = [];

          if (series.length > 0) {
            seriesVideos = response.data.resp;
          }

          this.setState({
            isLoading: false,
            videos: that.state.videos.concat(seriesVideos),
          });
        } else if (that.state.videoType === "timeline") {
          let timeline = response.data.videos;
          let timelineVideos = [];

          if (timeline.length > 0) {
            timelineVideos = response.data.videos;
          }

          that.setState({
            isLoading: false,
            videos: that.state.videos.concat(timelineVideos),
          });
        }

        if (that.state.videoId) {
          that.setCurrentVideo(that.state.videoId);
        } else if (
          that.state.videoType === "series" &&
          that.state.videos.length > 0 &&
          that.state.videos[0].video
        ) {
          that.setCurrentVideo(that.state.videos[0].video.id);
        }
      } else {
        window.location.href = "/404notfound";
      }
    });
  }

  loadVideos() {
    let apiPath = this.state.apiPath;
    let page = this.state.page + 1;
    let videoListApiURL = "";

    if (this.state.playlistId && this.state.playlistId > 0) {
      // videoListApiURL = apiPath +
      //                     'video_lists/play_video_list?list_id=2&type=play_list&play_list_id=' + this.state.playlistId +
      //                     '&page=' + page + '&msisdn=' + this.state.msisdn + "&app_name=" + Globals.appName;
      videoListApiURL =
        apiPath +
        "video_lists/play_video_list?list_id=2&type=play_list&play_list_id=" +
        this.state.playlistId +
        "&page=" +
        page +
        "&msisdn=00000000000&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
              ? Globals.appName
              : Globals.appName
        }`;
      this.setState({
        videoType: "playlist",
        page: page,
      });
    } else if (this.state.seriesId && this.state.seriesId > 0) {
      //   videoListApiURL =
      //     apiPath +
      //     "video_lists/play_video_list?list_id=" +
      //     this.state.seriesId +
      //     "&type=series&page=" +
      //     page +
      //     "&msisdn=" +
      //     this.state.msisdn +
      //     "&web_user=1&app_name=" +
      //     Globals.appName;
      videoListApiURL =
        apiPath +
        "video_lists/play_video_list?list_id=" +
        this.state.seriesId +
        "&type=series&page=" +
        page +
        "&msisdn=00000000000&web_user=1&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
              ? Globals.appName
              : Globals.appName
        }`;
      this.setState({
        videoType: "series",
        page: page,
      });
    } else if (this.props.isHighlights) {
      videoListApiURL =
        "https://back.cricwick.net/api/v1/video/get_by_video_type/timeline/" +
        page +
        "/10?telco=" +
        this.props.telco +
        "&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
              ? Globals.appName
              : Globals.appName
        }`;
      this.setState({
        videoType: "timeline",
        page: page,
      });
    } else {
      //   videoListApiURL =
      //     apiPath +
      //     "video_lists/play_video_list?list_id=1&type=featured&page=" +
      //     page +
      //     "&msisdn=" +
      //     this.state.msisdn +
      //     "&app_name=" +
      //     Globals.appName;

      videoListApiURL =
        apiPath +
        "video_lists/play_video_list?list_id=1&type=featured&page=" +
        page +
        "&msisdn=00000000000&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
              ? Globals.appName
              : Globals.appName
        }`;

      this.setState({
        videoType: "featured",
        page: page,
      });
    }

    try {
      return axios.get(videoListApiURL);
    } catch {
      return [];
    }
  }

  loadSelectedVideo() {
    let apiPath = this.state.apiPath;
    let singleVideoApiURL =
      apiPath +
      "videos/" +
      this.state.videoId +
      "/get_single_video?msisdn=" +
      this.state.msisdn +
      "&app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;

    try {
      return axios.get(singleVideoApiURL);
    } catch {
      return [];
    }
  }

  setCurrentVideo(newVideoId) {
    let currentVideo;
    this.state.videos.forEach((video) => {
      if (this.state.videoType !== "timeline") {
        if (video.video.id === parseInt(newVideoId)) {
          currentVideo = video;
        }
      } else {
        if (video.id === parseInt(newVideoId)) {
          currentVideo = video;
        }
      }
    });

    if (currentVideo) {
      if (this.state.videoType === "timeline") {
        let obj = {};
        obj.video = currentVideo;
        this.setState({
          currentVideo: obj,
        });
      } else {
        this.setState({
          currentVideo: currentVideo,
        });
      }
    } else {
      this.loadSelectedVideo().then((response) => {
        if (response.status === 200 && response.data && response.data.video) {
          this.setState({
            currentVideo: response.data,
          });
        } else {
          window.location.href = "/404notfound";
        }
      });
    }
  }

  playerReady(e) {
    this.setWatchCount();

    if (!this.isMobile) {
      JWPlayerOnScrollViewResize();
      document.getElementsByClassName("player-container")[0].style.height =
        "336px";
    }
  }

  videoLoad(e) {
    if (e.index !== 0) {
      const videosCopy = this.state.videos.slice(1, this.state.videos.length);
      this.setState({ videos: videosCopy });
    }
  }

  onLikeClicked(e) {
    let isUserLiked = this.state.currentVideo.video.user_liked;

    this.setState((prevState) => ({
      ...prevState,
      currentVideo: {
        ...prevState.currentVideo,
        video: {
          ...prevState.currentVideo.video,
          user_liked: !isUserLiked,
        },
      },
    }));

    this.setLikeUnlike();
  }

  setLikeUnlike() {
    let apiPath = this.state.apiPath;
    let currentVideo = this.state.currentVideo.video;

    let operation = currentVideo.user_liked ? "unlike" : "like";

    let apiURL =
      apiPath +
      "videos/" +
      currentVideo.id +
      "/like_unlike_video?msisdn=" +
      this.state.msisdn +
      "&operation=" +
      operation +
      "&app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;

    try {
      axios.post(apiURL).then((response) => {
        if (response.status === 200) {
          this.setState((prevState) => ({
            ...prevState,
            currentVideo: {
              ...prevState.currentVideo,
              video: {
                ...prevState.currentVideo.video,
                user_liked: operation === "like" ? true : false,
                likes:
                  operation === "like"
                    ? currentVideo.likes + 1
                    : currentVideo.likes - 1,
              },
            },
          }));
        }
      });
    } catch {
      console.log("err");
    }
  }

  setWatchCount() {
    let apiPath = this.state.apiPath;
    let currentVideo = this.state.currentVideo.video;

    let apiURL =
      apiPath +
      "videos/" +
      currentVideo.id +
      "/update_views?msisdn=" +
      this.state.msisdn +
      "&app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;

    try {
      axios.post(apiURL).then((response) => {});
    } catch {
      console.log("err");
    }
  }

  async nextPage(e) {
    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName("loading-more-results");

      if (elem.length) {
        elem[0].classList.remove("d-none");
      }

      this.loadVideos().then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          ((this.props.isHighlights && response.data) || response.data.resp)
        ) {
          if (elem.length) {
            elem[0].classList.add("d-none");
          }

          if (this.state.videoType === "playlist") {
            let playlist = response.data.resp;
            let playListVideos = [];

            if (playlist.play_list_items.length > 0) {
              playListVideos = playlist.play_list_items;

              this.setState({
                videos: this.state.videos.concat(playListVideos),
              });
            } else if (playlist.play_list_items.length === 0) {
              this.setState({ pagesEnd: true });
            }
          } else if (this.state.videoType === "featured") {
            let featured = response.data.resp;
            let featuredVideos = [];

            if (featured.length > 0) {
              featuredVideos = response.data.resp;

              this.setState({
                videos: this.state.videos.concat(featuredVideos),
              });
            } else if (featured.length === 0) {
              this.setState({ pagesEnd: true });
            }
          } else if (this.state.videoType === "series") {
            let series = response.data.resp;
            let seriesVideos = [];

            if (series.length > 0) {
              seriesVideos = response.data.resp;

              this.setState({
                videos: this.state.videos.concat(seriesVideos),
              });
            } else if (series.length === 0) {
              this.setState({ pagesEnd: true });
            }
          } else if (this.state.videoType === "timeline") {
            let timeline = response.data.videos;
            let timelineVideos = [];

            if (timeline.length > 0) {
              timelineVideos = response.data.videos;
              this.setState({
                videos: this.state.videos.concat(timelineVideos),
              });
            } else if (timeline.length === 0) {
              this.setState({ pagesEnd: true });
            }
          }
        } else {
          if (elem.length) {
            elem[0].classList.add("d-none");
          }
        }
      });
    }
  }

  getISOTime = (secsToConvert) => {
    if (!secsToConvert || isNaN(secsToConvert) || secsToConvert < 0)
      return `PT0H0M0S`;
    return `PT${parseInt(secsToConvert / 3600)}H${parseInt(
      (secsToConvert % 3600) / 60
    )}M${parseInt((secsToConvert % 3600) % 60)}S`;
  };

  render() {
    let playlist = [],
      title = "",
      description = "",
      date = "",
      image = "";

    if (this.state.videos.length) {
      playlist = this.state.videos.map(
        (obj) =>
          obj
            ? {
                file: obj.video
                  ? obj.video.qualities.video_file
                  : obj.video_file,
              }
            : ""
      );
      title = this.state.currentVideo.video
        ? this.state.currentVideo.video.title
        : this.state.currentVideo.title;
      description = this.state.currentVideo.video
        ? this.state.currentVideo.video.match_desc
        : this.state.currentVideo.description;
      date = this.state.currentVideo.video
        ? this.state.currentVideo.video.created_at
        : this.state.currentVideo.created_at;
      image = this.state.currentVideo.video
        ? this.state.currentVideo.video.thumb
        : this.state.currentVideo.thumb_image;
      console.clear();
      console.log(title);
    }

    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        {this.state.currentVideo.video ? (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "VideoObject",
                name: `${this.state.currentVideo.video.title}`,
                description: `${this.state.currentVideo.video.description}`,
                thumbnailUrl: [this.state.currentVideo.video.thumb],
                contentUrl: getVideoPath(this.state.currentVideo.video),
                interactionStatistic: {
                  "@type": "InteractionCounter",
                  interactionType: { "@type": "WatchAction" },
                  userInteractionCount: 5647018,
                },
                uploadDate: new Date(
                  this.state.currentVideo.video.created_at
                ).toISOString(),
                duration: this.getISOTime(
                  this.state.currentVideo.video.duration
                ),
              })}
            </script>
          </Helmet>
        ) : null}
        <Helmet>
          <meta property="og:url" content={"Cricket!"} />
          <meta property="og:type" content="video" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
        {this.state.videoType === "series" && this.state.seriesId !== "1452" ? (
          <Helmet>
            <title>
              {hyphen2space(this.state.seriesTitle)} Videos - {Globals.product}
            </title>
            <meta
              name="description"
              content={
                "Check out the" +
                hyphen2space(this.state.seriesTitle) +
                " videos with the best coverage only on " +
                Globals.product
              }
            />
            <link rel="canonical" href={updatedCanonical(title)} />
          </Helmet>
        ) : this.state.videoType === "series" &&
        this.state.seriesId === "1452" ? (
          <Helmet>
            <title>Watch KPL 2022 Highlights for all matches on CricWick</title>
            <meta
              name="description"
              content={"Watch KPL 2022 Highlights for all matches on CricWick"}
            />
            <link rel="canonical" href={updatedCanonical(title)} />
          </Helmet>
        ) : this.state.videoType === "playlist" ||
        this.state.videoType === "featured" ||
        this.state.videoType === "timeline" ? (
          <Helmet>
            <title>
              {hyphen2space(this.state.seriesTitle)} - {Globals.product}
            </title>
            <meta
              name="description"
              content={
                Globals.product +
                "is the Prime Destination for Watching Match Highlights, Interviews, Special Features, Latest Events and Many More Cricket Videos. Stay Tuned"
              }
            />
            <link rel="canonical" href={updatedCanonical(title)} />
          </Helmet>
        ) : (
          ""
        )}

        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div />
        )}

        <div className="card p-3">
          {this.state.videoType === "playlist" ? (
            <h2 className={"font-weight-bold p-2 m-f-14"}>
              {this.state.playlistTitle}
            </h2>
          ) : (
            ""
          )}
          {this.state.videoType === "featured" ? (
            <h2 className={"font-weight-bold p-2 m-f-14"}>Featured</h2>
          ) : (
            ""
          )}
          {this.state.videoType === "series" ? (
            <>
              <h2 className={"font-weight-bold p-2 m-f-14"}>
                {hyphen2space(this.state.seriesTitle)}
              </h2>
              <SeriesNav path={this.props.match} />
            </>
          ) : (
            ""
          )}
          {this.state.videoType === "timeline" ? (
            <h2 className={"font-weight-bold p-2 m-f-14"}>Video Highlights</h2>
          ) : (
            ""
          )}
        </div>

        <div className="card p-3 mt-2">
          {this.state.videoType === "timeline" ? (
            this.state.videos.length > 0 &&
            this.state.currentVideo &&
            this.state.currentVideo.video ? (
              <div className="player-container">
                <div className="player-position">
                  <ReactJWPlayer
                    playerId="player"
                    playerScript="https://content.jwplatform.com/libraries/Spb3cNmq.js"
                    file={getVideoPath(this.state.currentVideo.video)}
                    onReady={this.playerReady}
                    onVideoLoad={this.videoLoad}
                  />
                </div>
              </div>
            ) : (
              ""
            )
          ) : this.state.videos.length > 0 && this.state.currentVideo.video ? (
            <div className="player-container">
              <div className="player-position">
                <ReactJWPlayer
                  playerId="player"
                  playerScript="https://content.jwplatform.com/libraries/Spb3cNmq.js"
                  file={getVideoPath(this.state.currentVideo.video)}
                  onReady={this.playerReady}
                  onVideoLoad={this.videoLoad}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="px-2 pt-3 pb-0 d-flex">
            {this.state.videoType === "timeline" ? (
              this.state.videos.length > 0 &&
              (this.state.currentVideo && this.state.currentVideo.video) ? (
                <VideoDescriptionCardTopOne
                  title={this.state.currentVideo.video.title}
                  description={this.state.currentVideo.video.match_desc}
                  showDescription={true}
                  showDate={true}
                  showViews={true}
                  data={this.state.currentVideo.video}
                />
              ) : (
                // <h1>Part 1</h1>
                ""
              )
            ) : this.state.videos.length > 0 &&
            this.state.currentVideo.video !== undefined ? (
              <VideoDescriptionCardTopOne
                title={this.state.currentVideo.video.title}
                description={this.state.currentVideo.video.description}
                showDescription={true}
                showDate={true}
                showViews={true}
                data={this.state.currentVideo.video}
              />
            ) : (
              // <h1>Part 2</h1>
              ""
            )}
          </div>
          {this.state.videoType !== "timeline" ? (
            this.state.videos.length && this.state.currentVideo.video ? (
              <div className="d-flex">
                <div className="ml-auto navbar-expand">
                  <ul className="navbar-nav mt-2 mt-lg-0">
                    <li className="nav-item">
                      <a
                        data-original-title="Likes"
                        className={
                          "nav-link text-secondary h1 mb-0 py-0 m-f-14 " +
                          (this.state.currentVideo.video.user_liked === true
                            ? "like-red"
                            : " ")
                        }
                        onClick={this.onLikeClicked}
                      >
                        <i className="fa fa-thumbs-up" />
                        <p className="like-font">
                          {this.state.currentVideo.video.likes}
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        data-original-title="Facebook"
                        className="nav-link text-secondary h1 mb-0 py-0 m-f-14"
                        target="_blank"
                        href={
                          "https://www.facebook.com/sharer/sharer.php?u=" +
                          window.location.href
                        }
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        data-original-title="twitter"
                        className="nav-link text-secondary h1 mb-0 py-0 m-f-14"
                        href={
                          "http://www.twitter.com/share?text=" +
                          title +
                          "&amp;url=" +
                          window.location.href +
                          "/"
                        }
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        data-original-title="google-plus"
                        className="nav-link text-secondary h1 mb-0 py-0 m-f-14"
                        href={
                          "https://plus.google.com/share?url=" +
                          window.location.href +
                          "/"
                        }
                      >
                        <i className="fa fa-google-plus google" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div />
            )
          ) : this.state.videos.length && this.state.currentVideo ? (
            <div className="d-flex">
              <div className="ml-auto navbar-expand">
                <ul className="navbar-nav mt-2 mt-lg-0">
                  <li className="nav-item">
                    <a
                      data-original-title="Facebook"
                      className="nav-link text-secondary h1 mb-0 py-0 m-f-14"
                      target="_blank"
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        window.location.href
                      }
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      data-original-title="twitter"
                      className="nav-link text-secondary h1 mb-0 py-0 m-f-14"
                      href={
                        "http://www.twitter.com/share?text=" +
                        title +
                        "&amp;url=" +
                        window.location.href +
                        "/"
                      }
                    >
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      data-original-title="google-plus"
                      className="nav-link text-secondary h1 mb-0 py-0 m-f-14"
                      href={
                        "https://plus.google.com/share?url=" +
                        window.location.href +
                        "/"
                      }
                    >
                      <i className="fa fa-google-plus google" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="d-flex overflow-x-auto mt-2">
          {this.state.videoType === "playlist" ? (
            <p className={"font-weight-bold p-2 m-f-14"}>
              <span>
                <img
                  className="plist-icon"
                  style={{ color: "black" }}
                  src={getCDNUrl("../../../images/playlist_ico_black.png")}
                />
                &nbsp;
                <bold>{this.state.totalPlaylistVideos} Videos</bold>
              </span>
            </p>
          ) : (
            <h3 className={"font-weight-bold p-2 m-f-14"}>Related Videos</h3>
          )}
        </div>
        <div className="card p-3 mt-2">
          {this.state.videos &&
            this.state.videos.length > 0 &&
            this.state.videos.map((video, i) => (
              <Link
                to={
                  this.state.videoType === "playlist"
                    ? "/playlist/" +
                      this.state.playlistId +
                      "/video/" +
                      video.video.id +
                      "/" +
                      space2hyphenForSlug(video.video.title)
                    : this.state.videoType === "featured"
                      ? "/video/" +
                        video.video.id +
                        "/" +
                        space2hyphenForSlug(video.video.title)
                      : this.state.videoType === "series"
                        ? "/series/" +
                          this.state.seriesId +
                          "/" +
                          space2hyphen(this.state.seriesTitle) +
                          "/videos/" +
                          video.video.id +
                          "/" +
                          space2hyphenForSlug(video.video.title)
                        : this.state.videoType === "timeline"
                          ? "/video/highlights/" +
                            video.id +
                            "/" +
                            space2hyphenForSlug(video.title)
                          : ""
                }
                key={"sv-" + i}
                className="d-flex p-2 mvlist"
              >
                <div className="w-30 pr-3 position-relative">
                  <BackgroundImage
                    className="sixteen-by-nine bg-center-cover"
                    src={
                      this.state.videoType === "timeline"
                        ? video.thumb
                        : video.video.thumb
                    }
                    placeholder={Globals.placeholderPlain4x3}
                  >
                    {/* {
                                            (this.state.currentVideo && this.state.currentVideo.video && this.state.currentVideo.video.id == video.video.id) 
                                                ?
                                                <span className="play-btn-lg play-btn-sm" style={{fontSize: "14px", fontWeight: "bold"}}>Now Playling</span>
                                                :
                                                <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div> 
                                        } */}
                    <div className="fa fa-play-circle play-btn-lg play-btn-sm" />
                  </BackgroundImage>
                </div>
                <VideoDescriptionCardNew
                  title={
                    this.state.videoType === "timeline"
                      ? video.title
                      : video.video.title
                  }
                  description={
                    this.state.videoType === "timeline"
                      ? video.match_desc
                      : video.video.description
                  }
                  showDescription={true}
                  showDate={this.state.videoType === "timeline" ? false : true}
                  showViews={this.state.videoType === "timeline" ? false : true}
                  data={
                    this.state.videoType === "timeline" ? video : video.video
                  }
                />
              </Link>
            ))}
        </div>
        {!this.state.pagesEnd && this.state.videos.length ? (
          <div>
            <div className="text-center d-none loading-more-results">
              <img
                src={getCDNUrl("/images/loader_v2.gif")}
                height="30"
                alt=""
              />
            </div>
            <button
              id="more-results"
              onClick={this.nextPage}
              type="button"
              className="btn btn-primary d-none"
            >
              More Results
            </button>
          </div>
        ) : (
          <div />
        )}
      </section>
    );
  }
}

export default SingleVideo;
