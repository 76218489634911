import React, { Component } from "react";
import Loading from "../Loading";
import queryString from "query-string";
import { Globals } from "../constants";
import axios from "axios";
import {
  loginUser,
  logoutUser,
  getTelcoName,
  getCDNUrl,
} from "../HelperFunctions";
class JazzCallBack extends Component {
  state = {
    isLoading: true,
    message: "",
  };

  checkStatus = async (params) => {
    let url = `${Globals.NodeApi}api/card_payment_status?phone=${
        params.msisdn
      }&order_id=${params.orderId}`,
      serverResponse;

    try {
      serverResponse = await axios.get(url, {});
      console.log("Server response for card", serverResponse);
      serverResponse = serverResponse.data;

      if (serverResponse && serverResponse.status === 0) {
        this.setState({
          message: serverResponse.status_message,
          isLoading: false,
        });
      }

      if (
        serverResponse &&
        serverResponse.status === 1 &&
        serverResponse.remote_response &&
        serverResponse.remote_response.status === 0
      )
        this.setState({
          message: serverResponse.remote_response.status_message,
          isLoading: false,
        });

      if (
        serverResponse &&
        serverResponse.status === 1 &&
        serverResponse.remote_response &&
        serverResponse.remote_response.status === 1 &&
        serverResponse.remote_response.user
      ) {
        let user = serverResponse.remote_response.user;
        localStorage.setItem(
          "isSocialProfileAttached",
          user ? user.is_social_profile_attached : "false"
        );
        let xAccessToken = user ? user.x_access_token : "";
        if (xAccessToken) {
          axios
            .post(`${Globals.UserManagment}api/v1/users/app-start`, {
              token: xAccessToken,
              tokenType: "telco",
              appName: localStorage.getItem("domain")
                ? localStorage.getItem("domain")
                : `CricwickWeb`,
              platform: "web",
            })
            .then((resp) => {
              let data = resp.data.data;
              if (
                data.subscriptionStatus === 1 ||
                data.subscriptionStatus === 2
              ) {
                let user = {
                  name: data.name,
                  isSocialLogin: data.isSocialLogin,
                  email: data.email,
                  phone: data.msisdn,
                  subscribed:
                    data.subscriptionStatus === 1 ||
                    data.subscriptionStatus === 2
                      ? 1
                      : 0,
                  signInProvider: data.signInProvider,
                  telco: getTelcoName(data.telcoId, 0),
                  profilePicture: data.avatarUrl,
                  cnic: data.cnic,
                  cnicIssueDate: data.cnicIssueDate,
                  isSocialProfileAttached: data.isSocialProfileAttached,
                  xAccessToken: xAccessToken,
                  isUserGracePeriodElapsed: data.isUserGracePeriodElapsed,
                  subscriptionType: data.subscriptionType,
                  paymentType: data.paymentType,
                  subscriptionStatus: data.subscriptionStatus,
                };
                localStorage.setItem("user_logged_in", true);
                loginUser(user);
                window.location = document.documentURI;
              } else {
                logoutUser();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    } catch (error) {
      console.log(`serverResponse,`, serverResponse);
      this.setState({
        btnDisbaled: false,
        message: "Something went wrong",
      });
    }
  };

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    console.log("params isL", params);
    // if (params.status === "0")
    //   this.setState({
    //     message: params.message,
    //     isLoading: false,
    //   });
    this.checkStatus(params);
  }

  render() {
    return (
      <div id="main-section">
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
              <div
                className={`d-flex justify-content-center align-items-center mt-5`}
              >
                <img
                  src={getCDNUrl(`/images/cricwick/cricwick-logo.jpg?v=3`)}
                  alt={`Cricwick logo`}
                  width={`250px`}
                  height={`auto`}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontSize: "1.9rem",
                  lineHeight: "1.2 !important",
                  fontWeight: "700",
                }}
                className={`mt-5`}
              >
                Login Failed!
              </div>
              <br />

              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontSize: "1.7rem",
                  lineHeight: "1.2 !important",
                  fontWeight: "600",
                }}
              >
                {this.state.message.length > 0 ? this.state.message : ""}
              </div>

              <div className={`pb-5`}>
                <button
                  className="submit border-0 btn bg-red text-white rounded-0 p-3 text-uppercase font-weight-bold"
                  onClick={() => this.payNow()}
                  disabled={this.state.btnDisbaled}
                >
                  {`Go to Login`}
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default JazzCallBack;
