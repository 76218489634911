import React, { Component } from "react";
import {
  VideoCard,
  NewsCard,
  MatchNav,
  MatchScorecardSection,
  MatchHighlightBar,
  MatchScorecardTable,
  MatchScorecardTableBowling,
  BallByBall,
  BallByBallDetail,
  ScrollRightBallByBall,
  canonicalLink,
  getCDNUrl,
} from "./HelperFunctions";

import MatchReport from "./MatchReport";

import { BallByBallSwipe } from "./InitializePlugins";
import { Globals } from "./constants";
import JWPlayerOnScrollViewResize from "./JWPlayerOnScrollViewResize";
import axios from "axios";
import Pusher from "pusher-js";
import MatchSquads from "./MatchSquads";
import ReactJWPlayer from "react-jw-player";
import MatchScorecard from "./MatchScorecard";
import MatchVideos from "./MatchVideos";
import Login from "./Login";
import LowBalance from "./login/lowBalance.js";

import { Waypoint } from "react-waypoint";
import { Helmet } from "react-helmet";

class Match extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchSummaryResp: {},
      ballByBallDetail: [],
      squads: [],
      activeSquadsId: null,
      matchId: this.props.match.params.id,
      category: this.props.match.params.category,
      subcategory: this.props.match.params.subcategory,
      overPageEnd: false,
      allowLiveStream: true,
      customCssTransition: "h-transition",
      isLoading: true,
      isError: false,
      counter: 0,
      loadingPreviousBalls: false,
      selectedOver: 0,
      matchButtonText: "Watch Now!",
      hasError: false,
      hasErrorSquads: false,
      setLogin: false,
      activeOver: [],
      streamURLFromAPI: "",
    };
    this.isMobile = window.innerWidth < 992;
    this.handleSwipe = BallByBallSwipe.bind(this);
    this.windowLoadListener = false;
    this.promiseHandler = this.promiseHandler.bind(this);
    this.setMatchSummaryFromApi = this.setMatchSummaryFromApi.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getPreviousOvers = this.getPreviousOvers.bind(this);
    this.playerReady = this.playerReady.bind(this);
    this.playLiveStream = this.playLiveStream.bind(this);
    this.setSquads = this.setSquads.bind(this);
    this.ballByBallScroll = this.ballByBallScroll.bind(this);
    this.ballByBallSelect = this.ballByBallSelect.bind(this);
    this.refreshMatchSummary = this.refreshMatchSummary.bind(this);
    this.getMatchSquads = this.getMatchSquads.bind(this);
    this.playerError = this.playerError.bind(this);

    this.pusher = new Pusher(Globals.pusherKey, {
      cluster: "ap1",
      encrypted: true,
    });
    this.channel = this.pusher.subscribe("live_matches");
    this.userCountryOfOrigin = localStorage.getItem("countryCode");
    this.activeNav = React.createRef();
  }

  playLiveStream(e) {
    !this.state.allowLiveStream
      ? this.setState({ allowLiveStream: true, matchButtonText: "Close!" })
      : this.setState({
          allowLiveStream: false,
          matchButtonText: "Watch Now!",
        });
  }

  ballByBallScroll(e) {
    e.persist();
    const scrollPos = e.target.scrollLeft;
    if (scrollPos === 0) {
      this.getPreviousOvers();
    }
  }

  ballByBallSelect(overNumber) {
    // e.persist();
    // const oversTitle = e.target.firstChild.data || e.target.innerText || e.target.innerHTML || "Over 0";
    // const splitBySpaceArray = oversTitle.split(' ')[1];
    // const selectedOverNumber = (splitBySpaceArray) ? splitBySpaceArray.split('\n')[0] : 0;

    const selectedOverNumber = overNumber;
    if (selectedOverNumber !== this.state.selectedOver) {
      this.setState({ selectedOver: selectedOverNumber });
    }
  }

  async setMatchSummaryFromApi(matchId, category, subcategory, firstRun) {
    const matchSummaryApiUrl =
      Globals.ApiUrl +
      "api/v4/matches/" +
      matchId +
      `/${
        this.props.userLoggedIn ? `summary` : `unsub_summary`
      }?web_user=1&telco=` +
      this.props.telco +
      "&app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;

    let matchSummaryResp = {},
      matchSummaryStatus,
      ballByBallDetail = [],
      lastOver = 0,
      isOverSelected = false;

    try {
      const resp = await axios.get(matchSummaryApiUrl);
      this.setState(() => {
        return { isError: false };
      });
      matchSummaryResp = resp.data;
      matchSummaryStatus = resp.status;

      let active_inn = matchSummaryResp.match.innings.length;
      let scores = matchSummaryResp.match.innings[active_inn - 1];
      let team = [];
      if (
        active_inn &&
        matchSummaryResp.match.innings[active_inn - 1].batting_team_id ==
          matchSummaryResp.match.team_1_id
      ) {
        team = matchSummaryResp.match.teamA;
      } else {
        team = matchSummaryResp.match.teamB;
      }
      document.title =
        matchSummaryResp.match.match_state === "Live"
          ? team.short_name +
            " " +
            (scores.runs ? scores.runs : "0") +
            "/" +
            (scores.wickets ? scores.wickets : "0") +
            " (" +
            (scores.overs ? scores.overs : "0.0") +
            ") | " +
            Globals.title
          : Globals.title;
    } catch (err) {
      console.log("Match Summary Api Error", err);
      // this.setState(()=>{return{isError:true}});
      // ;
    }

    if (!matchSummaryStatus || matchSummaryStatus !== 200) {
      console.log("no reponse from summary api");
      return;
    }

    if (matchSummaryStatus && !matchSummaryResp.match) {
      if (matchSummaryStatus === 200) {
        //server responded but response was not valid
        this.setState({
          hasError: true,
          isLoading: false,
          customCssTransition: "v-transition",
        }); //TT
      } else {
        //server did not respond
        this.refreshMatchSummary();
      }
      return false;
    }

    if (!matchSummaryResp.hasOwnProperty("overs")) {
      matchSummaryResp.overs = [];
    }

    // if (matchSummaryResp.match && matchSummaryResp.match.is_alternate_stream_url && matchSummaryResp.match.alternate_stream_url.length > 0) {
    //  let apiResp = await axios.get(matchSummaryResp.match.alternate_stream_url);
    //  apiResp = apiResp.data;

    //  if (apiResp.data.url.length > 0) {
    //    this.setState({
    //       streamURLFromAPI: apiResp.data.url
    //    });
    //  }
    // }

    if (
      matchSummaryResp.match &&
      matchSummaryResp.match.match_state.toLowerCase().indexOf("over") > -1
    ) {
      // const overMatchesBallsUrl = Globals.ApiUrl + "api/v1/matches/" + matchId + "/summary"
      // try {
      //     const resp2 = await axios.get(overMatchesBallsUrl);
      //     matchSummaryResp.overs = resp2.data.overs.slice();
      // }
      // catch (err) {
      //     console.log(err);
      // }
    }

    matchSummaryResp.overs.forEach((obj) => {
      let balls = obj.balls.slice();
      ballByBallDetail.push({ ...obj, balls });
    });

    // lastOver = matchSummaryResp.overs.length
    //   ? matchSummaryResp.overs[0].title.split(" ")[1]
    //   : 0;

    lastOver = matchSummaryResp.overs.length
      ? matchSummaryResp.overs.length - 1
      : -1;

    // if (this.state.selectedOver !== 0 && this.state.selectedOver !== lastOver) {
    //   // if over already selected
    //   lastOver = this.state.selectedOver;
    //   isOverSelected = true;
    // }

    if (this.state.selectedOver !== 0 && this.state.selectedOver !== lastOver) {
      // if over already selected
      lastOver = this.state.selectedOver;
      isOverSelected = true;
    }

    matchSummaryResp.overs = matchSummaryResp.overs.reverse().map((obj, i) => {
      return { title: obj.title, balls: obj.balls.reverse() };
    });

    if (category === "squads") {
      this.setSquads(
        category,
        subcategory,
        matchId,
        matchSummaryResp,
        ballByBallDetail
      );
    } else {
      let allowStream = false,
        buttonText = "Watch Now!";
      if (subcategory === "allowStream" || this.state.allowLiveStream) {
        allowStream = true;
        buttonText = "Close!";
      }
      this.setState(
        {
          category: category,
          subcategory: subcategory,
          matchId: matchId,
          matchSummaryResp: matchSummaryResp,
          ballByBallDetail: ballByBallDetail,
          isLoading: false,
          customCssTransition: "v-transition",
          selectedOver: lastOver,
          allowLiveStream: allowStream,
          matchButtonText: buttonText,
        },
        () => {
          this.channel.bind("refresh", this.refresh);
          if (!isOverSelected) {
            ScrollRightBallByBall();
          }
          if (
            this.state.matchSummaryResp.match &&
            this.state.matchSummaryResp.match.match_state
              .toLowerCase()
              .indexOf("live") > -1
          ) {
            //match is live
            this.pusherSubscriptions();
          }
        }
      );
    }
  }

  async setSquads(
    category,
    subcategory,
    matchId,
    matchSummaryResp,
    ballByBallDetail
  ) {
    const teamId =
      subcategory === "2"
        ? matchSummaryResp.match.team_2_id
        : matchSummaryResp.match.team_1_id;
    const squadUrl =
      Globals.ApiUrl +
      "api/" +
      teamId.toString() +
      "/" +
      matchId.toString() +
      "/squad?app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;
    let squadsData = [],
      hasErrorSquads = false;

    try {
      const resp2 = await axios.get(squadUrl);
      squadsData = Array.isArray(resp2.data) ? resp2.data.slice() : [];
    } catch (err) {
      console.log(err);
    }

    if (!squadsData.length) hasErrorSquads = true;

    this.setState({
      hasErrorSquads: hasErrorSquads,
      category: category,
      subcategory: subcategory,
      matchId: matchId,
      matchSummaryResp: matchSummaryResp,
      ballByBallDetail: ballByBallDetail,
      squads: squadsData,
      activeSquadsId: teamId,
      isLoading: false,
      customCssTransition: "v-transition",
    });
  }

  componentWillReceiveProps(nextProps) {
    let matchId = nextProps.match.params.id,
      category = nextProps.match.params.category,
      subcategory = nextProps.match.params.subcategory;

    if (matchId !== this.state.matchId) {
      this.setState(
        {
          isLoading: true,
          hasError: false,
          customCssTransition: "h-transition",
          allowLiveStream: false,
          selectedOver: 0,
        },
        () => this.setMatchSummaryFromApi(matchId, category, subcategory, 0)
      );
    } else if (
      category !== this.state.category ||
      subcategory !== this.state.subcategory
    ) {
      if (category === "squads") {
        // this.setState({isLoading: true, customCssTransition: 'h-transition'}, () =>

        this.setSquads(
          category,
          subcategory,
          matchId,
          this.state.matchSummaryResp,
          this.state.ballByBallDetail
        );

        // );
      } else {
        let allowStream = false,
          buttonText = "Watch Now!";
        if (subcategory === "allowStream") {
          allowStream = true;
          buttonText = "Close!";
        }
        this.setState(
          {
            category: category,
            subcategory: subcategory,
            allowLiveStream: allowStream,
            matchButtonText: buttonText,
          },
          () => {
            if (category === "summary" || category === "ballbyball") {
              ScrollRightBallByBall();
            }
          }
        );
      }
    }
  }

  async getPreviousOvers() {
    this.setState({ loadingPreviousBalls: true }, async () => {
      const matchOvers = this.state.matchSummaryResp.overs;
      const lastOver = matchOvers[0].balls[0].over_number;
      // const innings = this.state.matchSummaryResp.match.innings;

      // This is the new innings id logic implemented by zain ejaz
      const inningsId = matchOvers[0].balls[0].inn;

      /* MAY BE FOR LIVE MATCHES */
      // This check is deprecated by zain ejaz because it's not choosing the right innings
      // let inningsId = innings
      //   .map((obj) => (obj.innings_order === innings.length ? obj.id : null))
      //   .filter((obj) => obj);

      /* NEW CHECK FOR OVER*/
      // This new check is deprecated by zain ejaz because it's not choosing the right innings
      // let active_inn = this.state.matchSummaryResp.match.innings.length;
      // inningsId = this.state.matchSummaryResp.match.innings[active_inn - 1].id;

      const url =
        Globals.ApiUrl +
        "api/v3/innings/" +
        inningsId.toString() +
        "/get_previous_overs?over_number=" +
        lastOver.toString() +
        "&app_name=" +
        `${
          localStorage.getItem("domain")
            ? localStorage.getItem("domain")
            : Globals.telco
              ? Globals.appName
              : Globals.appName
        }`;
      let ballByBallDetail = this.state.ballByBallDetail.slice();

      try {
        const response = await axios.get(url);
        if (
          response.status === 200 &&
          response.data.status &&
          response.data.overs.length
        ) {
          let matchSummaryRespCopy = Object.assign(
            {},
            this.state.matchSummaryResp
          );
          matchSummaryRespCopy.overs = response.data.overs
            .slice()
            .reverse()
            .concat(matchSummaryRespCopy.overs);
          ballByBallDetail = ballByBallDetail.concat(response.data.overs);

          this.setState(
            {
              matchSummaryResp: matchSummaryRespCopy,
              ballByBallDetail: ballByBallDetail,
              loadingPreviousBalls: false,
            },
            () => {
              let elem = document.getElementsByClassName("bbb-container");
              if (elem) {
                elem = elem[0];
                elem.scrollTo(10, 0);
              }
            }
          );
          // ballByBallDetail = ballByBallDetail.concat(response.data.overs);
          // this.setState({ ballByBallDetail: ballByBallDetail });
        } else if (!response.data.overs.length) {
          this.setState({ overPagesEnd: true, loadingPreviousBalls: false });
        }
      } catch (err) {
        console.log("error getting next page", err);
        this.setState({ loadingPreviousBalls: false });
      }
    });
  }

  pusherSubscriptions() {
    const channelKeys = [
      "partnership_update",
      "ball_by_ball_update",
      "match_update",
    ];
    const promises = channelKeys.map(
      (obj) =>
        new Promise((resolve) =>
          this.channel.bind(obj, (data) => resolve(data))
        )
    );

    Promise.all(promises)
      .then((data) => {
        if (data[1].match_obj.id === this.state.matchSummaryResp.match.id) {
          this.promiseHandler(data);
        } else {
          this.pusherSubscriptions();
        }
      })
      .catch((err) => {
        console.log("Promise all error", err);
        this.setMatchSummaryFromApi(
          this.state.matchId,
          this.state.category,
          this.state.subcategory,
          0
        );
        return;
      });
  }

  refreshMatchSummary() {
    this.setState(
      { isLoading: true, hasError: false, customCssTransition: "h-transition" },
      () =>
        this.setMatchSummaryFromApi(
          this.state.matchId,
          this.state.category,
          this.state.subcategory,
          0
        )
    );
  }

  componentDidUpdate() {
    if (this.activeNav.current)
      this.activeNav.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "center",
      });
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    window.gtag("event", "match_page_landed");

    const {
      match: { params },
    } = this.props;
    if (document.readyState === "complete") {
      // if document has finished loading all the scripts
      this.handleSwipe(); // call videos swipe initialization
    } else {
      // if document is still loading scripts i.e readyState === 'loading'
      this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
      window.addEventListener("load", this.handleSwipe); // add listener
    }

    await this.setMatchSummaryFromApi(
      params.id,
      params.category,
      params.subcategory,
      1
    );

    if (this.state.isError) {
      console.log("Knocking NetWork");
      await this.knockingNetWorking(
        params.id,
        params.category,
        params.subcategory,
        1
      );
    }
  }

  //========================================================================

  knockingNetWorking = async (id, category, subcategory, firsttime) => {
    let i = 0;
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        this.setMatchSummaryFromApi(id, category, subcategory, firsttime);
        i = i + 1;
        this.setState(() => {
          return { counter: i };
        });

        if (this.state.counter === 3) {
          this.setState({
            hasError: true,
            isLoading: false,
            customCssTransition: "v-transition",
          }); //TT
        }
      }, 15000);

      if (!this.state.isError) return;
    }
  };

  // componentDidUpdate(prevProps) {
  //   let matchId = nextProps.match.params.id,
  //     category = nextProps.match.params.category,
  //     subcategory = nextProps.match.params.subcategory;
  //   if (matchId !== this.state.matchId && prevProps.userLoggedIn !== this.props.userLoggedIn)
  //     this.setMatchSummaryFromApi();
  // }

  //==================================End========================================

  refresh(data) {
    if (data.match_id === this.state.matchSummaryResp.match.id) {
      this.setMatchSummaryFromApi(
        this.state.matchId,
        this.state.category,
        this.state.subcategory,
        0
      );
    }
  }

  promiseHandler(pusherData) {
    if (
      !pusherData ||
      !pusherData.length ||
      (parseInt(this.state.matchId) !== pusherData[0].id ||
        !(
          this.state.category === "summary" ||
          this.state.category === "ballbyball"
        ))
    ) {
      this.pusherSubscriptions();
      if (this.state.category === "videos") {
        if (this.state.videoId && this.state.videoId !== "v") {
          this.singleVideoCase(this.state.videoId);
        } else {
          this.getFromApi(this.state.tag);
        }
      }
    } else {
      const matchSummaryRespCopy = Object.assign(
        {},
        this.state.matchSummaryResp
      );

      let titleResp = pusherData[2];
      let active_inn = titleResp.innings.length;
      let scores = titleResp.innings[active_inn - 1];
      let team = [];
      if (
        titleResp.innings[active_inn - 1].batting_team_id == titleResp.team_1_id
      ) {
        team = titleResp.teamA;
      } else {
        team = titleResp.teamB;
      }
      document.title =
        titleResp.match_state === "Live"
          ? team.short_name +
            " " +
            (scores.runs ? scores.runs : "0") +
            "/" +
            (scores.wickets ? scores.wickets : "0") +
            " (" +
            (scores.overs ? scores.overs : "0.0") +
            ") | " +
            Globals.title
          : Globals.title;

      pusherData[2].live_stream_url =
        matchSummaryRespCopy.match.live_stream_url; //livestream stream url should not be updated with pusher as pusher does not have any telco check
      const mergedMatchObject = {
        ...matchSummaryRespCopy.match,
        ...pusherData[2],
        ...pusherData[0],
      };

      if (!mergedMatchObject.partnership.hasOwnProperty("fall_of_wickets")) {
        mergedMatchObject.partnership.fall_of_wickets = this.state.matchSummaryResp.match.partnership.fall_of_wickets.slice();
      }

      let matchOversArray = matchSummaryRespCopy.overs.slice();

      const lastOverIndex = matchOversArray.length - 1;
      const lastOverBalls = matchOversArray[lastOverIndex].balls;
      const ballOrders = lastOverBalls.map((obj) => obj.ball_order);
      const lastBallIndex = Math.max(...ballOrders);
      const lastOver = matchOversArray[lastOverIndex];
      const lastBall = lastOver.balls[lastOver.balls.length - 1];
      const lastBallWideOrNo = lastBall.no_ball || lastBall.wide_ball;
      const isLastBall = lastBallIndex === 6 && !lastBallWideOrNo;
      const nextBallIndex = pusherData[1].ball.ball_order;

      let isFirstBall = false;

      if (matchOversArray.length) {
        if (
          (lastBallWideOrNo && !(lastBallIndex === nextBallIndex)) || // if ball order is not correct
          (!isLastBall && !(lastBallIndex + 1 === nextBallIndex)) ||
          (isLastBall && nextBallIndex !== 1)
        ) {
          this.pusherSubscriptions();
          this.setMatchSummaryFromApi(
            this.state.matchId,
            this.state.category,
            this.state.subcategory,
            3
          );
          return;
        }

        if (isLastBall) {
          // pushed ball is first ball of next over

          matchOversArray.push({
            balls: [],
            title: "over " + pusherData[1].ball.over_number,
          });
          matchOversArray[matchOversArray.length - 1].balls.push(
            pusherData[1].ball
          );

          isFirstBall = true;
        } else {
          lastOverBalls.push(pusherData[1].ball);
        }
      } else {
        // if first ball of inning

        matchOversArray.push({
          balls: [],
          title: "over " + pusherData[1].ball.over_number,
        });
        matchOversArray[0].balls.push(pusherData[1].ball);
        isFirstBall = true;
      }

      const finalResponse = {
        ...matchSummaryRespCopy,
        ...{ overs: matchOversArray },
        ...{ match: mergedMatchObject },
      };

      const ballByBallDetail = finalResponse.overs
        .slice()
        .reverse()
        .map((obj, i) => {
          return { title: obj.title, balls: obj.balls.slice().reverse() };
        });

      // let selectedOver = finalResponse.overs.length
      //   ? finalResponse.overs[finalResponse.overs.length - 1].title.split(
      //       " "
      //     )[1]
      //   : 0;

      let selectedOver = finalResponse.overs.length
        ? finalResponse.overs.length - 1
        : -1;
      let isOverSelected = false;

      if (
        this.state.selectedOver !== selectedOver &&
        !(isFirstBall && parseInt(this.state.selectedOver) + 1 === selectedOver)
      ) {
        // if over already selected
        selectedOver = this.state.selectedOver;
        isOverSelected = true;
      }

      this.setState(
        {
          matchSummaryResp: finalResponse,
          ballByBallDetail: ballByBallDetail,
          selectedOver: selectedOver,
        },
        () => {
          this.pusherSubscriptions();
          if (!isOverSelected) {
            ScrollRightBallByBall();
          }
        }
      );
    }
  }

  componentWillUnmount() {
    document.title = Globals.title;
    if (this.windowLoadListener)
      window.removeEventListener("load", this.handleSwipe); //remove listener if listening
  }

  playerReady(e) {
    if (!this.isMobile) JWPlayerOnScrollViewResize();
  }

  playerError(e) {
    this.setState({ allowLiveStream: false });
    this.setState({ allowLiveStream: true });
  }

  getMatchSquads() {
    if (this.state.hasErrorSquads) {
      return (
        <div className="card p-3 mt-2">
          <h1>No squad available</h1>
        </div>
      );
    } else {
      return (
        <MatchSquads
          match={this.state.matchSummaryResp.match}
          activeSquadsId={this.state.activeSquadsId}
          data={this.state.squads}
          customCssTransition={this.state.customCssTransition}
        />
      );
    }
  }

  render() {
    let liveStreamURL = null;

    if (
      this.state.matchSummaryResp.hasOwnProperty("match") &&
      this.state.matchSummaryResp.match.hasOwnProperty("match_state") &&
      this.state.matchSummaryResp.match.match_state.toLowerCase() === "live"
    ) {
      if (
        this.state.matchSummaryResp.match.is_alternate_stream_url &&
        this.state.streamURLFromAPI.length > 0
      ) {
        liveStreamURL = this.state.matchSummaryResp.match.alternate_stream_url;
      } else if (
        this.state.matchSummaryResp.match.live_stream_url &&
        this.state.matchSummaryResp.match.live_stream_url.length > 0
      ) {
        liveStreamURL = this.state.matchSummaryResp.match.live_stream_url;
      }
    }

    let hardcodedURL = "";

    if (
      this.state.matchSummaryResp.hasOwnProperty("match") &&
      this.state.matchSummaryResp.match.hasOwnProperty("match_state") &&
      this.state.matchSummaryResp.match.match_state.toLowerCase() === "live" &&
      this.state.matchSummaryResp.match.series.id.toString() === "1453" &&
      this.userCountryOfOrigin &&
      this.userCountryOfOrigin.length > 0 &&
      this.userCountryOfOrigin.toLowerCase() === "sa"
    ) {
      hardcodedURL = "https://studio.cricwick.net/mobile/wct2022/playlist.m3u8";
      window.gtag("event", "live_stream_on_match_sa_1453");
    } else if (
      this.state.matchSummaryResp.hasOwnProperty("match") &&
      this.state.matchSummaryResp.match.series.id.toString() === "1411"
    ) {
      hardcodedURL =
        "https://studio.cricwick.net/cricket/psl2022/playlist.m3u8";
      window.gtag("event", "live_stream_on_match");
    }

    const playlist = [
      {
        // file: (this.state.matchSummaryResp.hasOwnProperty('match') && this.isMobile) ? this.state.matchSummaryResp.match.live_stream_url
        // : (this.state.matchSummaryResp.hasOwnProperty('match') && !this.isMobile) ? 'https://32x2cn7zz29m47vnqt4z-kyz6hw.p5cdn.com/abr_PSLME_D/psl62021/playlist.m3u8' : null
        file: hardcodedURL.length > 0 ? hardcodedURL : liveStreamURL,
      },
    ];

    // const playlist = [{
    //     file: (this.userCountryOfOrigin.toLowerCase() === "sa" && this.state.matchSummaryResp.hasOwnProperty('match') && this.state.matchSummaryResp.match.series.id.toString() === "1350" ? "https://32x2cn7zz29m47vnqt4z-kyz6hw.p5cdn.com/abr_PSLME/ipl2021/playlist.m3u8" :
    //     this.state.matchSummaryResp.hasOwnProperty('match') ? this.state.matchSummaryResp.match.live_stream_url : null )
    // }];

    let teamA,
      teamB,
      title,
      venue,
      date,
      seriesTitle = "";
    let isLive = false;
    if (this.state.matchSummaryResp.hasOwnProperty("match")) {
      teamA =
        this.state.matchSummaryResp.match.teamA.name ||
        (this.state.matchSummaryResp.match.team_1 &&
          this.state.matchSummaryResp.match.team_1.team.name);
      teamB =
        this.state.matchSummaryResp.match.teamB.name ||
        (this.state.matchSummaryResp.match.team_2 &&
          this.state.matchSummaryResp.match.team_2.team.name);
      title = this.state.matchSummaryResp.match.title;
      venue = this.state.matchSummaryResp.match.venue;
      date = this.state.matchSummaryResp.match.match_start.toString();
      isLive =
        this.state.matchSummaryResp.match.hasOwnProperty("match_state") &&
        this.state.matchSummaryResp.match.match_state.toLowerCase() === "live";
      seriesTitle = this.state.matchSummaryResp.match.series.title;
    }

    return (
      <React.Fragment>
        {this.state.setLogin ? (
          <Login />
        ) : (
          <>
            {this.props.userLoggedIn && this.props.lowBalance ? (
              <LowBalance
                onClickDiscountedBundles={() =>
                  this.setState({
                    setLogin: true,
                  })
                }
              />
            ) : (
              <section
                id="main-section"
                className="px-lg-0 jc-inner-page align-self-start"
              >
                {isLive ? (
                  <Helmet>
                    <title
                    >{`${teamA} vs ${teamB} Live Score - ${title} Match - ${venue} - ${
                      Globals.product
                    }`}</title>
                    <meta
                      name="description"
                      content={`Watch ${teamA} vs ${teamB} Live Cricket Score for - ${title} ${date}. Stay tuned to ${seriesTitle} on ${
                        Globals.product
                      }`}
                    />
                    <link rel="canonical" href={canonicalLink()} />
                  </Helmet>
                ) : (
                  <Helmet>
                    <title>
                      {this.state.category == "summary"
                        ? `${teamA} vs ${teamB}  ${title} Live Cricket Score - ${
                            Globals.product
                          }`
                        : `${teamA} vs ${teamB} ${this.state.category[0].toUpperCase() +
                            this.state.category.substring(1)} ${
                            this.state.category == "scorecard"
                              ? "and Result"
                              : ""
                          } - ${title}  - ${Globals.product}`}
                    </title>
                    <meta
                      name="description"
                      content={
                        this.state.category == "summary"
                          ? `Check out the ${teamA} vs ${teamB} Live Score for - ${title} ${date} on ${
                              Globals.product
                            }`
                          : `Check out the ${teamA} vs ${teamB} ${(
                              this.state.category[0].toUpperCase() +
                              this.state.category.substring(1)
                            ).toUpperCase()} ${
                              this.state.category == "scorecard"
                                ? "and Match Result"
                                : ""
                            } 
                      for ${title} played on ${date} on ${Globals.product}`
                      }
                    />
                  </Helmet>
                )}

                {this.state.isLoading ? (
                  <div className="mt-2 main-loader card min-height-80vh">
                    <img
                      width="64"
                      height="64"
                      src={getCDNUrl("/images/loader_v2.gif")}
                      alt=""
                    />
                  </div>
                ) : (
                  <div />
                )}

                <div className={this.state.customCssTransition}>
                  {this.state.hasError ? (
                    <div className="has-error card min-height-80vh p-3 mt-2">
                      <h2>
                        {" "}
                        Error or no result{" "}
                        <button
                          className="btn btn-default"
                          onClick={this.refreshMatchSummary}
                        >
                          Try Again
                        </button>
                      </h2>
                    </div>
                  ) : (
                    <div className="card p-3">
                      <h1 className="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">
                        {this.state.category === "summary"
                          ? `${teamA} vs ${teamB}  ${title} Live Score`
                          : this.state.category === "scorecard"
                            ? `${teamA} vs ${teamB}  ${title} Scorecard`
                            : this.state.category === "videos"
                              ? `${teamA} vs ${teamB}  ${title} Match Highlights`
                              : this.state.category === "ballbyball"
                                ? `${teamA} vs ${teamB}  ${title} Ball by Ball`
                                : this.state.category === "squads"
                                  ? `${teamA} vs ${teamB}  ${title} Playing XI`
                                  : `${teamA} vs ${teamB}  ${title} Match Report`}
                      </h1>
                      {this.state.matchSummaryResp.match && (
                        <MatchNav
                          category={this.state.category}
                          matchId={this.state.matchId}
                          match={this.state.matchSummaryResp.match}
                        />
                      )}
                    </div>
                  )}

                  {this.state.category === "squads" ? (
                    this.getMatchSquads()
                  ) : (
                    <div />
                  )}

                  {this.state.category === "scorecard" ? (
                    <MatchScorecard matchId={this.state.matchId} />
                  ) : (
                    <div />
                  )}

                  {this.state.category === "videos" ? (
                    <MatchVideos
                      telco={this.props.telco}
                      videoId={this.state.subcategory}
                      matchId={this.state.matchId}
                      isVideoAllowed={this.props.isVideoAllowed}
                      isRegionChecked={this.props.isRegionChecked}
                      noVideoReason={this.props.noVideoReason}
                    />
                  ) : (
                    <div />
                  )}

                  {this.state.category === "matchReport" ? (
                    <div className={`mt-2 `}>
                      {this.state.matchSummaryResp &&
                      this.state.matchSummaryResp.match &&
                      this.state.matchSummaryResp.match.match_report ? (
                        <MatchReport
                          article={
                            this.state.matchSummaryResp.match.match_report
                          }
                        />
                      ) : (
                        <h2 className="mb-0 none-message d-none">
                          Sorry no report
                        </h2>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}

                  {this.state.category === "summary" &&
                  this.state.matchSummaryResp.match ? (
                    <div className="card mt-2 p-3">
                      {!this.props.userLoggedIn &&
                      this.state.allowLiveStream &&
                      this.state.matchSummaryResp.match.hasOwnProperty(
                        "match_state"
                      ) &&
                      this.state.matchSummaryResp.match.match_state.toLowerCase() ===
                        "live" &&
                      this.state.matchSummaryResp.match.is_live_stream ? (
                        <img
                          src={getCDNUrl(`/images/LiveStream.jpg`)}
                          alt={`Paid live stream of the match`}
                          width={`100%`}
                          height={`100%`}
                          style={{
                            cursor: `pointer`,
                          }}
                          onClick={() =>
                            this.setState({
                              setLogin: true,
                            })
                          }
                        />
                      ) : null}
                      {this.props.userLoggedIn &&
                      this.state.allowLiveStream &&
                      this.userCountryOfOrigin &&
                      this.userCountryOfOrigin.toLowerCase() === "pk" &&
                      this.state.matchSummaryResp.match.hasOwnProperty(
                        "match_state"
                      ) &&
                      this.state.matchSummaryResp.match.match_state.toLowerCase() ===
                        "live" &&
                      this.state.matchSummaryResp.match.live_stream_url !==
                        null &&
                      this.state.matchSummaryResp.match.live_stream_url.length >
                        0 ? (
                        <div className="player-container mb-2">
                          <div className="player-position">
                            <ReactJWPlayer
                              playerId="player"
                              playerScript="https://content.jwplatform.com/libraries/Spb3cNmq.js"
                              playlist={playlist}
                              onReady={this.playerReady}
                              onError={this.playerError}
                            />
                          </div>
                        </div>
                      ) : this.props.userLoggedIn &&
                      this.state.allowLiveStream &&
                      this.userCountryOfOrigin &&
                      this.userCountryOfOrigin.toLowerCase() === "sa" &&
                      this.state.matchSummaryResp.match.hasOwnProperty(
                        "match_state"
                      ) &&
                      this.state.matchSummaryResp.match.match_state.toLowerCase() ===
                        "live" ? (
                        <div className="player-container mb-2">
                          <div className="player-position">
                            <ReactJWPlayer
                              playerId="player"
                              playerScript="https://content.jwplatform.com/libraries/Spb3cNmq.js"
                              playlist={playlist}
                              onReady={this.playerReady}
                              onError={this.playerError}
                            />
                          </div>
                        </div>
                      ) : (
                        <div style={{ height: 0 }} />
                      )}

                      <MatchScorecardSection
                        matchButtonText={this.state.matchButtonText}
                        playLiveStream={this.playLiveStream}
                        data={this.state.matchSummaryResp.match}
                      />
                      <MatchHighlightBar
                        data={this.state.matchSummaryResp.match}
                      />
                      <MatchScorecardTable
                        data={this.state.matchSummaryResp.match}
                        showPlayerDetailText={false}
                      />
                      <MatchScorecardTableBowling
                        data={this.state.matchSummaryResp.match}
                        showPlayerDetailText={false}
                      />
                    </div>
                  ) : (
                    <div />
                  )}

                  {(this.state.category === "ballbyball" ||
                    this.state.category === "summary") &&
                  this.state.ballByBallDetail.length ? (
                    <div className="card mt-2 pb-3">
                      <div
                        className={`${
                          this.state.category === "summary"
                            ? "d-flex"
                            : "d-none"
                        }`}
                      >
                        <BallByBall
                          loadingPreviousBalls={this.state.loadingPreviousBalls}
                          scroll={this.ballByBallScroll}
                          data={this.state.matchSummaryResp.overs}
                          // ballByBallSelect={this.ballByBallSelect}
                          ballByBallSelect={(index) =>
                            this.setState({
                              selectedOver: index,
                            })
                          }
                          selectedOver={this.state.selectedOver}
                        />
                      </div>
                      {this.state.category === "summary" ? (
                        <div>
                          {this.state.selectedOver !== -1 &&
                            this.state.matchSummaryResp.overs[
                              this.state.selectedOver
                            ].balls.map((ball) => (
                              <BallByBallDetail key={ball.id} data={ball} />
                            ))}
                        </div>
                      ) : (
                        <div>
                          {[...this.state.matchSummaryResp.overs]
                            .reverse()
                            .map((over, index) => {
                              console.log(`over`, over);
                              return (
                                <div
                                  className={`mt-5`}
                                  style={{
                                    backgroundColor:
                                      this.state.activeOver[0] === index
                                        ? "#f6f6f6"
                                        : "white",
                                  }}
                                  key={over.title}
                                >
                                  {over.balls.map((ball) => (
                                    <BallByBallDetail
                                      key={ball.id}
                                      data={ball}
                                    />
                                  ))}
                                </div>
                              );
                            })}
                          {!this.state.loadingPreviousBalls &&
                          !this.state.overPagesEnd ? (
                            <div>
                              <Waypoint
                                onEnter={() => {
                                  this.getPreviousOvers();
                                }}
                              />
                            </div>
                          ) : null}
                          {this.state.loadingPreviousBalls &&
                          !this.state.overPagesEnd ? (
                            <div className="border-right p-3 d-flex align-items-center">
                              <img
                                src={getCDNUrl("/images/loader_v2.gif")}
                                height="30"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                  {this.state.category === "ballbyball" &&
                  !this.state.ballByBallDetail.length ? (
                    <div className="card p-3 mt-2">
                      <h1>No Ball By Ball available</h1>
                    </div>
                  ) : (
                    <div />
                  )}

                  {this.state.category === "summary" &&
                  this.state.matchSummaryResp.timeline_videos &&
                  this.state.matchSummaryResp.timeline_videos.timeline.length &&
                  localStorage.getItem("user") ? (
                    <div className="card mt-2 px-3 pb-3 pt-4">
                      {this.state.matchSummaryResp.timeline_videos.timeline.map(
                        (obj, i) => (
                          <VideoCard
                            key={"ms-videos-" + i}
                            image={obj.video.thumb}
                            matchTitle={obj.video.match_obj.title}
                            title={obj.video.title}
                            matchId={this.state.matchSummaryResp.match.id}
                            id={obj.video.id}
                            showDescription={false}
                          />
                        )
                      )}
                    </div>
                  ) : (
                    <div />
                  )}
                  {this.state.category === "summary" &&
                  this.state.matchSummaryResp.articles ? (
                    <div className="card mt-2 px-3 pb-3 pt-4">
                      {this.state.matchSummaryResp.articles.map((obj, i) => (
                        <NewsCard
                          key={"ms-articles-" + i}
                          image={obj.image}
                          id={obj.id}
                          title={obj.title}
                          date={obj.created_at}
                        />
                      ))}
                    </div>
                  ) : (
                    <div />
                  )}

                  {/* {
            (!this.state.overPagesEnd && this.state.matchSummaryResp.overs && this.state.category === 'ballbyball' &&
            this.state.ballByBallDetail.length) ?
            <button onClick={this.getPreviousOvers} type="button" className="btn btn-primary">Previous Overs</button>
            : <div></div>
        } */}
                </div>
              </section>
            )}
          </>
        )}
      </React.Fragment>
    );
  }
}

export default Match;
