import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Globals } from "../constants";
import { withRouter } from "react-router-dom";
import InputMask from "react-input-mask";

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      name: "",
      cnic: "",
      cnicIssueDate: "",
      profilePicture: "",
      shouldFormBeDisabled: true,
      errorMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.cnicRegex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;
  }

  openModal = () => {
    this.setState({ showModal: !this.state.showModal }, () =>
      this.props.changeOpen(this.state.open)
    );
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.setState({
      name: user.name,
      cnic: user.cnic,
      cnicIssueDate: user.cnicIssueDate,
      profilePicture: user.profilePicture,
    });
    if (this.props.open)
      this.setState({
        showModal: this.props.open,
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open)
      this.setState({
        showModal: this.props.open,
      });
  }

  handleChange(event) {
    this.setState({
      name: event.target.value,
    });
  }

  authHeader() {
    let token;
    let msisdn;
    let user = JSON.parse(localStorage.getItem("user"));
    token = user.xAccessToken;
    msisdn = user.msisdn;

    return {
      "x-access-token": token,
      msisdn: msisdn,
      iswebuser: "true",
    };
  }

  isCNICValid() {
    let test = this.cnicRegex.test(this.state.cnic);
    if (!test) {
      this.setState({ showCNICErrorMessage: true });
    } else {
      this.setState({ showCNICErrorMessage: false });
    }
    return test;
  }

  validateForm() {
    if (this.state.name && this.state.cnic && this.state.cnicIssueDate) {
      if (
        this.state.name.length > 0 &&
        this.isCNICValid() === true &&
        this.state.cnicIssueDate.length > 0
      ) {
        this.setState({ shouldFormBeDisabled: false });
      } else {
        this.setState({
          errorMessage: "Must fill all the fields",
        });
      }
    }
  }

  handleInputChange(name, value) {
    this.setState({ [name]: value }, () => {
      this.validateForm();
    });
  }

  save = async () => {
    let url = `${Globals.UserManagment}api/v1/users/update-user-profile`;

    let user = JSON.parse(localStorage.getItem("user"));
    let requestBody = {
      name: this.state.name,
      cnic: this.state.cnic,
      cnicIssueDate: this.state.cnicIssueDate,
    };

    let requestOptions = {
      method: "POST",
      headers: {
        "x-access-token": user.xAccessToken,
        "Content-type": "application/json; charset=UTF-8",
        msisdn: user.phone,
        platform: `web`,
        app: localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : "",
      },
      body: JSON.stringify(requestBody),
    };

    var resp = await fetch(url, requestOptions);
    resp = await resp.json();

    if (resp && resp.status === 1) {
      this.openModal();
      window.location.reload();
    }
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.openModal}
          backdrop={true}
        >
          <ModalBody>
            <div className="text-center">
              <div
                className={`d-flex justify-content-center align-items-center mt-3`}
              >
                <img
                  src={this.state.profilePicture}
                  alt={`User Profile`}
                  width={`80px`}
                  height={`80px`}
                  className="rounded-circle"
                />
              </div>
              <input
                type="text"
                placeholder="Add Name"
                className="input mt-4 mb-4"
                name="name"
                id="name"
                value={this.state.name}
                onChange={(e) => this.handleInputChange("name", e.target.value)}
              />
              <div style={{ color: "#B91414" }}>
                {!this.state.name ? this.state.errorMessage : null}
              </div>
              <div className="mb-2 mt-2" style={{ fontSize: "16px" }}>
                {`Enter Your CNIC`}
              </div>
              <input
                type="text"
                className="payout-input"
                data-inputmask="'mask': '99999-9999999-9'"
                value={this.state.cnic}
                placeholder="XXXXX-XXXXXXX-X"
                name="cnic"
                required=""
                maxLength={15}
                minLength={1}
                onChange={(e) => this.handleInputChange("cnic", e.target.value)}
              />
              {/* <InputMask
            type="text"
              className="payout-input"
              mask="99999-9999999-9"
              value={this.state.cnic}
              name="cnic"
              id="cnic"
              placeholder="xxxxx-xxxxxxx-x"
              maxLength={13}
             onChange={(e) =>
                this.handleInputChange("cnic", e.target.value)
              }
            />  */}
              <div style={{ color: "#B91414" }}>
                {!this.state.cnic ? this.state.errorMessage : null}
              </div>
              <div className="my-2 mb-2 mt-2" style={{ fontSize: "16px" }}>
                CNIC Issuance Date
              </div>
              <input
                type="date"
                value={this.state.cnicIssueDate}
                data-date-inline-picker="true"
                onChange={(e) =>
                  this.handleInputChange("cnicIssueDate", e.target.value)
                }
                className="payout-input"
                id="cnicIssueDatePicker"
              />
              {!this.state.cnicIssueDate ? this.state.errorMessage : null}
            </div>
          </ModalBody>
          <div className={`d-flex justify-content-center w-100 pb-4 pt-5`}>
            <div className={`d-flex flex-column`}>
              <button
                className="save-profile border-0 btn bg-red text-white rounded-0"
                onClick={() =>
                  !this.state.name ||
                  !this.state.cnic ||
                  !this.state.cnicIssueDate
                    ? (this.setState({
                        errorMessage: "Must fill all fields",
                      }),
                      this.state.errorMessage)
                    : this.save()
                }
              >
                {`Save Profile`}
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(EditUser);
