import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Globals } from "./constants";

import LoginForCricwick from "./login/LoginForCricwick";
import LoginForMobilink from "./login/LoginForMobilink";

class Login extends Component {
  render() {
    return Globals.telco === "mobilink" ? (
      <LoginForMobilink
        downloadApp={this.props.downloadApp}
        msisdn={this.props.msisdn}
      />
    ) : (
      <LoginForCricwick
        pathname={this.props.location.pathname}
        downloadApp={this.props.downloadApp}
        msisdn={this.props.msisdn}
      />
    );
  }
}

export default withRouter(Login);
