import React, { Component } from "react";
import axios from "axios";
import { Globals } from "../constants";
import { getCDNUrl } from "../HelperFunctions";
class LowBalance extends Component {
  state = {
    phone:
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).phone,
    errorMessages: "",
  };

  payNow = async () => {
    let url = `${Globals.NodeApi}api/user_charge_attempt?phone=${
      this.state.phone
    }`;
    let results = (await axios.get(url)).data;
    if (results.remote_response.status === 0)
      this.setState({
        errorMessages: `Low Balance - Abhi apnay account main balance dal ker ye button doobara dabain`,
      });
    else window.location.reload();
  };

  render() {
    return (
      <div id="main-section">
        <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
          <div
            className={`d-flex justify-content-center align-items-center mt-5`}
          >
            <img
              src={getCDNUrl(`/images/cricwick/cricwick-logo.jpg?v=3`)}
              alt={`Cricwick logo`}
              width={`250px`}
              height={`auto`}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "1.9rem",
              lineHeight: "1.2 !important",
              fontWeight: "700",
            }}
            className={`mt-5`}
          >
            LOW BALANCE!
          </div>
          <br />

          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "1.7rem",
              lineHeight: "1.2 !important",
              fontWeight: "600",
            }}
          >
            Pichlay 3 din se apka bill pay nae hua. Is liye ap LIVE STREAM aur
            FANTASY CRICKET nae istimal ker saktay.
          </div>
          <br />
          <div
            style={{
              lineHeight: `30px`,
              fontWeight: "500",
              wordSpacing: `10px`,
              color: "grey",
            }}
            className={`useUrduFont text-right`}
            dir="rtl"
          >
            پچھلے ٣ دن سے آپکا بل ادا نہیں ہوا . اس لئے لائیو سٹریم اور فینٹسی
            کرکٹ استعمال نہیں کر سکتے.
          </div>
          <br />

          <div className={`pb-5`}>
            <button
              className="submit border-0 btn bg-red text-white rounded-0 p-3 text-uppercase font-weight-bold"
              onClick={() => this.payNow()}
              disabled={this.state.btnDisbaled}
            >
              {`PAY NOW`}
            </button>
            {this.state.errorMessages && (
              <p className="message mt-3 text-danger text-center">
                {this.state.errorMessages}
              </p>
            )}

            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "1.7rem",
                lineHeight: "1.2 !important",
                fontWeight: "600",
              }}
              className={`mt-5`}
            >
              Ya abi discounted offers hasil karein.
            </div>

            <button
              className="submitHollow border-0 btn rounded-0 p-3 text-uppercase font-weight-bold"
              onClick={() => {
                if (this.props.onClickDiscountedBundles)
                  this.props.onClickDiscountedBundles();
              }}
              disabled={this.state.btnDisbaled}
            >
              {`DISCOUNTED OFFERS`}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default LowBalance;
