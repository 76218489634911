import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Globals } from "./constants";
import { getCDNUrl, getRegionFlags } from "./HelperFunctions";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regionFlags: getRegionFlags(localStorage.getItem("countryCode")),
    };
  }

  componentDidMount;

  render() {
    return (
      <footer>
        <div className="container py-5">
          <div className="d-flex flex-column d-lg-none">
            <div className="mr-auto w-100 d-flex justify-content-center">
              <div className="d-flex flex-column text-center">
                {Globals.product === "Cricwick" ? (
                  <>
                    <Link to="/mystars11-tnc">
                      MyStars11 - Terms and Conditions
                    </Link>
                    <Link to="/mystars11-faqs">MyStars11 - FAQs</Link>
                  </>
                ) : Globals.product == "Jazz Cricket" ? (
                  <>
                    <Link to="/mystars11-tnc">
                      MyStars11 - Terms and Conditions
                    </Link>
                    <Link to="/mystars11-faqs">MyStars11 - FAQs</Link>
                  </>
                ) : null}
              </div>
            </div>
            <div className="mx-auto mt-5 d-flex justify-content-center">
              &copy; 2023 {Globals.product}. All rights reserved
            </div>
            <div className="ml-auto mt-5 w-100 d-flex justify-content-center">
              <div>
                <img
                  onClick={() =>
                    Globals.telco === "mobilink"
                      ? window.open(
                          "https://itunes.apple.com/pk/app/jazz-cricket/id1371362811?mt=8"
                        )
                      : window.open(
                          "https://apps.apple.com/pk/app/cricwick-pakistan/id1461475044"
                        )
                  }
                  height="50"
                  src={getCDNUrl("/images/ios_icon.png")}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
                <img
                  onClick={() =>
                    Globals.telco === "mobilink"
                      ? window.open(
                          "https://play.google.com/store/apps/details?id=com.Khaleef.CricWickMobilink"
                        )
                      : window.open(
                          "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong"
                        )
                  }
                  height="50"
                  src={getCDNUrl("/images/android_icon.png")}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
                <img
                  onClick={() =>
                    Globals.telco === "mobilink"
                      ? window.open(
                          "https://appgallery.huawei.com/#/app/C100858031"
                        )
                      : window.open(
                          "https://appgallery.huawei.com/#/app/C100957899"
                        )
                  }
                  height="50"
                  src={getCDNUrl("/images/huawie_icon.png")}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="d-lg-flex d-none">
            <div className="mr-auto d-flex flex-column">
              {Globals.product === "Cricwick" ? (
                <>
                  <Link to="/mystars11-tnc">
                    MyStars11 - Terms and Conditions
                  </Link>
                  <Link to="/mystars11-faqs">MyStars11 - FAQs</Link>
                </>
              ) : Globals.product == "Jazz Cricket" ? (
                <>
                  <Link to="/mystars11-tnc">
                    MyStars11 - Terms and Conditions
                  </Link>
                  <Link to="/mystars11-faqs">MyStars11 - FAQs</Link>
                </>
              ) : null}
            </div>
            <div className="mx-auto">
              &copy; 2023 {Globals.product}. All rights reserved
            </div>
            <div className="ml-auto">
              <img
                onClick={() =>
                  Globals.telco === "mobilink"
                    ? window.open(
                        "https://itunes.apple.com/pk/app/jazz-cricket/id1371362811?mt=8"
                      )
                    : Globals.telco == "zain"
                      ? window.open(
                          "https://apps.apple.com/sa/app/cricwick-watch-live-cricket/id1461475044"
                        )
                      : this.state.regionFlags &&
                        this.state.regionFlags.EnableSaLinks
                        ? window.open(
                            "https://apps.apple.com/sa/app/cricwick-watch-live-cricket/id1461475044"
                          )
                        : JSON.parse(localStorage.getItem("user")) &&
                          JSON.parse(localStorage.getItem("user")).telco ==
                            "virgin"
                          ? window.open(
                              "https://apps.apple.com/sa/app/friendi-cricket-live/id1600465682"
                            )
                          : this.state.regionFlags &&
                            this.state.regionFlags.EnableBhLinks
                            ? window.open(
                                "https://apps.apple.com/bh/app/cricwick-bahrain/id1499601119"
                              )
                            : this.state.regionFlags &&
                              this.state.regionFlags.EnableKwLinks
                              ? window.open(
                                  "https://apps.apple.com/kw/app/cricwick-kuwait/id1499603060"
                                )
                              : this.state.regionFlags &&
                                this.state.regionFlags.EnableAeLinks
                                ? window.open(
                                    "https://apps.apple.com/ae/app/cricwick-uae/id1499604367"
                                  )
                                : window.open(
                                    "https://apps.apple.com/pk/app/cricwick-pakistan/id1461475044"
                                  )
                }
                height="50"
                src={getCDNUrl("/images/ios_icon.png")}
                style={{ cursor: "pointer" }}
                alt=""
              />
              <img
                onClick={() =>
                  Globals.telco === "mobilink"
                    ? window.open(
                        "https://play.google.com/store/apps/details?id=com.Khaleef.CricWickMobilink"
                      )
                    : Globals.telco == "zain"
                      ? window.open(
                          "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick"
                        )
                      : this.state.regionFlags &&
                        this.state.regionFlags.EnableSaLinks
                        ? window.open(
                            "https://play.google.com/store/apps/details?id=com.cricwick.ksa"
                          )
                        : JSON.parse(localStorage.getItem("user")) &&
                          JSON.parse(localStorage.getItem("user")).telco ==
                            "virgin"
                          ? window.open(
                              "https://play.google.com/store/apps/details?id=com.khaleef.friendi"
                            )
                          : this.state.regionFlags &&
                            this.state.regionFlags.EnableAeLinks
                            ? window.open(
                                "https://play.google.com/store/apps/details?id=com.cricwick.uae"
                              )
                            : this.state.regionFlags &&
                              this.state.regionFlags.EnableOmLinks
                              ? window.open(
                                  "https://play.google.com/store/apps/details?id=com.cricwick.oman"
                                )
                              : this.state.regionFlags &&
                                this.state.regionFlags.EnableKwLinks
                                ? window.open(
                                    "https://play.google.com/store/apps/details?id=com.cricwick.kuwait"
                                  )
                                : this.state.regionFlags &&
                                  this.state.regionFlags.EnableBhLinks
                                  ? window.open(
                                      "https://play.google.com/store/apps/details?id=com.cricwick.bahrain"
                                    )
                                  : window.open(
                                      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong"
                                    )
                }
                height="50"
                src={getCDNUrl("/images/android_icon.png")}
                style={{ cursor: "pointer" }}
                alt=""
              />
              {localStorage.getItem("countryCode") != "sa" &&
              localStorage.getItem("countryCode") != "SA" ? (
                <img
                  onClick={() =>
                    Globals.telco === "mobilink"
                      ? window.open(
                          "https://appgallery.huawei.com/#/app/C100858031"
                        )
                      : window.open(
                          "https://appgallery.huawei.com/#/app/C100957899"
                        )
                  }
                  height="50"
                  src={getCDNUrl("/images/huawie_icon.png")}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              ) : null}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
