import React, { Component } from "react";
import { space2hyphen, space2hyphenForSlug, SquadBox } from "./HelperFunctions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { canonicalLink } from "./HelperFunctions";

class MatchSquads extends Component {
  render() {
    return (
      <div className={"card mt-2 p-3 " + this.props.customCssTransition}>
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.props.hasOwnProperty("match") && this.props.match ? (
          <div className="d-flex overflow-x-auto py-3 border-bottom">
            <Link
              to={
                "/match/" +
                this.props.match.id +
                "/squads/1/" +
                space2hyphenForSlug(
                  this.props.match.teamA.name +
                    " vs " +
                    this.props.match.teamB.name +
                    " " +
                    this.props.match.title
                )
              }
            >
              <h1
                className={
                  getActiveClasses(
                    this.props.activeSquadsId,
                    this.props.match,
                    1
                  ) + " text-capitalize"
                }
              >
                {this.props.match.teamA.name}
              </h1>
            </Link>
            <Link
              to={
                "/match/" +
                this.props.match.id +
                "/squads/2/" +
                space2hyphenForSlug(
                  this.props.match.teamA.name +
                    " vs " +
                    this.props.match.teamB.name +
                    " " +
                    this.props.match.title
                )
              }
            >
              <h1
                className={
                  getActiveClasses(
                    this.props.activeSquadsId,
                    this.props.match,
                    2
                  ) + " text-capitalize"
                }
              >
                {this.props.match.teamB.name}
              </h1>
            </Link>
          </div>
        ) : (
          <div />
        )}

        <div className="pt-4 row">
          {this.props.data.map((obj, i) => (
            <SquadBox key={"squads-" + i} data={obj} />
          ))}
        </div>
      </div>
    );
  }
}

const getActiveClasses = (id, match, order) => {
  if (order === 1 && id !== match.team_1_id) {
    return "font-weight-semi-bold p-2 ml-4 text-secondary";
  }
  if (order === 2 && id !== match.team_2_id) {
    return "font-weight-semi-bold p-2 ml-4 text-secondary";
  }
  return "font-weight-semi-bold p-2 ml-4 border-bottom border-danger";
};

export default MatchSquads;
