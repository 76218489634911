import axios from "axios";
import React, { Component } from "react";
import PackageCard from "../newLoginFlow/packageCard";
import TelcoPackageCard from "../newLoginFlow/telcoPackageCard";
import { Globals } from "../constants";
import Loading from "../Loading";
import packageCardStyles from "../newLoginFlow/packageCard.module.css";
import { withRouter } from "react-router";
const queryString = require("query-string");

class SubscriptionPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      // apiPath: `${
      //   Globals.UserManagment
      // }api/v1/packages/get-all-packages?region=1`,
      // apiPath: `${Globals.NodeApi}api/v1/bundles/get_all_packages?region=1`,
      plans: null,
      plans2: null,
      plans3: null,
      expanded: true,
      fromInvite: false,
    };
  }

  subscriptionTypes = [
    "daily",
    "weekly",
    "monthly",
    "biweekly",
    "monthly",
    "months",
    "year",
  ];

  getPlansFromApi = async (pkg_type = 1, stateVar = "plans") => {
    let apiurl = `${Globals.NodeApi}api/get_packages?pkg_type=${pkg_type}`;

    try {
      let response = await axios.get(apiurl);
      console.log("this.state.plans3", response);
      this.setState({
        [stateVar]: response.data.remote_response.data.packages,
        isLoading: false,
      });
    } catch (error) {
      console.log(`Cannot get plans: `, error);
    }
  };

  async componentDidMount() {
    console.log("telconame", this.props.telcoName);
    this.getPlansFromApi(1);
    this.getPlansFromApi(2, "plans2");
    this.getPlansFromApi(3, "plans3");
    let parsed = queryString.parse(this.props.location.search);
    if (parsed.fromInvite)
      this.setState({
        fromInvite: true,
      });
    // if (this.props.changeSubscription) {

    //   this.setState({
    //     subscriptionType: user.subscriptionType,
    //     paymentType: user.paymentType,
    //   });
    // }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div>
            <div
              style={{
                textAlign: "center",
                // marginBottom: "20px",
                fontSize: "1.5rem",
                lineHeight: "1.2 !important",
                fontWeight: "700",
              }}
            >
              Subscription Plans
            </div>

            <div
              className="text-center"
              style={{ fontSize: "0.9rem", fontWeight: "600" }}
            >
              Awesome Bundles. Cancel anytime.
            </div>
            <div className="mt-4">
              {this.state.plans3 &&
                this.state.plans3.map((plan, index) => {
                  return (
                    <PackageCard
                      packageDetails={plan}
                      key={plan.title}
                      index={index}
                      onPlanSelection={this.props.onPlanSelection}
                    />
                  );
                })}
              {this.state.plans2 &&
                this.state.plans2.map((plan, index) => {
                  return (
                    <PackageCard
                      packageDetails={plan}
                      key={plan.title}
                      index={index}
                      onPlanSelection={this.props.onPlanSelection}
                    />
                  );
                })}
              {this.props.isJazzEnabled === false &&
              this.props.telcoName !== "mobilink" &&
              this.props.telcoName !== "warid" &&
              this.props.telcoName !== "jazz" &&
              this.props.isSubscribed === false ? (
                <TelcoPackageCard onClick={this.props.onTelcoCardClick} />
              ) : (
                ""
              )}
              {!this.state.fromInvite ? (
                <div
                  className={`mt-2 mb-4 pl-4 pr-4 pb-4 pt-2 `}
                  id={`${packageCardStyles.bundleBox}`}
                  style={{
                    cursor: `pointer`,
                    border: `${
                      this.state.expanded ? `1.5px solid #cf3737` : `none`
                    } `,
                  }}
                  onClick={() => {
                    if (!this.state.expanded)
                      this.setState({
                        expanded: !this.state.expanded,
                      });
                  }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center w-100"
                    onClick={() => {
                      // if (!this.state.expanded)
                      this.setState({
                        expanded: !this.state.expanded,
                      });
                    }}
                  >
                    <div className="w-100">
                      <div className=" redCl">
                        <span
                          className={`${packageCardStyles.packageDuration}`}
                        >{`Check Other bundles`}</span>
                      </div>
                    </div>
                    <div
                      className={`d-flex justify-content-center align-items-center pt-2`}
                    >
                      <span className="">
                        <i
                          className={`fa fa-chevron-${
                            this.state.expanded ? "up" : "down"
                          }`}
                        />
                      </span>
                    </div>
                  </div>
                  <div className={`${this.state.expanded ? "mt-4" : ""}`}>
                    {this.state.expanded &&
                      this.state.plans &&
                      this.state.plans.map((plan, index) => {
                        return (
                          <PackageCard
                            packageDetails={plan}
                            key={plan.title}
                            index={index}
                            onPlanSelection={this.props.onPlanSelection}
                          />
                        );
                      })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SubscriptionPlans);
