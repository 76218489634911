import React, { Component } from "react";
import { Globals } from "./constants";
import ImageLoader from "react-load-image";
import { Helmet } from "react-helmet";
import { canonicalLink } from "./HelperFunctions";

class MatchReport extends Component {
  state = {
    shareOn: "image",
  };
  async componentDidMount() {
    this.myElement.addEventListener("DOMSubtreeModified", () => {
      window.twttr.widgets.load(document.getElementById("tweetss"));
    });
  }

  getShortString(apiResp) {
    if (apiResp.summary) {
      let str = apiResp.summary;
      return str;
    } else {
      let str = apiResp.body ? apiResp.body.substring(5, 100) : "";
      return str;
    }
  }

  getImageForFacebookShare(apiResp) {
    let imageFB =
      apiResp[
        this.state.shareOn === "image"
          ? `${this.state.shareOn}`
          : `${this.state.shareOn}_image_url`
      ];
    if (
      imageFB == null ||
      imageFB == undefined ||
      imageFB == "" ||
      imageFB.indexOf("/missing.png") !== -1
    )
      imageFB = apiResp.image;
    return imageFB;
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:url" content={"Cricket!"} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={this.props.article.title} />
          <meta
            property="og:description"
            content={this.getShortString(this.props.article)}
          />
          <meta
            property="og:image"
            content={this.getImageForFacebookShare(this.props.article)}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        <div className="card mt-2 p-3">
          <div className="article pb-4 mb-3">
            <div className="d-flex">
              <div className="ml-auto navbar-expand">
                <ul className="navbar-nav ">
                  <li className="nav-item">
                    <a
                      data-original-title="Facebook"
                      className="nav-link text-secondary h1"
                      onClick={() => {
                        this.setState(
                          {
                            shareOn: "facebook",
                          },
                          () =>
                            window.open(
                              "http://www.facebook.com/sharer/sharer.php?u=" +
                                window.location.href +
                                `${""}`,
                              "_blank"
                            )
                        );
                      }}
                    >
                      <i class="fa fa-facebook" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      data-original-title="twitter"
                      className="nav-link text-secondary h1"
                      href={
                        "https://twitter.com/intent/tweet?text=" +
                        this.props.article.title +
                        " " +
                        window.location.href +
                        `${"/"}`
                      }
                      onClick={() => {
                        this.setState(
                          {
                            shareOn: "twitter",
                          },
                          () =>
                            window.open(
                              "https://twitter.com/intent/tweet?text=" +
                                this.props.article.title +
                                " " +
                                window.location.href +
                                `${"/"}`,
                              "_blank"
                            )
                        );
                      }}
                    >
                      <i class="fa fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="article-image">
              {/* <img className="w-100" src={this.props.article.image} /> */}
              <ImageLoader src={this.props.article.image}>
                <img className="w-100" />
                <div>Error!</div>
                <Preloader />
              </ImageLoader>
            </div>
            <div className="article-credits my-3 fs-1p2 pb-2 border-bottom text-medium-grey d-flex m-f-12">
              <span className="">{this.props.article.by}</span>
              <span className="ml-auto">{getDate(this.props.article)}</span>
            </div>
            <div className="text-black fs-1p2 m-f-12">
              <div
                dangerouslySetInnerHTML={{ __html: this.props.article.body }}
                ref={(myElement) => (this.myElement = myElement)}
                id={`tweetss`}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function Preloader() {
  return <img className="w-100" src={Globals.placeholder16x9} />;
}

const getDate = (props) => {
  let datestr,
    date = "";
  if (typeof props !== "undefined" && props.created_at) {
    datestr = props.created_at;
    date = new Date(datestr).toDateString().split(" ");
    date = date[2] + " " + date[1] + ", " + date[3];
  }

  return date;
};

export default MatchReport;
