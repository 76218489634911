import React, { Component } from "react";

import { Globals } from "./constants";
import LeftUpdatesList from "./components/leftUpdatesList";
import { Telco } from "./telco";

import { Carousel } from "react-responsive-carousel";

class Leftsection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seriesApiPath: Globals.ApiUrl + "api/v2/series/featured",
      seriesApiResp: [],
      updateApiPath: Globals.ApiUrl + "api/news?page=1&per_page=6",
      updateApiResp: [],
      error: null, //by Tariq
      loading: true, //by Tariq
    };

    this.apiPath = Globals.NodeApi + "api/";
    this.callGoogleEvent = this.callGoogleEvent.bind(this);
  }

  callGoogleEvent(eventName) {
    window.gtag("event", eventName);
  }

  onAdChanged(ad) {
    switch (ad) {
      case 0:
        window.gtag("event", "w_left_gago_ludo_watch");
        break;
      case 1:
        window.gtag("event", "w_left_gago_watch");
        break;
      case 2:
        window.gtag("event", "w_left_mktv_watch");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div id="left-section" className="d-none d-lg-block pr-4">
        <div className="left-section">
          {/* {
              Telco === "mobilink"
              ?
              ""
              :
              <Carousel showIndicators={false} infiniteLoop={true} autoPlay={true} showArrows={false} showStatus={false} showThumbs={false} onChange={this.onAdChanged}>  
                  <div className="card"  onClick={
                                                    () => {
                                                    window.gtag("event", "w_left_gago_ludo_click");
                                                    window.open(
                                                      `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_Left_Banner_Ludo&utm_campaign=Cricwick&partner_id=2`,
                                                      `_blank`
                                                    )}
                                                  }>
                      <img src={'https://asset.cricvids.co/cricwick-assets/ads/Gago/gago_ludo_left.jpg'}  alt=""/>
                  </div>
                  
                  <div className="card"  onClick={
                                                    () => {
                                                    window.gtag("event", "w_left_gago_click");
                                                    window.open(
                                                      `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_Left_Banner&utm_campaign=Cricwick&partner_id=2`,
                                                      `_blank`
                                                    )}
                                                  }>
                      <img src={'https://asset.cricvids.co/cricwick-assets/ads/Gago/w_left_gago_new.png'}  alt=""/>
                  </div>

                  <div className="card"  onClick={
                                                    () => {
                                                    window.gtag("event", "w_left_mktv_click");
                                                    window.open(
                                                      `http://mktv.knectapi.com/?utm_source=203&utm_medium=Desktop_Left_Banner_MKTV&partner_id=2`,
                                                      `_blank`
                                                    )}
                                                  }>
                      <img src={'https://asset.cricvids.co/cricwick-assets/ads/mktv/185x100.jpg'}  alt=""/>
                  </div>
              </Carousel>
          }
           */}
          <LeftUpdatesList />
        </div>
      </div>
    );
  }
}

export default Leftsection;
