import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./onboarding.css";

import { withRouter } from "react-router-dom";
import SocialAuth from "../Modal";
import axios from "axios";
import {
  getCDNUrl,
  getTelcoName,
  getMenaTelcoName,
  loginUser,
  logoutUser,
  getRegionFlags,
} from "../../HelperFunctions";
import { Globals } from "../../constants";

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      letPlayClicket: false,
      flowCompleted: false,
      config: null,
    };

    this.isSocialProfileAttached =
      Globals.telco !== "mobilink" &&
      localStorage.getItem("isSocialProfileAttached")
        ? localStorage.getItem("isSocialProfileAttached")
        : false;
    this.appStartUrl = `${Globals.UserManagment}api/v1/users/app-start`;
    this.appStartUrlKSA = `${Globals.UserManagment}api/v1/users/app-start-KSA`;

    this.countryCode = localStorage.getItem("countryCode")
      ? localStorage.getItem("countryCode")
      : null;
  }

  componentDidMount() {
    let user = localStorage.getItem("user");

    let regionFlags = getRegionFlags(this.countryCode);
    if (regionFlags.isPKRegion) {
      this._appStart(JSON.parse(user));
    } else {
      this._appStartKSA(JSON.parse(user));
    }
  }

  _appStartKSA(user) {
    let xAccessToken = localStorage.getItem("xAccessToken");
    let phone = localStorage.getItem("phone");
    if (
      (user && user.xAccessToken && user.xAccessToken.length > 0) ||
      (user && xAccessToken && xAccessToken.length > 0)
    ) {
      let url = this.appStartUrlKSA;
      axios
        .post(url, {
          msisdn: user.phone ? user.phone : phone,
          appName: "CricwickMena",
          platform: "web",
        })
        .then((resp) => {
          let data = resp.data.data;

          if (
            data &&
            (data.subscriptionStatus === 1 || data.subscriptionStatus === 3)
          ) {
            let userObject = {
              name: data.name,
              id: data.id,
              isSocialLogin: data.isSocialLogin,
              email: data.email,
              phone: data.msisdn,
              subscribed:
                data.subscriptionStatus === 1 || data.subscriptionStatus === 3
                  ? 1
                  : 0,
              signInProvider: data.signInProvider,
              telco: getMenaTelcoName(data.telcoId, 0),
              profilePicture: data.avatarUrl,
              cnic: data.cnic,
              cnicIssueDate: data.cnicIssueDate,
              isSocialProfileAttached: data.isSocialProfileAttached,
              xAccessToken: user.xAccessToken,
              isUserGracePeriodElapsed: data.isUserGracePeriodElapsed,
              subscriptionType: data.subscriptionType,
              paymentType: data.paymentType,
              inviteCode: data.inviteCode,
              authToken: user.authToken,
              checkInStreak: data.checkInStreak,
              nextClaimDate: data.nextClaimDate,
              subscriptionStatus: data.subscriptionStatus,
            };

            localStorage.setItem("user_logged_in", true);
            localStorage.setItem("id", data.id);
            loginUser(userObject);
            localStorage.setItem("xAccessToken", user.xAccessToken);
            localStorage.setItem("user", JSON.stringify(userObject));
          } else {
            logoutUser();
          }
        });
    }
  }

  _appStart(user) {
    let xAccessToken = localStorage.getItem("xAccessToken");
    if (
      (user && user.xAccessToken && user.xAccessToken.length > 0) ||
      (user && xAccessToken && xAccessToken.length > 0)
    ) {
      let url = this.appStartUrl;
      axios
        .post(url, {
          token: user.xAccessToken ? user.xAccessToken : xAccessToken,
          //tokenType: "telco",
          appName:
            Globals.telco == "mobilink" ? "JazzCricketWeb" : "CricwickWeb",
          platform: "web",
        })
        .then((resp) => {
          let data = resp.data.data;

          if (
            data &&
            (data.subscriptionStatus === 1 || data.subscriptionStatus === 2)
          ) {
            let userObject = {
              name: data.name,
              id: data.id,
              isSocialLogin: data.isSocialLogin,
              email: data.email,
              phone: data.msisdn,
              subscribed:
                data.subscriptionStatus === 1 || data.subscriptionStatus === 2
                  ? 1
                  : 0,
              signInProvider: data.signInProvider,
              telco: getTelcoName(data.telcoId, 0),
              profilePicture: data.avatarUrl,
              cnic: data.cnic,
              cnicIssueDate: data.cnicIssueDate,
              isSocialProfileAttached: data.isSocialProfileAttached,
              xAccessToken: user.xAccessToken,
              isUserGracePeriodElapsed: data.isUserGracePeriodElapsed,
              subscriptionType: data.subscriptionType,
              paymentType: data.paymentType,
              inviteCode: data.inviteCode,
              authToken: user.authToken,
              checkInStreak: data.checkInStreak,
              nextClaimDate: data.nextClaimDate,
              subscriptionStatus: data.subscriptionStatus,
            };

            localStorage.setItem("user_logged_in", true);
            localStorage.setItem("id", data.id);
            loginUser(userObject);
            localStorage.setItem("xAccessToken", user.xAccessToken);
            localStorage.setItem("user", JSON.stringify(userObject));
          } else {
            logoutUser();
          }
        });
    }
  }

  LetsPlayClicked() {
    this.setState({
      letPlayClicket: true,
      flowCompleted: false,
    });
    this.props.history.push("trivia");
  }

  render() {
    var settings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 200,
      swipeToSlide: true,
      slidesToShow: 1,
    };
    if (this.state.isLoading) {
      return null;
    }
    if (
      this.isSocialProfileAttached === "false" &&
      this.countryCode &&
      this.countryCode === "pk"
    ) {
      return <SocialAuth />;
    }
    if (this.state.flowCompleted && this.isMBLOn === "true") {
      return (
        <div className="d-lg-none d-md-block d-sm-block">
          <div
            onClick={() => {
              this.props.history.push("/trivia");
            }}
            className="omni-present"
          >
            <img
              width=""
              src={getCDNUrl("/images/trivia/onboarding/omni-present.png")}
            />
          </div>
        </div>
      );
    }
    if (this.state.letPlayClicket) {
      return (
        <div className="d-lg-none d-md-block d-sm-block">
          <div className={`main-bg-ob `} style={{ zIndex: "1" }} />
          {this.isMBLOn === "true" && (
            <div className="omni-present">
              <img
                width=""
                src={getCDNUrl("/images/trivia/onboarding/omni-present.png")}
              />
            </div>
          )}
          {this.isMBLOn === "true" && (
            <div className="omni-present-left ">
              <img src={getCDNUrl("/images/trivia/onboarding/popup.png")} />
              <div className="omni-present-text-box p-4">
                <div className="mb-1" style={{ fontWeight: "bold" }}>
                  Master Blaster League
                </div>

                <div>
                  Your score ,acheivments from the league can be found at one
                  place
                </div>
                <div
                  onClick={() => {
                    this.setState({ flowCompleted: true });
                  }}
                  className="my-1 text-right"
                  style={{ fontWeight: "bold", width: "87%" }}
                >
                  Got it
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    if (this.isMBLOn === "true") {
      return (
        <div>
          <div className="main-bg-ob" />

          <div className="omni-present d-lg-none d-md-block d-sm-block">
            <img
              width=""
              src={getCDNUrl("/images/trivia/onboarding/omni-present.png")}
            />
          </div>

          <div className="text-center ob-container">
            <embed
              style={{ position: "absolute", top: "0", left: "0" }}
              src="./images/trivia/onboarding/bg.svg"
              width="100%"
            />
            <embed
              style={{ position: "absolute", top: "-18px", left: "50%" }}
              className="ob-logo"
              src="./images/trivia/onboarding/masterlogo.svg"
            />

            <div className="main-slider">
              <Slider {...settings}>
                <div>{this.ScreenIntro()}</div>
                <div>{this.ScreenGameFormat()}</div>
                <div>{this.ScreenCash()}</div>
                <div>{this.ScreenLetsPlay()}</div>
              </Slider>
            </div>

            <div className="terms-conditions">
              Visit{" "}
              <a
                href="http://cricwick.net/trivia/info"
                style={{ color: "white", fontWeight: "bold" }}
              >
                www.cricwick.net
              </a>{" "}
              for Terms & Conditions
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  ScreenIntro() {
    const { config } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="text-center ob-text">
              <div style={{ fontFamily: "Patua One, cursive" }}>
                MASTER BLASTER LEAGUE = {config.league_infoboard_screen1_text_1}{" "}
              </div>
              {/* <div style={{ 'fontFamily': "Patua One, cursive" }}>Zabardast Cash</div>
                            <div style={{ 'fontFamily': "Patua One, cursive" }}>Inamat Jeetna ka Mooqa!</div> */}

              <div className="mt-2">
                <embed
                  style={{ display: "inline", width: "60%" }}
                  src="./images/trivia/onboarding/man-cash.svg"
                />
              </div>
              {/* <div className="px-4 mt-2">
                                Zyada say zyada <span style={{ fontWeight: 'bolder' }}> {config.league_infoboard_screen1_text_2.snapables[0]} </span> istemal krain or rozana hazaro ka inamat jeetain
                                </div> */}
              <div className="px-4 mt-5">
                <span
                  style={{
                    color: config.league_infoboard_screen1_text_2.color,
                  }}
                >
                  {config.league_infoboard_screen1_text_2.text}
                </span>
                <span
                  style={{
                    color: config.league_infoboard_screen1_text_3.color,
                  }}
                >
                  {config.league_infoboard_screen1_text_3.text}
                </span>
                {/* <span style={{ fontWeight: 'bolder', color: 'yellow' }}> {config.league_infoboard_screen1_text_3.snapables[0]} </span  > ka <span style={{ fontWeight: 'bolder', color: 'yellow' }}>{config.league_infoboard_screen1_text_3.snapables[1]}</span> jitna ka mooka pain */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  ScreenGameFormat() {
    const { config } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="text-center">
              <div
                style={{
                  fontFamily: "Patua One, cursive",
                  fontSize: "18px",
                  color: "white",
                }}
              >
                {config.league_infoboard_screen2_heading}
              </div>

              <div className="pl-1">
                <span style={{ width: "25%" }} className="d-inline-block pl-1">
                  <embed
                    style={{ marginLeft: "10px" }}
                    src="./images/trivia/onboarding/t20-tag.svg"
                  />
                </span>
                <span
                  style={{ width: "75%" }}
                  className="d-inline-block ob-text2 p-3"
                >
                  <div>
                    mein rozana 10 sawalaat k SAHI jawab dein aur{" "}
                    <span>
                      {config.league_infoboard_screen2_t20_text.snapables[1]}
                    </span>{" "}
                    <span style={{ fontWeight: "bolder", color: "yellow" }}>
                      {config.league_infoboard_screen2_t20_text.snapables[0]}
                    </span>{" "}
                    tk ka cash jeetne ka moqa payein.
                  </div>
                </span>
              </div>
              <hr className="m-0" />
              <div className="pl-1">
                <span
                  style={{ paddingBottom: "15px", width: "25%" }}
                  className="d-inline-block pl-1"
                >
                  <embed
                    style={{ marginLeft: "10px" }}
                    src="./images/trivia/onboarding/odi-tag.svg"
                  />
                </span>
                <span
                  style={{ width: "75%" }}
                  className="d-inline-block ob-text2 p-3"
                >
                  <div>{config.league_infoboard_screen2_odi_text.text}</div>
                </span>
              </div>
              <hr className="m-0" />
              <div className="pl-1">
                <span
                  style={{ paddingBottom: "15px", width: "25%" }}
                  className="d-inline-block pl-1"
                >
                  <embed
                    style={{ marginLeft: "10px" }}
                    src="./images/trivia/onboarding/test-tag.svg"
                  />
                </span>
                <span
                  style={{ width: "75%" }}
                  className="d-inline-block ob-text2 p-3"
                >
                  <div>
                    mein kam se kam 3 din khelein aur haftawaar{" "}
                    <span style={{ fontWeight: "bolder", color: "yellow" }}>
                      {config.league_infoboard_screen2_test_text.snapables[0]}
                    </span>{" "}
                    tak ka CASH jeetne ke liye TOP 200 SCORERS mein shamil ho
                    jayein.{" "}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  ScreenCash() {
    const { config } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="text-center ob-text">
              <div
                style={{
                  fontFamily: "Patua One, cursive",
                  fontSize: "14px",
                  margin: "auto",
                  width: "80%",
                }}
              >
                {config.league_infoboard_screen3_text_1}
              </div>

              <div className="mt-3">
                <embed
                  style={{ display: "inline", width: "60%" }}
                  src="./images/trivia/onboarding/happy-guy.svg"
                />
              </div>
              <div className="px-4 mt-3">
                {config.league_infoboard_screen3_text_2.text}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  ScreenLetsPlay() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <div>
              {/* <div className="mx-auto mt-5" style={{ backgroundColor: '#170F53', borderRadius: '50%', width: '180px', height: '180px' }}>

                            </div> */}
              <div>
                <img
                  className="img-fluid m-auto"
                  src="/images/trivia/onboarding/lets_play.png"
                />
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  this.LetsPlayClicked();
                }}
                className="mx-auto mt-5"
                style={{
                  backgroundColor: "#FFE500",
                  borderRadius: "42px",
                  width: "226px",
                  height: "45px",
                }}
              >
                <span
                  className="text-center"
                  style={{
                    lineHeight: "42px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Let's Play
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OnBoarding);
