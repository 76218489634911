import React, { Component } from "react";
import { getCDNUrl } from "../HelperFunctions";
import packageCardStyles from "./packageCard.module.css";

class PackageCard extends Component {
  state = {
    expanded: false,
  };

  returnSubscriptionIncludes = (features) => {
    return (
      <div>
        <div
          className="d-flex justify-content-start w-100"
          style={{
            marginTop: `10px`,
          }}
        >
          <span
            className={`${packageCardStyles.subhead}`}
          >{`Subscription Includes:`}</span>
        </div>{" "}
        <div>
          {features.map((feature) => {
            return (
              <div className={`d-flex mt-2`} key={feature.title}>
                <div
                  className={`d-flex justify-content-center align-items-center`}
                >
                  {feature.icon_url ? (
                    <img
                      src={`${feature.icon_url}`}
                      alt=""
                      width={25}
                      height={25}
                    />
                  ) : null}
                </div>
                <div className="d-flex justify-content-start align-items-center ml-3">
                  <span>{`${feature.title ? feature.title : ``}`}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  returnPayWith = (paymentGatways) => {
    return (
      <div>
        <div
          className="d-flex justify-content-start w-100"
          style={{
            marginTop: `10px`,
          }}
        >
          <span className={`${packageCardStyles.subhead}`}>{`Pay With:`}</span>
        </div>{" "}
        <div>
          {paymentGatways.map((paymentGatway, index) => {
            return (
              <div
                className={`d-flex justify-content-between `}
                key={paymentGatway.title}
                style={{
                  cursor: `pointer`,
                  marginTop: index !== 0 ? `23px` : `12px`,
                }}
                onClick={() => {
                  if (this.props.onPlanSelection)
                    this.props.onPlanSelection(
                      this.props.packageDetails,
                      index
                    );
                }}
              >
                <div
                  className={`d-flex justify-content-center align-items-center`}
                >
                  {paymentGatway.icon_url ? (
                    <img
                      src={`${paymentGatway.icon_url}`}
                      alt=""
                      width={`100%`}
                      height={`auto`}
                    />
                  ) : null}
                </div>
                <div
                  className={`d-flex justify-content-center align-items-center`}
                >
                  <span className="">
                    <i className="fa fa-chevron-right" />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  componentDidMount() {
    if (this.props.packageDetails.is_best)
      this.setState({
        expanded: true,
      });
    else if (this.props.expanded && this.props.expanded !== this.state.expanded)
      this.setState({
        expanded: this.props.expanded,
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.expanded && this.props.expanded !== prevProps.expanded)
      this.setState({
        expanded: this.props.expanded,
      });
  }

  render() {
    let packageDetails = this.props.packageDetails;
    return (
      <React.Fragment>
        {packageDetails ? (
          <div
            className={`mt-2 mb-4 pl-4 pr-4 pb-4 ${
              !this.state.expanded ? packageCardStyles.bundleBox1 : ``
            }`}
            id={`${packageCardStyles.bundleBox}`}
            onClick={() => {
              if (!this.state.expanded)
                this.setState({
                  expanded: true,
                });
            }}
            style={{
              cursor: this.state.expanded ? `` : `pointer`,
              border: `${
                this.state.expanded ? `1.5px solid #cf3737` : `none`
              } `,
            }}
          >
            <div
              className={`d-flex justify-content-between pt-4 ${
                packageDetails.is_best ? `mt-2` : ``
              }`}
              style={{
                cursor: `pointer`,
              }}
              onClick={(e) => {
                if (this.state.expanded) this.setState({ expanded: false });
              }}
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                {packageDetails.is_best ? (
                  <div className={`d-flex`}>
                    <div className={`d-flex align-items-center mr-2`}>
                      <img
                        className={`${packageCardStyles.bestValue}`}
                        src={getCDNUrl(`/images/payout/star.svg`)}
                        width={`10px`}
                        height={`10px`}
                        alt=""
                      />
                    </div>
                    <div className={`d-flex align-items-center`}>
                      <span
                        className={`${packageCardStyles.bestValue}`}
                      >{`Most Popular`}</span>
                    </div>
                  </div>
                ) : null}
                <div className="d-flex w-100 mt-1">
                  <div className="">
                    <span
                      className={`${packageCardStyles.packageDuration}`}
                    >{`${
                      packageDetails.title ? packageDetails.title : ``
                    }`}</span>
                  </div>
                  {packageDetails.save_percentage ? (
                    <div
                      className={`${
                        packageCardStyles.chip
                      } d-flex justify-content-center align-items-center`}
                    >
                      <span className="text-center">{`${
                        packageDetails.save_percentage
                          ? packageDetails.save_percentage
                          : ``
                      }`}</span>
                    </div>
                  ) : null}
                </div>
                {packageDetails.description ? (
                  <div
                    className="d-flex justify-content-start w-80"
                    style={{
                      marginTop: `10px`,
                      marginRight: `25px`,
                    }}
                  >
                    <span className={`${packageCardStyles.packageSubhead}`}>{`${
                      packageDetails.description
                        ? packageDetails.description
                        : ``
                    }`}</span>
                  </div>
                ) : null}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  {packageDetails.actual_amount &&
                  packageDetails.actual_amount !== packageDetails.amount ? (
                    <div
                      className={`d-flex align-items-center justify-content-end`}
                    >
                      <div
                        className={`d-flex align-items-center justify-content-end ${
                          packageCardStyles.actualAmountDiv
                        }`}
                      >
                        <div className="mr-1">
                          <span
                            className={`${packageCardStyles.actualAmount}`}
                          >{`${packageDetails.region.currency}`}</span>
                        </div>
                        <div>
                          <span
                            className={`${packageCardStyles.actualAmount}`}
                          >{`${
                            packageDetails.actual_amount
                              ? packageDetails.actual_amount
                              : ``
                          }`}</span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={`d-flex align-items-center justify-content-end`}
                  >
                    <div className="pt-1 mr-2">
                      <span
                        className={`${packageCardStyles.packageCurrency}`}
                      >{`${packageDetails.region.currency}`}</span>
                    </div>
                    <div>
                      <span className={`${packageCardStyles.pricePoint}`}>{`${
                        packageDetails.amount ? packageDetails.amount : ``
                      }`}</span>
                    </div>
                  </div>
                  <div className="text-right">
                    <span
                      className={`${packageCardStyles.pricePointSubHeading}`}
                    >{`Inclusive of taxes`}</span>
                  </div>
                </div>
              </div>
            </div>

            {this.state.expanded && packageDetails.features.length
              ? this.returnSubscriptionIncludes(packageDetails.features)
              : null}

            {this.state.expanded &&
            !this.props.dontShowPaymentGateways &&
            packageDetails.payment_gateways.length ? (
              <React.Fragment>
                <div
                  style={{
                    borderBottom: `1px solid #d4d4d4`,
                    marginTop: `20px`,
                    marginBottom: `20px`,
                  }}
                />
                {this.returnPayWith(packageDetails.payment_gateways)}
              </React.Fragment>
            ) : null}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default PackageCard;
