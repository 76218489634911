import React, { Component, useState } from "react";
import testmain2 from "./images/test-main2.png";
import testmain from "./images/test-main.png";
import { Carousel } from "react-responsive-carousel";

import { handleGoogleEvents } from "./HelperFunctions";

import { Globals } from "./constants";
import axios from "axios";

class CricWickAds extends Component {
  callGoogleEvent(eventName) {
    window.gtag("event", eventName);
  }

  onAdChanged(ad) {
    switch (ad) {
      case 0:
        window.gtag("event", "w_nav_gago_ludo_watch");
        break;
      case 1:
        window.gtag("event", "w_nav_gago_watch");
        break;
      case 2:
        window.gtag("event", "w_nav_mktv_watch");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <div className={`d-none d-lg-block `}>
          <div className="d-flex  cricwick-ads justify-content-center align-items-center pt-2 pb-2">
            <Carousel
              className="mt-2"
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              onChange={this.onAdChanged}
            >
              {/* {this.state.homeScreen.map((checkNavValue, i) => {
                return(
                    <div className="card" onClick={() =>
                      window.open(checkNavValue.link)}>
                    <img src={checkNavValue.imageUrl} style={{ height: "90px", width: "728px" }} alt=""/>
                    </div>
                    )
                  })} */}
              <div
                className="card"
                onClick={() => {
                  this.callGoogleEvent("w_nav_gago_ludo_click");
                  window.open(
                    `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_Nav_Banner_Ludo&utm_campaign=Cricwick&partner_id=2`,
                    `_blank`
                  );
                }}
              >
                <img
                  src={
                    "https://asset.cricvids.co/cricwick-assets/ads/Gago/gago_ludo_nav.jpg"
                  }
                  alt=""
                />
              </div>

              <div
                className="card"
                onClick={() => {
                  this.callGoogleEvent("w_nav_gago_click");
                  window.open(
                    `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_TopHead_Banner&utm_campaign=Cricwick&partner_id=2`,
                    `_blank`
                  );
                }}
              >
                <img
                  src={
                    "https://asset.cricvids.co/cricwick-assets/ads/Gago/web_gago_new_728.png"
                  }
                  alt=""
                />
              </div>

              <div
                className="card"
                onClick={() => {
                  this.callGoogleEvent("w_nav_mktv_click");
                  window.open(
                    `http://mktv.knectapi.com/?utm_source=203&utm_medium=Desktop_Nav_Banner_MKTV&partner_id=2`,
                    `_blank`
                  );
                }}
              >
                <img
                  src={
                    "https://asset.cricvids.co/cricwick-assets/ads/mktv/w_nav.jpg"
                  }
                  alt=""
                />
              </div>
            </Carousel>
          </div>
        </div>

        <div className={`d-lg-none`}>
          <div className="d-flex  cricwick-ads justify-content-center align-items-center pt-2 pb-2">
            <Carousel
              className="mt-2"
              showIndicators={false}
              infiniteLoop={true}
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              onChange={this.onAdChanged}
            >
              {/* {this.state.homeScreen.map((checkNavValue, i) => {
                return(
                    <div className="card" onClick={() =>
                      window.open(checkNavValue.link)}>
                    <img src={checkNavValue.imageUrl} style={{ height: "90px", width: "728px" }} alt=""/>
                    </div>
                    )
                  })} */}

              <div
                className="card"
                onClick={() => {
                  this.callGoogleEvent("w_nav_gago_ludo_click");
                  window.open(
                    `http://d.gago.games/pk?utm_source=183&utm_medium=Desktop_Mobile_Banner_Ludo&utm_campaign=Cricwick&partner_id=2`,
                    `_blank`
                  );
                }}
              >
                <img
                  src={
                    "https://asset.cricvids.co/cricwick-assets/ads/Gago/gago_ludo_mobile.jpg"
                  }
                  alt=""
                />
              </div>

              <div
                className="card"
                onClick={() => {
                  this.callGoogleEvent("w_nav_gago_click");
                  window.open(
                    `http://d.gago.games/pk?utm_source=183&utm_medium=Mob_TopHead_Banner&utm_campaign=Cricwick&partner_id=2`,
                    `_blank`
                  );
                }}
              >
                <img
                  src={
                    "https://asset.cricvids.co/cricwick-assets/ads/Gago/web_gago_new_420_80.png"
                  }
                  alt=""
                />
              </div>

              <div
                className="card"
                onClick={() => {
                  this.callGoogleEvent("w_nav_mktv_click");
                  window.open(
                    `http://mktv.knectapi.com/?utm_source=203&utm_medium=Desktop_Nav_Banner_MKTV&partner_id=2`,
                    `_blank`
                  );
                }}
              >
                <img
                  src={
                    "https://asset.cricvids.co/cricwick-assets/ads/mktv/420x80.jpg"
                  }
                  alt=""
                />
              </div>
            </Carousel>
          </div>
        </div>
      </>
    );
  }
}

export default CricWickAds;
