import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { Globals } from "../constants";

import firebase from "firebase";
import { getCDNUrl } from "../HelperFunctions";

class SocialAuth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
    };

    this.xAccessToken = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).xAccessToken
      : "";
    this.msisdn = JSON.parse(localStorage.getItem("user")).phone;
    this.apiPath = Globals.SocialOnboardingAPI;

    this.openModal = this.openModal.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.twitterLogin = this.twitterLogin.bind(this);
    this.facebookLogin = this.facebookLogin.bind(this);

    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
  }

  openModal() {
    let modelState;
    if (this.state.showModal === false) {
      modelState = true;
    } else {
      modelState = false;
    }
    this.setState({ showModal: modelState });
  }

  newSocialApiUserManagement = async (tokenType, token) => {
    let resp = await fetch(
      `${Globals.UserManagment}api/v1/users/update-social-profile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          msisdn: this.msisdn,
          "x-access-token": this.xAccessToken,
          app: localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : ""
        },
        body: JSON.stringify({
          tokenType,
          token,
        }),
      }
    );
    let socialLoginResult = await resp.json();
    if (socialLoginResult && socialLoginResult.status === 1) {
      localStorage.setItem("isSocialProfileAttached", true);
      this.setState({ showModal: false });
    }
  };

  facebookLogin() {
    // let msisdn = this.msisdn;
    // let apiPath = this.apiPath;
    // let apiPathFantasy = this.apiPathFantasy;
    // let xAccessToken = this.xAccessToken;

    // let that = this;

    firebase
      .auth()
      .signInWithPopup(this.facebookProvider)
      .then((result) => {
        result.user.getIdToken().then((token) => {
          this.newSocialApiUserManagement(`firebase`, token);
        });

        // const requestObject = {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     phone: msisdn,
        //     name: user.displayName,
        //     profile_picture: user.photoURL,
        //     social_media_type: "facebook",
        //     email: user.email,
        //     web_user: 1,
        //   }),
        // };

        // fetch(apiPath + "main/onboarding_social_profile", requestObject);
        // fetch(`${apiPathFantasy}/api/v1/users/updateUserProfile`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     msisdn: msisdn,
        //     "x-access-token": xAccessToken,
        //   },
        //   body: JSON.stringify({
        //     name: user.displayName,
        //     profilePicture: user.photoURL,
        //   }),
        // });
      });
  }

  twitterLogin() {
    // let msisdn = this.msisdn;
    // let apiPath = this.apiPath;
    // let apiPathFantasy = this.apiPathFantasy;
    // let xAccessToken = this.xAccessToken;

    // let that = this;

    firebase
      .auth()
      .signInWithPopup(this.twitterProvider)
      .then((result) => {
        result.user.getIdToken().then((token) => {
          this.newSocialApiUserManagement(`firebase`, token);
        });

        // const requestObject = {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     phone: msisdn,
        //     name: user.displayName,
        //     profile_picture: user.photoURL,
        //     social_media_type: "twitter",
        //     email: user.email,
        //     web_user: 1,
        //   }),
        // };

        // fetch(apiPath + "main/onboarding_social_profile", requestObject);
        // fetch(apiPathFantasy + "/api/v1/users/updateUserProfile", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     msisdn: msisdn,
        //     "x-access-token": xAccessToken,
        //   },
        //   body: JSON.stringify({
        //     name: user.displayName,
        //     profilePicture: user.photoURL,
        //   }),
        // });
      });
  }

  googleLogin() {
    // let msisdn = this.msisdn;
    // let apiPath = this.apiPath;
    // let apiPathFantasy = this.apiPathFantasy;
    // let xAccessToken = this.xAccessToken;

    firebase
      .auth()
      .signInWithPopup(this.googleProvider)
      .then((result) => {
        result.user.getIdToken().then((token) => {
          this.newSocialApiUserManagement(`firebase`, token);
        });

        // this.newSocialApiUserManagement(`firebase`, result.credential.idToken);

        // const requestObject = {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     phone: msisdn,
        //     name: user.displayName,
        //     profile_picture: user.photoURL,
        //     social_media_type: "google",
        //     email: user.email,
        //     web_user: 1,
        //   }),
        // };
        // fetch(apiPath + "main/onboarding_social_profile", requestObject);
        // fetch(apiPathFantasy + "/api/v1/users/updateUserProfile", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     msisdn: msisdn,
        //     "x-access-token": xAccessToken,
        //   },
        //   body: JSON.stringify({
        //     name: user.displayName,
        //     profilePicture: user.photoURL,
        //   }),
        // });
      });
  }

  render() {
    const { showModal } = this.state;
    return (
      <Modal isOpen={showModal} toggle={this.openModal} backdrop={true}>
        <ModalHeader toggle={this.openModal} style={{ borderBottom: "0px" }} />
        <ModalBody className="modal-body-social">
          <div className="bg text-center">
            <img
              className="logo"
              src={getCDNUrl("/images/trivia/Group.jpg")}
              alt=""
              width="35%"
              style={{ marginTop: "10%" }}
            />
            <div style={{ marginTop: "8%" }}>
              <h1 className="head">One Step Sign Up,</h1>
              <h1 className="head">Keeping it Simple</h1>
            </div>
            <div className="pocket">
              <h1>Get all the awesomeness of Cricket</h1>
              <h1>in your Pocket, personalized the way</h1>
              <h1>You like it!</h1>
            </div>
            <div className="social_buttons">
              <div
                className="facebook"
                style={{ cursor: "pointer" }}
                onClick={this.facebookLogin}
              >
                <img
                  className="fb"
                  src={getCDNUrl("/images/trivia/Facebook.png")}
                  alt=""
                  width="55%"
                />
              </div>
              {/* <FacebookLogin
                    appId="609481209617653"
                    autoLoad={false}
                    callback={this.responseFromFacebook}
                    render={ renderProps => (
                      <div className="facebook" onClick={renderProps.onClick}><img className="fb" src="/images/trivia/Facebook.png" alt="" width="55%" /></div>
                    )}
                /> */}
              {/* <TwitterLogin
                  authCallback={this.responseFromTwitter}
                  consumerKey={"yFN5cIDjIosNUerjLW6IANpSs"}
                  consumerSecret={"vimezX9dkfCHKgAaqC0eQLNbyY2GU7xK9vh7e8PERpAo8TM96e"}
                  callbackUrl={"https://crickettelenorzong.firebaseapp.com/__/auth/handler"}
                  children={<div className="twitter"><img className="tw" src="/images/trivia/Twitter.png" alt="" width="55%" /></div>}
                /> */}
              <div
                className="twitter"
                style={{ cursor: "pointer" }}
                onClick={this.twitterLogin}
              >
                <img
                  className="tw"
                  src={getCDNUrl("/images/trivia/Twitter.png")}
                  alt=""
                  width="55%"
                />
              </div>

              <div
                className="gmail"
                style={{ cursor: "pointer" }}
                onClick={this.googleLogin}
              >
                <img
                  className="gm"
                  src={getCDNUrl("/images/trivia/Google.png")}
                  alt=""
                  width="55%"
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default SocialAuth;
