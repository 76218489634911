import axios from "axios";
import React, { Component } from "react";
import PackageCard from "../newLoginFlow/packageCard";
import TelcoPackageCard from "../newLoginFlow/telcoPackageCard";
import { Globals } from "../constants";
import Loading from "../Loading";
import { withRouter } from "react-router";
// import { G } from "../constants";
const queryString = require("query-string");

class SubscriptionPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      // apiPath: `${
      //   Globals.UserManagment
      // }api/v1/packages/get-all-packages?region=1`,
      // apiPath: `${Globals.NodeApi}api/v1/bundles/get_all_packages?region=1`,
      plans: null,
      plans2: null,
      plans3: null,
      expanded: false,
      fromInvite: false,
    };
  }

  subscriptionTypes = [
    "daily",
    "weekly",
    "monthly",
    "biweekly",
    "monthly",
    "months",
    "year",
  ];

  getPlansFromApi = async (pkg_type = 1, stateVar = "plans") => {
    let apiurl = `${
      Globals.NodeApi
    }api/get_packages?pkg_type=${pkg_type}&app_name=${
      localStorage.getItem("domain")
        ? localStorage.getItem("domain")
        : Globals.telco
          ? Globals.appName
          : Globals.appName
    }`;

    try {
      let response = await axios.get(apiurl);
      this.setState({
        [stateVar]: response.data.remote_response.data.packages,
        isLoading: false,
      });
    } catch (error) {
      console.log(`Cannot get plans: `, error);
    }
  };

  async componentDidMount() {
    this.getPlansFromApi(3, "plans3");
    let parsed = queryString.parse(this.props.location.search);
    if (parsed.fromInvite)
      this.setState({
        fromInvite: true,
      });
    // if (this.props.changeSubscription) {
    //   let user =
    //     localStorage.getItem("user") &&
    //     JSON.parse(localStorage.getItem("user"));

    //   this.setState({
    //     subscriptionType: user.subscriptionType,
    //     paymentType: user.paymentType,
    //   });
    // }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div>
            <div
              style={{
                textAlign: "center",
                // marginBottom: "20px",
                fontSize: "1.5rem",
                lineHeight: "1.2 !important",
                fontWeight: "700",
              }}
            >
              Subscription Plans
            </div>

            <div
              className="text-center"
              style={{ fontSize: "0.9rem", fontWeight: "600" }}
            >
              Awesome Bundles. Cancel anytime.
            </div>
            <div className="mt-4">
              {this.state.plans3 &&
                this.state.plans3.map((plan, index) => {
                  return (
                    <PackageCard
                      packageDetails={plan}
                      key={plan.title}
                      index={index}
                      onPlanSelection={this.props.onPlanSelection}
                    />
                  );
                })}
              <TelcoPackageCard
                onClick={this.props.onTelcoCardClick}
                telco={"mobilink"}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SubscriptionPlans);
