import React from "react";
import "../src/trivia/trivia.css";
import googleAd from "../src/images/ad.png";
import coin from "../src/images/coin.png";
import jccoin from "../src/images/jccoin.png";
import menaCoin from "../src/images/menaCoin.png";
import icon from "../src/images/icon.png";
import tick from "../src/images/view-claim-tick.png";
import copy from "../src/images/copy.png";
import { Modal, ModalBody } from "reactstrap";
import QRCode from "react-qr-code";
import { Form, FormGroup, Input, FormText, ModalFooter } from "reactstrap";
import InputMask from "react-input-mask";
import { Globals } from "../src/constants";
import TimerComponent from "../src/timer";
import editt from "../src/images/editt.png";
import productt from "../src/images/product.png";
import arrow from "../src/images/arrow.png";
import {
  kFormatter,
  formatAsDDMMYY,
  numberWithCommas,
} from "./HelperFunctions";
import axios from "axios";
class RedeemPrizeCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setModal: false,
      cardClaim: false,
      viewCouponClaim: false,
      viewVoucherClaim: false,
      confirmRedeemSignedBat: false,
      viewGameCard: false,
      confirmPubgCard: false,
      claimedVoucher: false,
      gameTitle: "",
      gameDescription: "",
      productTitle: "",
      productDescription: "",
      productImageUrl: null,
      products: [],
      availableCoins: 0,
      userItems: [],
      userItem: [],
      voucherCode: null,
      voucherImageUrl: null,
      cardProductId: null,
      voucherId: null,
      serverTime: 0,
      claimIn: null,
      nextClaimIn: null,
      viewCard: false,
      expiryDate: null,

      price: "",
      gamePrice: 0,
      productPrice: 0,
      name: "",
      phoneNumber: "",
      address: "",
      imageUrl: null,
      pubgId: null,
      pubgImageUrl: null,
      pubgName: "",
      pubgErrorMessage: "Please fill the pubg Id",
      nameErrorMessage: "Please fill the Name",
      numberErrorMessage: "Please fill the Number",
      addressErrorMessage: "Please fill the Address",
      errorMessage: "* Fill Out Required Fields",
      shouldFormBeDisabled: true,
      isLoading: true,
    };
  }
  componentDidMount() {
    this.setState({
      availableCoins: localStorage.getItem("coins"),
    });
    this.getProducts();
  }

  // Last redeem Item from list of UserItems
  getLasRedeemItem(productId, userItemsResponse, products) {
    let UserItems = [];
    userItemsResponse &&
      userItemsResponse.length > 0 &&
      userItemsResponse.forEach((userItem) => {
        if (productId === userItem.productId) {
          products["userItem"] = userItem;
          UserItems.push(userItem);
        }
      });
    return UserItems[UserItems.length - 1];
  }

  // Product Remaining Time in MilliSeconds
  getProductRemainingMS(productRedeemTime, redeemTime, serverTime) {
    let productRedeemTimeinMS = productRedeemTime * 1000;
    let redemptionTime = productRedeemTimeinMS + redeemTime;
    let remainingMS = redemptionTime - serverTime;

    return remainingMS;
  }

  //Get product remaining time in hours, minutes and seconds
  getProductRemainingTime(nextClaimMS, product) {
    var dif;
    var hh = Math.floor(nextClaimMS / 1000 / 60 / 60); //remaining hours
    dif -= hh * 1000 * 60 * 60;
    var mm = Math.floor(dif / 1000 / 60); //remaining minutes
    dif = mm * 1000 * 60;
    var ss = Math.floor(dif / 1000); //remaining seconds
    dif = ss * 1000;

    if (hh >= 0) {
      dif = [hh, mm, ss].join(":");
      product["nextClaim"] = nextClaimMS;
      this.setState({
        claimIn: hh,
      });
    } else {
      this.setState({
        claimIn: null,
      });
    }
  }

  // Get products from redemption's API
  getProducts() {
    let url = `${Globals.redemptionService}redemptions/products`;
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    axios
      .get(url, {
        headers: {
          userid: user ? user.id : "",
          app: localStorage.getItem("domain")
            ? "mena"
            : Globals.telco == "mobilink"
              ? "jazzcricket"
              : "cricwick",
        },
      })
      .then((response) => {
        if (response && response.data.response_code === 0) {
          for (let i = 0; i < response.data.data.products.length; i++) {
            let userItem = this.getLasRedeemItem(
              response.data.data.products[i].id,
              response.data.data.userItems,
              response.data.data.products[i]
            );
            let remainingSeconds = this.getProductRemainingMS(
              response.data.data.products[i].redemptionTime,
              userItem ? userItem.details.redemptionTimeInMS : 0,
              response.serverTime
            );
            this.getProductRemainingTime(
              remainingSeconds,
              response.data.data.products[i]
            );
            //response.data.products[i]["nextClaim"] = remainingSeconds;
          }
          for (let i = 0; i < response.data.data.products.length; i++) {
            if (response.data.data.products[i + 1]) {
              if (
                response.data.data.products[i].quantity == 0 &&
                response.data.data.products[i + 1].quantity == 0
              ) {
                if (
                  response.data.data.products[
                    response.data.data.products.length - 1
                  ].quantity > 0
                ) {
                  let product =
                    response.data.data.products[
                      response.data.data.products.length - 1
                    ];
                  response.data.data.products[
                    response.data.data.products.length - 1
                  ] = response.data.data.products[i];
                  response.data.data.products[i] = product;
                } else {
                  let product =
                    response.data.data.products[
                      response.data.data.products.length - 2
                    ];
                  response.data.data.products[
                    response.data.data.products.length - 2
                  ] = response.data.data.products[i];
                  response.data.data.products[i] = product;
                }
              } else if (
                response.data.data.products[i].quantity <
                response.data.data.products[i + 1].quantity
              ) {
                let product = response.data.data.products[i + 1];
                response.data.data.products[i + 1] =
                  response.data.data.products[i];
                response.data.data.products[i] = product;
              }
            }
          }
          this.setState({
            products: response.data.data.products,
            userItems: response.data.data.userItems,
          });
        }
      });
  }

  //Product Remaining time counter
  getRemainingTime = (time) => {
    return <TimerComponent timeRemaining={time} />;
  };

  // Redeem Product
  redeemProduct(productId, name = "", id = "", address = "") {
    this.setState({
      shouldFormBeDisabled: true,
    });
    const url = `${Globals.redemptionService}userRedemptions`;
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    let requestBody = {};
    if (name != "" && id != "" && address != "") {
      //For Product redemption
      requestBody = {
        name: name,
        phonenumber: Number(id),
        address: address,
      };
    } else if (name != "" && id != "" && address === "") {
      //For Game redemption
      requestBody = {
        pubgplayerid: id,
        pubgname: name,
      };
    }
    axios
      .post(url, requestBody, {
        headers: {
          userId: user.id,
          productid: productId,
          app: localStorage.getItem("domain")
            ? "mena"
            : Globals.telco == "mobilink"
              ? "jazzcricket"
              : "cricwick",
        },
      })
      .then((response) => {
        if (response && response.data.response_code === 0) {
          console.log("redeem", response.data.data.userItem);
          this.setState({
            userItem: response.data.data ? response.data.data.userItem : null,
            serverTime: response.data.serverTime,
            availableCoins:
              response.data.data && response.data.data.wallet
                ? response.data.data.wallet.availableCoins
                : 0,
          });
          console.log(
            "redeem",
            response.data.data.userItem.details.expiryDate,
            response.data.data.userItem.details.voucherCode
          );
          localStorage.removeItem("coins");
          localStorage.setItem(
            "coins",
            response.data.data && response.data.data.wallet
              ? response.data.data.wallet.availableCoins
              : 0
          );
          if (
            (name != "" && id != "" && address != "") ||
            (name != "" && id != "")
          ) {
            window.location.reload();
          }
          //window.location.reload();
        } else {
          //window.location.reload();
        }
      });
  }

  validateForm() {
    if (
      this.state.name &&
      this.state.name.length > 0 &&
      (this.state.phoneNumber && this.state.phoneNumber.length < 11) &&
      (this.state.address && this.state.address.length > 0)
    ) {
      this.setState({ shouldFormBeDisabled: false });
    } else if (
      this.state.pubgId &&
      this.state.pubgId != null &&
      (this.state.pubgName && this.state.pubgName.length > 0)
    ) {
      this.setState({ shouldFormBeDisabled: false });
    } else {
      this.setState({
        shouldFormBeDisabled: true,
        errorMessage: "* Required Fields",
      });
    }
  }

  //Open Modal
  openPopup() {
    this.setState({
      setModal: true,
    });
  }

  //open voucher popup
  openCardClaim(productId, price) {
    this.setState({
      setModal: true,
      cardClaim: true,
      price: price.price,
      viewCouponClaim: false,
      viewVoucherClaim: false,
      confirmRedeemSignedBat: false,
      voucherId: productId,
      voucherImageUrl: price.imageUrl,
      viewGameCard: false,
    });
    this.redeemProduct(productId);
  }

  //Open Voucher details
  openViewCouponClaim(voucherId, price, claim, voucherImageUrl) {
    console.log(
      "voucher",
      this.state.expiryDate,
      this.state.voucherCode,
      this.state.userItem
    );

    if (claim == true) {
      if (this.state.userItems.length > 0) {
        for (let i = 0; i < this.state.userItems.length; i++) {
          if (this.state.userItems[i].productId === voucherId) {
            this.setState({
              voucherCode: this.state.userItems[i].details.voucherCode,
              expiryDate: this.state.userItems[i].details.expiryDate,
              voucherImageUrl: this.state.userItems[i].details.imageUrl,
              viewCard: claim,
            });
          }
        }
      }
    }

    this.setState({
      setModal: true,
      viewCouponClaim: true,
      claimedVoucher: claim,
      cardClaim: false,
      viewVoucherClaim: false,
      voucherId: voucherId,
      price: price.price,
      confirmRedeemSignedBat: false,
      viewGameCard: false,
      voucherImageUrl: price.imageUrl ? price.imageUrl : voucherImageUrl,
    });
    //window.location.reload()
  }

  //verify product data card
  openConfirmRedeemSignedBat(
    productId,
    cardClaim,
    product,
    productDescription,
    productImageUrl
  ) {
    if (cardClaim == true) {
      if (this.state.userItems.length > 0) {
        for (let i = 0; i < this.state.userItems.length; i++) {
          if (this.state.userItems[i].productId === productId) {
            this.setState({
              name: this.state.userItems[i].details.userDetails.name,
              phoneNumber: this.state.userItems[i].details.userDetails
                .phonenumber,
              address: this.state.userItems[i].details.userDetails.address,
              imageUrl: this.state.userItems[i].details.userDetails.imageUrl,
              viewCard: cardClaim,
            });
          }
        }
      }
    }
    this.setState({
      setModal: true,
      viewCouponClaim: false,
      cardClaim: false,
      viewCard: cardClaim,
      viewVoucherClaim: false,
      confirmRedeemSignedBat: true,
      productPrice: product.price ? product.price : product,
      productDescription: product.description
        ? product.description
        : productDescription,
      imageUrl: product.imageUrl ? product.imageUrl : productImageUrl,
      viewGameCard: false,
    });
    // } else {
    //   this.validateForm();
    //   this.setState({
    //     viewVoucherClaim: true,
    //   });
  }

  //verify game data card
  openConfirmPubg(
    productId,
    cardClaim,
    product,
    gameDescription,
    gameImageUrl
  ) {
    if (cardClaim == true) {
      if (this.state.userItems.length > 0) {
        for (let i = 0; i < this.state.userItems.length; i++) {
          if (this.state.userItems[i].productId === productId) {
            this.setState({
              pubgName: this.state.userItems[i].details.userDetails.pubgname,
              pubgId: this.state.userItems[i].details.userDetails.pubgplayerid,
              pubgImageUrl: this.state.userItems[i].details.userDetails
                .imageUrl,
              viewCard: cardClaim,
            });
          }
        }
      }
    }
    this.setState({
      setModal: true,
      viewCouponClaim: false,
      cardClaim: false,
      viewVoucherClaim: false,
      confirmRedeemSignedBat: false,
      viewGameCard: false,
      confirmPubgCard: true,
      gamePrice: product.price ? product.price : product,
      gameTitle: product.title ? product.title : "Game",
      pubgImageUrl: product.imageUrl ? product.imageUrl : gameImageUrl,
      gameDescription: product.description
        ? product.description
        : gameDescription,
    });
    // } else {
    //   this.validateForm();
    //   this.setState({
    //     viewGameCard: true,
    //   });
    // }
  }

  //to Get product data
  openViewVoucherClaim(productId, price) {
    this.setState({
      setModal: true,
      viewCouponClaim: false,
      cardClaim: false,
      viewVoucherClaim: true,
      productTitle: price.title ? price.title : "",
      productDescription: price.description ? price.description : "",
      imageUrl: price.imageUrl ? price.imageUrl : "",
      confirmRedeemSignedBat: false,
      cardProductId: productId,
      productPrice: price.price ? price.price : price,
      viewGameCard: false,
      viewCard: false,
    });
  }

  //to get game data
  openViewGameCardClaim(productId, price) {
    this.setState({
      setModal: true,
      viewCouponClaim: false,
      cardClaim: false,
      viewVoucherClaim: false,
      confirmRedeemSignedBat: false,
      cardProductId: productId,
      gamePrice: price.price ? price.price : price,
      pubgImageUrl: price.imageUrl ? price.imageUrl : "",
      viewGameCard: true,
      gameTitle: price.title ? price.title : "",
      gameDescription: price.description ? price.description : "",
      viewCard: false,
    });
  }

  closeModal() {
    this.setState({
      setModal: false,
    });
  }

  handleInputChange(name, value) {
    this.setState({ [name]: value }, () => {
      this.validateForm();
    });
  }

  render() {
    return (
      <>
        {this.state.setModal && (
          <Modal
            isOpen={this.state.setModal}
            className="d-flex justify-content-center"
            style={{ maxWidth: "414px" }}
          >
            {/* Voucher card */}
            {this.state.cardClaim ? (
              <ModalBody className="modal-body p-0">
                <div className="col text-center justify-content-center p-0">
                  <div className="pb-1">
                    <img
                      src={this.state.voucherImageUrl}
                      alt=""
                      maxWidth={"414px"}
                      width={"100%"}
                      height={"83px"}
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                    <h4 className="font-weight-bold pt-4 mb-0">
                      REDEEM VOUCHER
                    </h4>
                  </div>
                  <div className="col pb-3">
                    <h5 className="red mt-2 pb-4">
                      Pkr.{" "}
                      <b>{this.state.price ? this.state.price / 10000 : 0}</b>
                    </h5>
                    <p className="mb-2">Voucher Code</p>
                    <h3 className="font-weight-bold">*******</h3>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ flexDirection: "column" }}
                    >
                      <button
                        className="btn bg-red text-light pr-5 pl-5 mb-4  rounded-pill text-center"
                        style={{ borderRadius: "79px" }}
                        onClick={() =>
                          this.openViewCouponClaim(
                            this.state.voucherId,
                            this.state.price,
                            false,
                            this.state.voucherImageUrl
                          )
                        }
                      >
                        Claim with {this.state.price} coins
                      </button>
                      <span className="d-flex">
                        <img
                          className="ml-3 mr-2 mt-1"
                          src={icon}
                          alt=""
                          width={"10px"}
                          height={"11px"}
                        />
                        <p className="red" onClick={() => this.closeModal()}>
                          Voucher codes will be sent on your number
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </ModalBody>
            ) : this.state.viewCouponClaim ? (
              <>
                {/* Voucher claimed */}
                <ModalBody className="modal-body p-0">
                  <div className="col text-center justify-content-center p-0">
                    <div className="pb-1">
                      <img
                        src={this.state.voucherImageUrl}
                        alt=""
                        maxWidth={"414px"}
                        width={"100%"}
                        height={"83px"}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                      <h4 className="font-weight-bold pt-4 mb-0">VOUCHER</h4>
                    </div>
                    <div className="col pb-3">
                      <h5 className="red mt-2 pb-4">
                        Pkr.{" "}
                        <b>{this.state.price ? this.state.price / 10000 : 0}</b>
                      </h5>
                      <QRCode
                        value={
                          this.state.userItem &&
                          this.state.userItem.details &&
                          this.state.userItem.details.mainType == "voucher"
                            ? this.state.userItem.details
                              ? this.state.userItem.details.voucherCode
                              : ""
                            : this.state.voucherCode
                              ? this.state.voucherCode
                              : ""
                        }
                        style={{ height: "136px", width: "136px" }}
                        size={136}
                        viewBox={`0 0 136 136`}
                      />
                      <p className="mb-2 mt-4">Voucher Code</p>
                      <h2 className="font-weight-bold">
                        {" "}
                        {this.state.userItem &&
                        this.state.userItem.details &&
                        this.state.userItem.details.mainType == "voucher"
                          ? this.state.userItem.details
                            ? this.state.userItem.details.voucherCode
                            : ""
                          : this.state.voucherCode
                            ? this.state.voucherCode
                            : ""}
                        <img
                          src={copy}
                          height={"18px"}
                          width={"18px"}
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              this.state.user && this.state.userItem.details
                                ? this.state.userItem.details.voucherCode
                                : this.state.voucherCode
                                  ? this.state.voucherCode
                                  : ""
                            );
                          }}
                        />
                      </h2>
                      <p className="red">
                        Expires on{" "}
                        {this.state.userItem &&
                        this.state.userItem.details &&
                        this.state.userItem.details.mainType == "voucher"
                          ? this.state.userItem.details
                            ? formatAsDDMMYY(
                                this.state.userItem.details.expiryDate
                              )
                            : null
                          : this.state.expiryDate
                            ? formatAsDDMMYY(this.state.expiryDate)
                            : ""}
                      </p>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ flexDirection: "column" }}
                      >
                        <button
                          className="btn bg-green pr-3 pl-3 mb-4  rounded-pill text-center"
                          style={{ borderRadius: "79px", color: "#003229" }}
                          onClick={() =>
                            this.state.claimedVoucher == true
                              ? this.closeModal()
                              : window.location.reload()
                          }
                        >
                          <img
                            className="mr-2"
                            src={tick}
                            height={"10px"}
                            width={"10px"}
                          />
                          Voucher Redeemed
                        </button>
                        <span className="d-flex">
                          <img
                            className="ml-3 mr-2 mt-1"
                            src={icon}
                            alt=""
                            width={"10px"}
                            height={"11px"}
                          />
                          <p
                            className="red"
                            onClick={() =>
                              this.state.claimedVoucher == true
                                ? this.closeModal()
                                : window.location.reload()
                            }
                          >
                            Voucher code sent on your number
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </>
            ) : this.state.viewVoucherClaim ? (
              <>
                {/* Product claim form */}
                <ModalBody className="modal-body p-0">
                  <div className="col text-center justify-content-center p-0">
                    <div className="pb-1">
                      <img
                        src={this.state.imageUrl}
                        alt=""
                        maxWidth={"414px"}
                        width={"100%"}
                        height={"83px"}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                      <h4 className="font-weight-bold text-uppercase pt-4 mb-0">
                        REDEEM {this.state.productTitle}
                      </h4>
                    </div>
                    <div className="col pb-3">
                      <h2 className="red mt-2 pb-4 text-uppercase font-weight-bold">
                        {this.state.productDescription}
                      </h2>

                      <Form className="p-5 pb-5 px-lg-5 py-lg-4">
                        <FormGroup>
                          <div
                            className="my-2 text-center font-weight-bold"
                            style={{ fontSize: "16px" }}
                          >
                            your Name
                          </div>
                          <Input
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "24px",
                              background: "#ffffff",
                            }}
                            className="payout-input text-center"
                            type="text"
                            value={this.state.name}
                            name="name"
                            id="name"
                            placeholder="Enter your name"
                            onChange={(e) =>
                              this.handleInputChange("name", e.target.value)
                            }
                          />
                          {this.state.name &&
                            this.state.name.length === 0 && (
                              <FormText color="#b91414">
                                {this.state.nameErrorMessage}
                              </FormText>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <div
                            className="my-2 font-weight-bold"
                            style={{ fontSize: "16px" }}
                          >
                            Phone Number
                          </div>
                          <Input
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "24px",
                              background: "#ffffff",
                            }}
                            className="payout-input text-center"
                            value={this.state.phoneNumber}
                            name="phoneNumber"
                            id="phoneNumber"
                            type="text"
                            placeholder="Enter your Phone Number"
                            onChange={(e) =>
                              this.handleInputChange(
                                "phoneNumber",
                                e.target.value
                              )
                            }
                          />
                          {this.state.phoneNumber &&
                            this.state.phoneNumber.length === 0 && (
                              <FormText color="#b91414">
                                {this.state.numberErrorMessage}
                              </FormText>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <div
                            className="my-2 font-weight-bold"
                            style={{ fontSize: "16px" }}
                          >
                            Your Address
                          </div>
                          <br />
                          <Input
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "24px",
                              background: "#ffffff",
                            }}
                            type="text"
                            name="address"
                            value={this.state.address}
                            placeholder="Enter your Address"
                            onChange={(e) =>
                              this.handleInputChange("address", e.target.value)
                            }
                            className="payout-input text-center"
                            id="address"
                          />
                          {this.state.address &&
                            this.state.address.length === 0 && (
                              <FormText color="#b91414">
                                {this.state.addressErrorMessage}
                              </FormText>
                            )}
                        </FormGroup>

                        <br />
                        <button
                          type="button"
                          //disabled={this.state.shouldFormBeDisabled}
                          className="bg-red b my-2 text-light pr-4 pl-4 border-0 font-weight-bold p-3 mb-4"
                          style={{ borderRadius: "79px", fontSize: "14px" }}
                          onClick={() =>
                            this.state.name.length === 0 ||
                            this.state.phoneNumber.length === 0 ||
                            this.state.address.length === 0
                              ? this.state.errorMessage
                              : this.openConfirmRedeemSignedBat(
                                  this.state.cardProductId,
                                  false,
                                  this.state.productPrice,
                                  this.state.productDescription,
                                  this.state.imageUrl
                                )
                          }
                        >
                          Claim with {this.state.productPrice} coins
                        </button>
                        {(this.state.name.length === 0 ||
                          this.state.phoneNumber.length === 0 ||
                          this.state.address.length === 0) &&
                        this.state.shouldFormBeDisabled ? (
                          <FormText
                            color="red font-weight-bold mb-3"
                            style={{ fontSize: "16px" }}
                          >
                            {this.state.errorMessage}
                          </FormText>
                        ) : (
                          ""
                        )}
                        <span className="d-flex">
                          <img
                            className="ml-3 mr-2 mt-1"
                            src={icon}
                            alt=""
                            width={"10px"}
                            height={"11px"}
                          />
                          <p className="red" onClick={() => this.closeModal()}>
                            Redemption codes will be sent on your number
                          </p>
                        </span>
                      </Form>
                    </div>
                  </div>
                </ModalBody>
              </>
            ) : this.state.viewGameCard ? (
              <>
                {/* Game claim Form */}
                <ModalBody className="modal-body p-0">
                  <div className="col text-center justify-content-center p-0">
                    <div className="pb-1">
                      <img
                        src={this.state.pubgImageUrl}
                        alt=""
                        maxWidth={"414px"}
                        width={"100%"}
                        height={"83px"}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                      <h4 className="font-weight-bold text-uppercase pt-4 mb-0">
                        Redeem {this.state.gameTitle}
                      </h4>
                    </div>
                    <div className="col pb-3">
                      <div className="row align-items-center justify-content-center">
                        {/* {Globals.telco == "mobilink" ? (
                          <img src={jccoin} alt="pubg" />
                        ): (
                          <img src={coin} alt="pubg" />
                        )} */}
                        <h2 className="red ml-1 mt-2 text-uppercase font-weight-bold">
                          {this.state.gameDescription}
                        </h2>
                      </div>

                      <Form className="p-5 pb-5 px-lg-5 py-lg-4">
                        <FormGroup>
                          <div
                            className="my-2 font-weight-bold"
                            style={{ fontSize: "16px" }}
                          >
                            {this.state.gameTitle} Player ID
                          </div>
                          <Input
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "24px",
                              background: "#ffffff",
                            }}
                            className="payout-input text-center"
                            value={this.state.pubgId}
                            name="pubgId"
                            id="pubgId"
                            type="text"
                            placeholder="Enter your PUBG Player ID"
                            onChange={(e) =>
                              this.handleInputChange("pubgId", e.target.value)
                            }
                          />
                          {this.state.pubgId &&
                            this.state.pubgId.length === 0 && (
                              <FormText color="#b91414">
                                {this.state.pubgErrorMessage}
                              </FormText>
                            )}
                        </FormGroup>
                        <FormGroup>
                          <div
                            className="my-2 text-center font-weight-bold"
                            style={{ fontSize: "16px" }}
                          >
                            Your {this.state.gameTitle} Name
                          </div>
                          <Input
                            style={{
                              border: "1px solid #000000",
                              borderRadius: "24px",
                              background: "#ffffff",
                            }}
                            className="payout-input text-center"
                            type="text"
                            value={this.state.pubgName}
                            name="pubgName"
                            id="pubgName"
                            placeholder="Enter your name"
                            onChange={(e) =>
                              this.handleInputChange("pubgName", e.target.value)
                            }
                          />
                          {this.state.pubgName &&
                            this.state.pubgName.length === 0 && (
                              <FormText color="#b91414">
                                {this.state.nameErrorMessage}
                              </FormText>
                            )}
                        </FormGroup>
                        <br />
                        <button
                          type="button"
                          //disabled={this.state.shouldFormBeDisabled}
                          className="bg-red b my-2 text-light pr-4 pl-4 border-0 font-weight-bold p-3 mb-4"
                          style={{ borderRadius: "79px", fontSize: "14px" }}
                          onClick={() =>
                            this.state.pubgId === null ||
                            this.state.pubgName.length === 0
                              ? this.state.errorMessage
                              : this.openConfirmPubg(
                                  this.state.cardProductId,
                                  false,
                                  this.state.gamePrice,
                                  this.state.gameDescription,
                                  this.state.pubgImageUrl
                                )
                          }
                        >
                          Claim with {this.state.gamePrice} coins
                        </button>
                        {(this.state.pubgName.length === 0 ||
                          this.state.pubgId === null) &&
                        this.state.shouldFormBeDisabled ? (
                          <FormText
                            color="red font-weight-bold mb-3"
                            style={{ fontSize: "16px" }}
                          >
                            {this.state.errorMessage}
                          </FormText>
                        ) : (
                          ""
                        )}
                        <span className="d-flex">
                          <img
                            className="ml-3 mr-2 mt-1"
                            src={icon}
                            alt=""
                            width={"10px"}
                            height={"11px"}
                          />
                          <p className="red" onClick={() => this.closeModal()}>
                            You will receive your {this.state.gameDescription}{" "}
                            in {this.state.gameTitle} with in a week.
                          </p>
                        </span>
                      </Form>
                    </div>
                  </div>
                </ModalBody>
              </>
            ) : this.state.confirmRedeemSignedBat ? (
              <>
                {/* Product claimed form for verification */}
                <ModalBody className="modal-body p-0">
                  <div className="col text-center justify-content-center p-0">
                    <div className="pb-1">
                      <img
                        src={this.state.imageUrl}
                        alt=""
                        maxWidth={"414px"}
                        width={"100%"}
                        height={"83px"}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                      <h4 className="font-weight-bold text-uppercase red pt-4 mb-0">
                        {this.state.productDescription}
                      </h4>
                    </div>
                    <div
                      className="d-flex p-4 justify-content-between"
                      style={{ flexDirection: "row" }}
                    >
                      <h2 className="w-40" style={{ textAlign: "start" }}>
                        Name
                      </h2>
                      <h2 className="w-60" style={{ textAlign: "start" }}>
                        {this.state.name}
                        {!this.state.viewCard ? (
                          <img
                            src={editt}
                            alt=""
                            width={"15px"}
                            height={"16px"}
                            style={{ marginLeft: "3px" }}
                            onClick={() =>
                              this.openViewVoucherClaim(
                                this.state.cardProductId,
                                this.state.productPrice
                              )
                            }
                          />
                        ) : null}
                      </h2>
                    </div>

                    <div
                      className="d-flex p-4 justify-content-between"
                      style={{ flexDirection: "row" }}
                    >
                      <h2 className="w-40" style={{ textAlign: "start" }}>
                        Number
                      </h2>
                      <h2 className="w-60" style={{ textAlign: "start" }}>
                        {this.state.phoneNumber}
                        {!this.state.viewCard ? (
                          <img
                            src={editt}
                            alt=""
                            width={"15px"}
                            height={"16px"}
                            style={{ marginLeft: "3px" }}
                            onClick={() =>
                              this.openViewVoucherClaim(
                                this.state.cardProductId,
                                this.state.productPrice
                              )
                            }
                          />
                        ) : null}
                      </h2>
                    </div>

                    <div
                      className="d-flex p-4 justify-content-between"
                      style={{ flexDirection: "row" }}
                    >
                      <h2 className="w-40" style={{ textAlign: "start" }}>
                        Address
                      </h2>
                      <h2 className="w-60" style={{ textAlign: "start" }}>
                        {this.state.address}
                        {!this.state.viewCard ? (
                          <img
                            src={editt}
                            alt=""
                            width={"15px"}
                            height={"16px"}
                            style={{ marginLeft: "3px" }}
                            onClick={() =>
                              this.openViewVoucherClaim(
                                this.state.cardProductId,
                                this.state.productPrice
                              )
                            }
                          />
                        ) : null}
                      </h2>
                    </div>
                    <p
                      className="p-4 ml-4 mr-4"
                      style={{ background: "rgba(255, 153, 102, 0.15)" }}
                    >
                      <p
                        className="mb-0 font-weight-bold"
                        style={{ color: "#E86726" }}
                        onClick={() => this.closeModal()}
                      >
                        You will receive your prize with in a week.
                      </p>
                    </p>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ flexDirection: "column" }}
                    >
                      {!this.state.viewCard ? (
                        <button
                          className="bg-red b my-2 text-light pr-4 pl-4 border-0 font-weight-bold p-3 mb-4"
                          style={{
                            borderRadius: "79px",
                            fontSize: "14px",
                            width: "198px",
                          }}
                          onClick={() =>
                            this.redeemProduct(
                              this.state.cardProductId,
                              this.state.name,
                              this.state.phoneNumber,
                              this.state.address
                            )
                          }
                        >
                          Confirm
                        </button>
                      ) : null}
                    </div>
                  </div>
                </ModalBody>
              </>
            ) : this.state.confirmPubgCard ? (
              <>
                {/* Game claimed form for verification */}
                <ModalBody className="modal-body p-0">
                  <div className="col text-center justify-content-center p-0">
                    <div className="pb-1">
                      <img
                        src={this.state.pubgImageUrl}
                        alt=""
                        maxWidth={"414px"}
                        width={"100%"}
                        height={"83px"}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      />
                      <div className="row align-items-center justify-content-center mt-5">
                        {/* {Globals.telco == "mobilink" ? (
                          <img src={jccoin} alt="" />
                        ): (
                          <img src={coin} alt="" />
                        )} */}
                        <h3 className="font-weight-bold red ml-1 mb-0 text-uppercase">
                          {this.state.gameDescription}
                        </h3>
                      </div>
                    </div>
                    <div
                      className="d-flex p-4 justify-content-between"
                      style={{ flexDirection: "row" }}
                    >
                      <h2 className="w-40 black" style={{ textAlign: "start" }}>
                        Player ID
                      </h2>
                      <h2 className="w-60 black" style={{ textAlign: "start" }}>
                        {this.state.pubgId}
                        {!this.state.viewCard ? (
                          <img
                            src={editt}
                            alt=""
                            width={"15px"}
                            height={"16px"}
                            style={{ marginLeft: "3px" }}
                            onClick={() =>
                              this.openViewGameCardClaim(
                                this.state.cardProductId,
                                this.state.gamePrice
                              )
                            }
                          />
                        ) : null}
                      </h2>
                    </div>
                    <div
                      className="d-flex p-4 justify-content-between"
                      style={{ flexDirection: "row" }}
                    >
                      <h2 className="w-40 black" style={{ textAlign: "start" }}>
                        Name
                      </h2>
                      <h2 className="w-60 black" style={{ textAlign: "start" }}>
                        {this.state.pubgName}
                        {!this.state.viewCard ? (
                          <img
                            src={editt}
                            alt=""
                            width={"15px"}
                            height={"16px"}
                            style={{ marginLeft: "3px" }}
                            onClick={() =>
                              this.openViewGameCardClaim(
                                this.state.cardProductId,
                                this.state.gamePrice
                              )
                            }
                          />
                        ) : null}
                      </h2>
                    </div>

                    <p
                      className="p-4 ml-4 mr-4"
                      style={{ background: "rgba(255, 153, 102, 0.15)" }}
                    >
                      <p
                        className="mb-0 font-weight-bold"
                        style={{ color: "#E86726" }}
                        onClick={() => this.closeModal()}
                      >
                        You will receive your {this.state.gameDescription} in{" "}
                        {this.state.gameTitle ? this.state.gameTitle : "Game"}{" "}
                        with in a week.
                      </p>
                    </p>
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ flexDirection: "column" }}
                    >
                      {!this.state.viewCard ? (
                        <button
                          className="bg-red b my-2 text-light pr-4 pl-4 border-0 font-weight-bold p-3 mb-4"
                          style={{
                            borderRadius: "79px",
                            fontSize: "14px",
                            width: "198px",
                          }}
                          onClick={() =>
                            this.redeemProduct(
                              this.state.cardProductId,
                              this.state.pubgName,
                              this.state.pubgId
                            )
                          }
                        >
                          Confirm
                        </button>
                      ) : null}
                    </div>
                  </div>
                </ModalBody>
              </>
            ) : null}
          </Modal>
        )}

        {/* Redemption Cards */}
        <section
          id="main-section"
          className="px-lg-0 jc-inner-page align-self-start"
        >
          <div
            className={`d-flex pl-2 w-15 bg-red pt-3 pb-3 mt-3 text-light font-weight-bold`}
          >
            <div className={`pr-2`}>
              <img
                alt=""
                src={arrow}
                width={17}
                height={16}
                onClick={() => (window.location.href = "/wallet")}
              />
            </div>
            <p
              className="d-flex justify-content-center align-items-center p-0 w-100 m-0"
              style={{ fontSize: "15px" }}
            >
              Redeem Prizes
            </p>

            <div
              className="d-flex justify-content-between align-items-center mr-2"
              style={{
                backgroundColor: `#FFB300`,
                borderRadius: `20px`,
                color: `black`,
                minWidth: `73px`,
              }}
              onClick={this.openModal}
            >
              {Globals.telco == "mobilink" ? (
                <img
                  style={{ width: "20px", height: "20px", marginLeft: "1px" }}
                  src={jccoin}
                />
              ) : Globals.telco == "zain" ||
              localStorage.getItem("domain") == "CricwickMena" ? (
                <img
                  style={{ width: "20px", height: "20px", marginLeft: "1px" }}
                  src={menaCoin}
                />
              ) : (
                <img
                  style={{ width: "20px", height: "20px", marginLeft: "1px" }}
                  src={coin}
                />
              )}
              <p
                className="text-light"
                style={{ margin: "3px", paddingRight: "3px" }}
              >
                {this.state.availableCoins
                  ? kFormatter(this.state.availableCoins)
                  : 0}
              </p>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center py-3 "
            style={{ flexDirection: "column" }}
          >
            {this.state.products && this.state.products.length > 0
              ? this.state.products.map((product) => {
                  return (
                    <div
                      className="d-flex card margin-auto mb-3"
                      style={{
                        maxWidth: "390px",
                        width: "100%",
                        height: "195px",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={product.imageUrl}
                        alt=""
                        height={"91px"}
                        width={"100%"}
                        maxWidth={"390px"}
                      />
                      <span
                        className="text-center"
                        style={{
                          width: "80px",
                          height: "20px",
                          position: "absolute",
                          color: "#ffffff",
                          background:
                            "transparent linear-gradient(90deg, #00FFB3 0%, #6A0DD5 100%) 0% 0% no-repeat padding-box",
                        }}
                      >
                        {product.mainType}
                      </span>
                      <div
                        className="d-flex justify-content-between align-items-center p-3 pb-2"
                        style={{ flexDirection: "row" }}
                      >
                        {product.nextClaim ? (
                          // <div className="w-100 d-flex justify-content-between">
                          <>
                            <div className="w-50 d-flex justify-content-between">
                              <div
                                className="d-flex"
                                style={{ flexDirection: "column" }}
                              >
                                <p className="mb-2">Quantity</p>
                                <p className="font-weight-bold mb-0">
                                  {product.quantity}
                                </p>
                              </div>
                              <div
                                className="d-flex"
                                style={{ flexDirection: "column" }}
                              >
                                {product.mainType === "product" ||
                                product.mainType === "game" ? (
                                  <p className="mb-2">Price/Gift Card</p>
                                ) : (
                                  <p className="mb-2">Price/Voucher</p>
                                )}

                                <span className="d-flex">
                                  {Globals.telco == "mobilink" ? (
                                    <img
                                      src={jccoin}
                                      alt=""
                                      height={"14px"}
                                      width={"14px"}
                                    />
                                  ) : Globals.telco == "zain" ||
                                  localStorage.getItem("domain") ==
                                    "CricwickMena" ? (
                                    <img
                                      src={menaCoin}
                                      alt=""
                                      height={"14px"}
                                      width={"14px"}
                                    />
                                  ) : (
                                    <img
                                      src={coin}
                                      alt=""
                                      height={"14px"}
                                      width={"14px"}
                                    />
                                  )}
                                  <p className="font-weight-bold mb-0 ml-1">
                                    {numberWithCommas(product.price)}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div
                              className="d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              {product.nextClaim &&
                              (product.quantity > 0 ||
                                this.state.availableCoins > product.price) ? (
                                <p className="mb-2">Next Claim in</p>
                              ) : null}
                              {product.nextClaim &&
                              (product.quantity > 0 ||
                                this.state.availableCoins > product.price) ? (
                                <p className="font-weight-bold mb-0 redCl">
                                  {this.getRemainingTime(product.nextClaim)}
                                </p>
                              ) : product.nextClaim &&
                              (product.quantity <= 0 ||
                                this.state.availableCoins < product.price) ? (
                                <button
                                  className="bg-red b my-2 text-light pr-4 pl-4 border-0"
                                  style={{
                                    borderRadius: "79px",
                                    height: "32px",
                                    opacity: "0.18",
                                  }}
                                >
                                  Redeem
                                </button>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          // {/* </div> */}
                          <div className="w-50 d-flex justify-content-between">
                            <div
                              className="d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              <p className="mb-2">Quantity</p>
                              <p className="font-weight-bold mb-0">
                                {product.quantity}
                              </p>
                            </div>
                            <div
                              className="d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              {product.mainType === "product" ||
                              product.mainType === "game" ? (
                                <p className="mb-2">Price/Gift Card</p>
                              ) : (
                                <p className="mb-2">Price/Voucher</p>
                              )}

                              <span className="d-flex">
                                {Globals.telco == "mobilink" ? (
                                  <img
                                    src={jccoin}
                                    alt=""
                                    height={"14px"}
                                    width={"14px"}
                                  />
                                ) : Globals.telco == "zain" ||
                                localStorage.getItem("domain") ==
                                  "CricwickMena" ? (
                                  <img
                                    src={menaCoin}
                                    alt=""
                                    height={"14px"}
                                    width={"14px"}
                                  />
                                ) : (
                                  <img
                                    src={coin}
                                    alt=""
                                    height={"14px"}
                                    width={"14px"}
                                  />
                                )}
                                <p className="font-weight-bold ml-1 mb-0">
                                  {numberWithCommas(product.price)}
                                </p>
                              </span>
                            </div>
                            <div
                              className="d-none"
                              style={{ flexDirection: "column" }}
                            >
                              {product.nextClaim ? (
                                <p className="mb-2">Next Claim in</p>
                              ) : null}
                              {product.nextClaim ? (
                                <p className="font-weight-bold mb-0 redCl">
                                  {product.nextClaim}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        )}

                        {(this.state.availableCoins < product.price ||
                          product.quantity === 0) &&
                        !product.nextClaim ? (
                          <button
                            className="bg-red b my-2 text-light pr-4 pl-4 border-0"
                            style={{
                              borderRadius: "79px",
                              height: "32px",
                              opacity: "0.18",
                            }}
                          >
                            Redeem
                          </button>
                        ) : (this.state.availableCoins >= product.price ||
                          product.quantity !== 0) &&
                        product.mainType === "voucher" &&
                        !product.nextClaim ? (
                          <button
                            className="bg-red b my-2 text-light pr-4 pl-4 border-0"
                            style={{ borderRadius: "79px", height: "32px" }}
                            onClick={() =>
                              this.openCardClaim(product.id, product)
                            }
                          >
                            Redeem
                          </button>
                        ) : (this.state.availableCoins >= product.price ||
                          product.quantity !== 0) &&
                        product.mainType === "product" &&
                        !product.nextClaim ? (
                          <button
                            className="bg-red b my-2 text-light pr-4 pl-4 border-0"
                            style={{ borderRadius: "79px", height: "32px" }}
                            onClick={() =>
                              this.openViewVoucherClaim(product.id, product)
                            }
                          >
                            Redeem
                          </button>
                        ) : (this.state.availableCoins >= product.price ||
                          product.quantity !== 0) &&
                        product.mainType === "game" &&
                        !product.nextClaim ? (
                          <button
                            className="bg-red b my-2 text-light pr-4 pl-4 border-0"
                            style={{ borderRadius: "79px", height: "32px" }}
                            onClick={() =>
                              this.openViewGameCardClaim(product.id, product)
                            }
                          >
                            Redeem
                          </button>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-between ml-3 mr-3">
                        <span className="d-flex">
                          <img
                            className="mr-2 mt-1"
                            src={icon}
                            alt=""
                            width={"10px"}
                            height={"11px"}
                          />
                          {product.quantity === 0 ? (
                            <p className="red">Out of Stock</p>
                          ) : this.state.availableCoins < product.price ? (
                            <p className="red">Not Enough Coins</p>
                          ) : product.mainType === "product" ||
                          product.mainType === "game" ? (
                            <p className="red">
                              1 {product.title} card per claim
                            </p>
                          ) : product.mainType === "voucher" ? (
                            <p className="red">1 {product.title} per claim</p>
                          ) : null}
                        </span>

                        {this.getLasRedeemItem(
                          product.id,
                          this.state.userItems,
                          product
                        ) && product.mainType === "voucher" ? (
                          //this.getLasRedeemItem(product.id, this.state.userItems, product) ? (
                          //product.nextClaim != null ? (
                          <a
                            className="text-danger font-weight-bold"
                            style={{ textDecoration: "underline" }}
                            onClick={() =>
                              this.openViewCouponClaim(
                                product.id,
                                product,
                                true
                              )
                            }
                          >
                            View Redeemed {product.mainType}
                          </a>
                        ) : this.getLasRedeemItem(
                          product.id,
                          this.state.userItems,
                          product
                        ) && product.mainType === "product" ? (
                          <a
                            className="text-danger font-weight-bold"
                            style={{ textDecoration: "underline" }}
                            onClick={() =>
                              this.openConfirmRedeemSignedBat(
                                product.id,
                                true,
                                product
                              )
                            }
                          >
                            View Redeemed {product.mainType}
                          </a>
                        ) : this.getLasRedeemItem(
                          product.id,
                          this.state.userItems,
                          product
                        ) && product.mainType === "game" ? (
                          <a
                            className="text-danger font-weight-bold"
                            style={{ textDecoration: "underline" }}
                            onClick={() =>
                              this.openConfirmPubg(product.id, true, product)
                            }
                          >
                            View Redeemed {product.mainType}
                          </a>
                        ) : null}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </section>
      </>
    );
  }
}

export default RedeemPrizeCard;
