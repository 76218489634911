import React, { Component } from "react";
import axios from "axios";
import {
  getCDNUrl,
  loginUser,
  handleGoogleEvents,
  tempUser,
} from "./HelperFunctions";

import { Globals } from "./constants";
import { Helmet } from "react-helmet";
const queryString = require("query-string");

class AuthenticationForOoredooOman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      phone: "",
      requestId: "",
      btnTxt: "Subscribe",
      btnDisbaled: false,
      message: "",
      inputName: "phone",
      flowToShow: "",
      jsScript: "",
      isMenaDLink: localStorage.getItem("isMenaDLink") ? true : false,
      filledNumber: localStorage.getItem("filledNumber")
        ? localStorage.getItem("filledNumber")
        : "",
      isSubscribed: 3,
    };

    this.apiPath = Globals.NodeApi + "api/";
    this.antiFraudApiPath = "https://antifraud.cgparcel.net/AntiFraud/Prepare/";
    this.userIPAddress = localStorage.getItem("ip");

    this.handleKeypress = this.handleKeypress.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.sendPin = this.sendPin.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.confirmPin = this.confirmPin.bind(this);
    this.confirm = this.confirm.bind(this);
    this.loginValuesChange = this.loginValuesChange.bind(this);
  }

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search);

    try {
      this.state.isMenaDLink
        ? this.setState({ phone: this.state.filledNumber })
        : this.setState({ phone: "" });
    } catch (error) {
      console.log(error);
    }

    if (params.MSISDN && params.MSISDN.toLowerCase() !== "empty") {
      this.setState({
        phone: params.MSISDN,
        isLoading: false,
        requestId: params.RequestID,
        flowToShow: "subscription",
      });
    } else if (params.MSISDN && params.MSISDN.toLowerCase() === "empty") {
      this.setState({
        isLoading: false,
        requestId: params.RequestID,
        flowToShow: "wifi",
      });
    }
  }

  async sendPin() {
    this.setState({
      btnTxt: "Please wait...",
      btnDisbaled: true,
    });

    let phone = this.state.phone;

    let url =
      this.apiPath +
      "login_or_sendPin?telco=ooredoo_oman&phone=" +
      phone +
      "&sub_type=";
    let serverResponse;
    serverResponse = await axios.get(url, {});

    this.setState({
      isLoading: false,
    });
    serverResponse = serverResponse.data;
    // new check added for time being
    if (serverResponse.generic_response.message === "Invalid Phone number") {
      this.setState({
        btnTxt: "Confirm",
        btnDisbaled: false,
        message: "You have entered invalid mobile number",
      });

      return;
    }

    if (serverResponse) {
      localStorage.setItem(
        "isSocialProfileAttached",
        serverResponse.remote_response && serverResponse.remote_response.user
          ? serverResponse.remote_response.user.is_social_profile_attached
          : "false"
      );

      let generic_response = {};

      if (
        (Object.keys(serverResponse).length === 0 &&
          serverResponse.constructor === Object) ||
        (!serverResponse.remote_response && !serverResponse.generic_response)
      ) {
        //empty response, means no response from  node server
        generic_response = tempUser(phone); //temporary disable | now enabled as per ali bhai 26-06-19
        // this.setState({ message: "Something went wrong!", btnTxt: 'Continue', btnDisbaled: false });
        // alert("Something went wrong, please check your network.");
      } else if (
        !serverResponse.status &&
        serverResponse.generic_response &&
        serverResponse.generic_response.call_type &&
        serverResponse.generic_response.call_type === "appstart" &&
        serverResponse.error
      ) {
        //appstart failed, remote server must not be responding
        generic_response = tempUser(phone); //temporary disabled | now enabled as per ali bhai 26-06-19
        // this.setState({ message: "Something went wrong. Please try again later!", btnTxt: 'Continue', btnDisbaled: false });
        // alert("Something went wrong, please check your internet connection.");
      } else if (!serverResponse.status) {
        //server responded but ERROR !
        this.setState({
          message: serverResponse.generic_response.message,
          btnTxt: "Confirm",
          btnDisbaled: false,
        });
      } else {
        generic_response = serverResponse.generic_response;
      }

      if (generic_response.subscribed) {
        this.setState({ message: "" });

        generic_response.phone = phone;
        generic_response.telco = this.state.telco;
        localStorage.setItem("user_logged_in", true);
        localStorage.setItem("user", JSON.stringify(generic_response));

        handleGoogleEvents(
          "Login",
          `Login-${this.state.telco}`,
          `User-Logged_In`
        );

        loginUser(generic_response);
        window.location.href = "/";
        return false;
      }

      if (
        serverResponse.status &&
        !serverResponse.generic_response.subscribed
      ) {
        //pin sent
        let antiFraudApi = this.antiFraudApiPath;
        let apiResponse;

        var req = new XMLHttpRequest();
        req.open("GET", document.location, false);
        req.send(null);

        var headers = req.getAllResponseHeaders();
        headers = JSON.stringify(headers);
        let headersb64 = window.btoa(headers);
        let userIP = window.btoa(this.userIPAddress);

        antiFraudApi =
          antiFraudApi +
          "?Page=2&ChannelID=99915705&ClickID=" +
          this.state.requestId +
          "&UserIP=" +
          userIP +
          "&MSISDN=" +
          this.state.phone +
          "&Headers=" +
          headersb64;

        apiResponse = await axios.get(antiFraudApi, {});
        console.log(apiResponse);

        this.setState({
          flowToShow: "pinSent",
          btnTxt: "Subscribe",
          btnDisbaled: false,
          jsScript: apiResponse.data,
        });
      }
    }
  }

  async confirmPin() {
    this.setState({
      btnTxt: "Please Wait ...",
      btnDisbaled: true,
    });

    let url = `${this.apiPath}confirmpin?telco=ooredoo_oman&phone=${
      this.state.phone
    }&pin=${this.state.pin}&sub_type=&uniquieId=${this.state.requestId}`;
    let serverResponse;

    serverResponse = await axios.get(url, {});

    serverResponse = serverResponse.data;

    if (serverResponse) {
      let generic_response = {};
      if (
        Object.keys(serverResponse).length === 0 &&
        serverResponse.constructor === Object
      ) {
        // no server response, ERROR ! login with a temporary user
        //  generic_response = tempUser(phone);
        this.setState({
          message: "Something went wrong!",
          btnTxt: "Confirm",
          btnDisbaled: false,
        });
      } else if (!serverResponse.status) {
        this.setState({
          message: serverResponse.generic_response.message,
          btnTxt: "Confirm",
          btnDisbaled: false,
          // flowToShow:'isOpenWebOrApp'
        });
      } else {
        generic_response = serverResponse.generic_response;
      }

      if (generic_response.subscribed) {
        this.setState({ message: "" });
        // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
        generic_response.phone = this.state.phone;
        generic_response.telco = "ooredoo_oman";
        this.setState({
          isSubscribed: serverResponse.remote_response.subscribe_status,
        });
        localStorage.setItem("user_logged_in", true);
        localStorage.setItem("user", JSON.stringify(generic_response));

        handleGoogleEvents(
          "Login",
          `Login-${this.state.telco}`,
          `Pin-Confirm-Ok-Subscribed`
        );

        loginUser(generic_response);
        // window.history.back();
        if (this.state.isMenaDLink) {
          this.setState({ flowToShow: "isOpenWebOrApp" });
        } else {
          window.location = document.documentURI;
        }
        console.log(this.state.isSubscribed);
      }
    }
  }

  loginValuesChange = (event) => {
    let lastKeyStroke = event.target.value[event.target.value.length - 1];
    let value = event.target.value;
    let setState = {};
    if (
      event.target.name === this.state.inputName &&
      this.state.prefill &&
      value.length < this.state.prefill.length
    ) {
      setState = {
        [event.target.name]: this.state.prefill,
        inputValue: this.state.prefill,
      };
    } else {
      setState = {
        [event.target.name]:
          (value.length && isNaN(lastKeyStroke)) || lastKeyStroke === " "
            ? this.state[event.target.name]
            : value,
        inputValue: value,
      };
    }
    this.setState(setState);
  };

  handleKeypress = function(e) {
    if (e.key === "Enter") {
      if (this.state.flowToShow === "wifi") {
        this.sendPin();
      } else if (this.state.flowToShow === "pinSent") {
        this.confirmPin();
      }
    }
  };

  confirm() {}

  async subscribe() {
    this.setState({
      btnTxt: "Please wait...",
      btnDisbaled: true,
    });

    let antiFraudApi = this.antiFraudApiPath;
    let apiResponse;

    var req = new XMLHttpRequest();
    req.open("GET", document.location, false);
    req.send(null);

    var headers = req.getAllResponseHeaders();
    headers = JSON.stringify(headers);
    let headersb64 = window.btoa(headers);
    let userIP = window.btoa(this.userIPAddress);

    antiFraudApi =
      antiFraudApi +
      "?Page=2&ChannelID=99915705&ClickID=" +
      this.state.requestId +
      "&UserIP=" +
      userIP +
      "&MSISDN=" +
      this.state.phone +
      "&Headers=" +
      headersb64;

    apiResponse = await axios.get(antiFraudApi, {});
    console.log(apiResponse);

    this.setState({
      btnTxt: "Confirm",
      btnDisbaled: false,
      jsScript: apiResponse.data,
      flowToShow: "confirmation",
    });
  }

  goToLogin() {
    this.state.isMenaDLink
      ? (window.location.href = "/login/true/CricwickOman")
      : (window.location.href = "/login");
  }

  renderSubscribe = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Subscribe{" "}
        </div>
        <h3 className="text-center mb-3">
          Please press the button to Subscribe the service on the following
          number
        </h3>
        <h3 className="text-center mb-5"> {this.state.phone} </h3>

        <div className="">
          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.subscribe}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>

          <p
            className={"text-center w-50"}
            style={{ margin: "15px auto 0 auto" }}
          >
            {this.state.message}
          </p>

          {
            <>
              <p
                className={"text-center w-50 font-weight-bold"}
                style={{ margin: "0 auto 0 auto" }}
              >
                OMR 1.313 per week ( Price includes 5% VAT). This service renews
                automatically.
              </p>
            </>
          }

          {
            <>
              <p
                className={"text-center w-50" + this.state.telcoMessageClass}
                style={{
                  margin: `30px auto 0 auto`,
                }}
              >
                {`Welcome to Cricwick!\n\n OMR 1.313 per week ( Price includes 5% VAT) or proportionally as per your balance. 
                    This service renews automatically..
                    Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, text unsub c to 91971. 
                    To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill.
                    The content inside this service is in English`}
              </p>
            </>
          }
        </div>
      </div>
    );
  };

  renderConfirmation = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Confirmation{" "}
        </div>
        <h3 className="text-center mb-3">Please confirm your subscription</h3>

        <div id="AFsubmitbtn">
          <input type="text" value={this.state.phone} hidden />
          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.confirm}
            disabled={this.state.btnDisbaled}
          >
            Confirm
          </button>
        </div>
        <p
          className={"text-center w-50"}
          style={{ margin: "15px auto 0 auto" }}
        >
          {this.state.message}
        </p>

        {
          <>
            <p
              className={"text-center w-50 font-weight-bold"}
              style={{ margin: "0 auto 0 auto" }}
            >
              OMR 1.313 per week ( Price includes 5% VAT). This service renews
              automatically.
            </p>
            {/* <br></br>
              <p
                className={"text-center w-50 font-weight-bold"} dir="rtl" lang="ar"
                style={{ margin: "0 auto 0 auto" }}
              >
                250 بيسة عماني في اليوم (تخضع إلى 5% ضريبة القيمة المضافة).
              </p> */}
          </>
        }

        {
          <>
            <p
              className={"text-center w-50" + this.state.telcoMessageClass}
              style={{
                margin: `30px auto 0 auto`,
              }}
            >
              {`Welcome to Cricwick!\n\n OMR 1.313 per week ( Price includes 5% VAT) or proportionally as per your balance. 
                  This service renews automatically..
                  Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, text unsub c to 91971. 
                  To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill.
                  The content inside this service is in English`}
            </p>
          </>
        }
      </div>
    );
  };

  renderPhone = () => {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "1.7rem",
            lineHeight: "1.2 !important",
            fontWeight: "600",
          }}
        >
          Subscribe now to watch Premium Cricket Content and Play Fantasy League
          for free to Win Exciting Prizes in every match.
        </div>
        <br />

        <div className={`mt-3`}>
          {this.renderPhoneInput()}

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-5 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={() => {
              this.sendPin();
            }}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>
        </div>
      </div>
    );
  };

  renderPhoneInput = () => {
    return (
      <div
        style={{
          textAlign: "center",
          paddingBottom: "6px",
        }}
      >
        <div className="mb-1" style={{ fontSize: "1.7rem", fontWeight: "600" }}>
          {`Enter Phone Number`}
        </div>
        <input
          name={this.state.inputName}
          type="text"
          ref={this.textInput}
          placeholder={`968 xxxxxxxx`}
          value={this.state.phone}
          onChange={this.loginValuesChange}
          onKeyPress={this.handleKeypress}
          autoComplete="off"
          className="telco-input"
          maxLength={this.state.lengthOfPhone}
        />
        <p
          className="message mt-3 text-danger text-center"
          style={{ fontSize: "16px" }}
        >
          {this.state.message}
        </p>
      </div>
    );
  };

  renderError = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Error{" "}
        </div>
        <h3 className="text-center mb-3">
          You can not subscribe to this service
        </h3>
        <h3 className="text-center mb-5">
          {" "}
          You can subscribe with another number{" "}
        </h3>
        <button
          className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={this.goToLogin}
        >
          Go to Login
        </button>
      </div>
    );
  };

  renderPin = () => {
    return (
      <div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          {" "}
          Pin Verification{" "}
        </div>
        <h3 className="text-center mb-3">
          We have sent PIN code via SMS to following number
        </h3>
        <h3 className="text-center mb-5"> {this.state.phone} </h3>
        {/* <div>{ this.renderTelcosForPin()}</div> */}

        <div className="">
          <input
            name={"pin"}
            //className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0"
            type="text"
            ref={this.textInput}
            autoFocus={true}
            placeholder={"Enter your pin code here"}
            value={this.state.pin}
            onChange={this.loginValuesChange}
            onKeyPress={this.handleKeypress}
            autoComplete="off"
            style={{
              width: 100 + "%",
              textAlign: "left",
              backgroundColor: "white",
              borderBottomColor: "lightgray",
              paddingLeft: "10px",
              paddingBottom: 8 + "px",
              borderRadius: 0,
              height: "65px",
              border: "none",
              background: "#F8F7F7",
              outline: "none",
              fontSize: "1.6rem",
            }}
            maxLength={`4`}
            // max="6"
          />
          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.confirmPin}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>

          <p
            className={"text-center w-50"}
            style={{ margin: "15px auto 0 auto" }}
          >
            {this.state.message}
          </p>

          {
            <>
              <p
                className={"text-center w-50 font-weight-bold"}
                style={{ margin: "0 auto 0 auto" }}
              >
                OMR 1.313 per week ( Price includes 5% VAT). This service renews
                automatically.
              </p>
            </>
          }

          {
            <>
              <p
                className={"text-center w-50" + this.state.telcoMessageClass}
                style={{
                  margin: `30px auto 0 auto`,
                }}
              >
                {`Welcome to Cricwick!\n\n OMR 1.313 per week ( Price includes 5% VAT) or proportionally as per your balance. This service renews automatically..\n\n Charges will be deducted from Ooredoo balance. Your subscription will renew automatically until cancelled. To cancel, text unsub c to 91971.\n\n To use this service, you must be 18+ years old or have received permission from your parents or person authorized to pay your bill.\n\n The content inside this service is in English`}
              </p>
            </>
          }
        </div>
      </div>
    );
  };

  renderOpenWebOrApp = () => {
    return (
      <div>
        <h3 className="text-center mb-3">
          You have been subscribed successfully
        </h3>
        <div className="text-center m-3">
          <img
            src={require("../src/images/success.png")}
            alt="success"
            height={200}
            width={200}
          />
        </div>
        <button
          className="submit border-0 w-30 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={() => {
            window.location.href = "/";
            localStorage.setItem("isMenaDLink", false);
          }}
        >
          Continue on Web
        </button>
        <h3 className="text-center mt-5"> OR </h3>
        <button
          className="submit border-0 w-30 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={() => {
            let link = `http://cricwick.net/welcome/${localStorage.getItem(
              "filledNumber"
            )}/${this.state.isSubscribed}/ooredoo_oman`;
            window.open(link, "_self");
          }}
        >
          Open App
        </button>
      </div>
    );
  };

  render() {
    return (
      <div id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <>
            {this.state.jsScript &&
              this.state.jsScript.length > 0 && (
                <Helmet>
                  <script>{this.state.jsScript}</script>
                </Helmet>
              )}
            <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
              {this.state.flowToShow === "subscription"
                ? this.renderSubscribe()
                : this.state.flowToShow === "confirmation"
                  ? this.renderConfirmation()
                  : this.state.flowToShow === "wifi"
                    ? this.renderPhone()
                    : this.state.flowToShow === "pinSent"
                      ? this.renderPin()
                      : this.state.flowToShow === "isOpenWebOrApp"
                        ? this.renderOpenWebOrApp()
                        : null}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default AuthenticationForOoredooOman;
