import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Globals } from "./constants";
import { Helmet } from "react-helmet";
import { canonicalLink, getCDNUrl, hyphen2space } from "./HelperFunctions";
import fantasyPrize from "../src/images/fantasyPrize.png";
import live from "../src/images/live.png";
import bat from "../src/images/bat.png";
import noAds from "../src/images/noAds.png";

class InvitedByFriend extends Component {
  state = {
    inviteCode: null,
    prizePoint: "20",
    discount: "75",
  };

  linkToFantasy = "";

  user = JSON.parse(localStorage.getItem("user"));

  returnThingsToDoRow = (index, point) => {
    return (
      <div className={`d-flex mt-3`}>
        <div>
          <h2 className={"m-f-14 "}>{point}</h2>
        </div>
      </div>
    );
  };

  getUserByInviteCode = async (inviteCode) => {
    let url = `${Globals.UserManagment}api/v1/users/get-user-by-ic`;
    let userReturnedFromApi = await axios.get(url, {
      params: {
        inviteCode,
      },
      headers: {
        app: localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : "",
      },
    });
    userReturnedFromApi = userReturnedFromApi.data;
    if (userReturnedFromApi.status === 1) {
      userReturnedFromApi = userReturnedFromApi.data;
      return {
        inviterMsisdn: userReturnedFromApi.msisdn,
        inviterName: userReturnedFromApi.name,
      };
    } else {
      console.log(
        `Error in invited friend get user by invite code`,
        userReturnedFromApi.message
      );
      return null;
    }
  };

  async componentDidMount() {
    const inviteCode = this.props.match.params.inviteCode;
    localStorage.setItem("inviteCode", inviteCode);
    if (inviteCode.length) {
      console.log(inviteCode);
      let user = await this.getUserByInviteCode(inviteCode);
      if (!user) this.props.history.push(`/`);
      if (user) {
        this.setState({
          inviteCode:
            user.inviterMsisdn && user.inviterMsisdn.length
              ? user.inviterMsisdn
              : user.inviterName,
        });
      }

      let invitedUser = {
        inviterMsisdn: user ? user.inviterMsisdn : null,
        seriesId: this.props.match.params.seriesId,
        matchId: this.props.match.params.matchId,
        leagueId: this.props.match.params.leagueId,
        constantId: this.props.match.params.constantId,
      };
      if (
        invitedUser.seriesId &&
        invitedUser.matchId &&
        invitedUser.leagueId &&
        invitedUser.constantId
      ) {
        localStorage.setItem(
          "redirect_after_login",
          `/fantasy?createTeam=true&seriesId=${invitedUser.seriesId}&matchId=${
            invitedUser.matchId
          }&leagueId=${invitedUser.leagueId}&constantId=${
            invitedUser.constantId
          }`
        );
        this.linkToFantasy = `/fantasy?createTeam=true&seriesId=${
          invitedUser.seriesId
        }&matchId=${invitedUser.matchId}&leagueId=${
          invitedUser.leagueId
        }&constantId=${invitedUser.constantId}`;

        if (this.user) {
          this.props.history.push(this.linkToFantasy);
          localStorage.removeItem("redirect_after_login");
          localStorage.removeItem("invitedUser");
        }
      } else localStorage.setItem("redirect_after_login", `/fantasy`);
    }
  }

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        <Helmet>
          <meta
            property="apple-itunes-app"
            content={
              "app-id=com.khaleef.cricwicktelenorzong, app-argument=" +
              window.location.pathname
            }
          />
        </Helmet>
        <div>
          <div className={`d-flex justify-content-center mt-4`}>
            <img
              src={getCDNUrl(`/images/friendship.svg`)}
              alt={`Friends`}
              width={70}
              height={`auto`}
            />
          </div>
          <div
            className={`d-flex justify-content-center align-items-center mt-3`}
            style={{ flexDirection: "column" }}
          >
            <h2 className={"font-weight-bold p-2 m-f-14 mb-0"}>
              YOU HAVE BEEN INVITED BY
            </h2>
            <span className={`redCl font-weight-bold`}>
              {" "}
              {`${this.state.inviteCode}`} {/*{`${this.state.inviteCode}`}*/}
            </span>
          </div>
          <div className={`d-flex justify-content-center mt-3`}>
            <div
              className={`lightRedBg`}
              style={{
                padding: `20px 15px`,
                borderRadius: `10px 10px 0px 0px`,
                width: "100%",
              }}
            >
              <div className="text-center">
                You got: <br />
                <h1 style={{ color: "#0BBA9D" }}>200K COINS</h1>
              </div>
              <div>
                <h2 className={"font-weight-bold p-2 m-f-14 text-center"}>
                  Please Register and Claim Reward
                </h2>
              </div>
            </div>
          </div>
          <div className={`d-flex justify-content-center mt-1`}>
            <div
              className={`lightRedBg`}
              style={{
                padding: `20px 15px`,
                borderRadius: `0px 0px 10px 10px`,
                width: "100%",
              }}
            >
              <div
                className={"text-center d-flex"}
                style={{ flexDirection: "column" }}
              >
                <span className="redCl font-weight-bold">
                  BENEFITS OF JOINING CRICWICK
                </span>
                <div
                  className="d-flex align-self-center align-items-start justify-content-center"
                  style={{ flexDirection: "column", width: "30%" }}
                >
                  <span className="d-flex justify-content-center align-items-center mt-3 mb-3">
                    <img
                      className="mr-3"
                      width={"24px"}
                      height={"24px"}
                      src={fantasyPrize}
                      alt=""
                    />
                    <p className="m-0 font-weight-bold">Fantasy Prizes</p>
                  </span>
                  <span className="d-flex justify-content-center align-items-center mb-3">
                    <img
                      className="mr-3"
                      width={"24px"}
                      height={"24px"}
                      src={live}
                      alt=""
                    />
                    <p className="m-0 font-weight-bold">Live Streaming</p>
                  </span>
                  <span className="d-flex justify-content-center align-items-center mb-3">
                    <img
                      className="mr-3"
                      width={"24px"}
                      height={"24px"}
                      src={bat}
                      alt=""
                    />
                    <p className="m-0 font-weight-bold">
                      Ball by ball highlights
                    </p>
                  </span>
                  <span className="d-flex justify-content-center align-items-center">
                    <img
                      className="mr-3"
                      width={"24px"}
                      height={"24px"}
                      src={noAds}
                      alt=""
                    />
                    <p className="m-0 font-weight-bold">No Ads</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className={`d-flex justify-content-center mt-2`}>
            <button
              className="submit border-0 btn bg-red text-white rounded-0 p-3 text-uppercase font-weight-bold"
              onClick={() => this.props.history.push("/login")}
            >
              {`Register & Claim`}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(InvitedByFriend);
