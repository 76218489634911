import firebase from "firebase";
import { Telco } from "./telco";
export const FireBaseRefId = "l_m_ids";

var config = {
  /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  // Using this for stagging
  // apiKey: "AIzaSyArlqEz6Toa5ueId7_X45Sft1jm5gQDOXA",
  // authDomain: "jazz-cricwick.firebaseapp.com",
  // databaseURL: "https://jazz-cricwick.firebaseio.com",
  // projectId: "jazz-cricwick",
  // storageBucket: "jazz-cricwick.appspot.com",
  // messagingSenderId: "721784746258",
  // appId: "1:721784746258:web:69eaf5b702b0c150",
  // measurementId: "G-62S0L4Z9MN"
};

if (Telco === "mobilink") {
  config = {
    /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyArlqEz6Toa5ueId7_X45Sft1jm5gQDOXA",
    authDomain: "jazz-cricwick.firebaseapp.com",
    databaseURL: "https://jazz-cricwick.firebaseio.com",
    projectId: "jazz-cricwick",
    storageBucket: "jazz-cricwick.appspot.com",
    messagingSenderId: "721784746258",
    appId: "1:721784746258:web:69eaf5b702b0c150",
    measurementId: "G-62S0L4Z9MN",
  };
} else if (Telco === "ufone") {
  config = {
    /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyArlqEz6Toa5ueId7_X45Sft1jm5gQDOXA",
    authDomain: "jazz-cricwick.firebaseapp.com",
    databaseURL: "https://jazz-cricwick.firebaseio.com",
    projectId: "jazz-cricwick",
    storageBucket: "jazz-cricwick.appspot.com",
    messagingSenderId: "721784746258",
    appId: "1:721784746258:web:69eaf5b702b0c150",
    measurementId: "G-62S0L4Z9MN",
  };
} else if (Telco === "zain") {
  config = {
    /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: "AIzaSyArlqEz6Toa5ueId7_X45Sft1jm5gQDOXA",
    authDomain: "jazz-cricwick.firebaseapp.com",
    databaseURL: "https://jazz-cricwick.firebaseio.com",
    projectId: "jazz-cricwick",
    storageBucket: "jazz-cricwick.appspot.com",
    messagingSenderId: "721784746258",
    appId: "1:721784746258:web:69eaf5b702b0c150",
    measurementId: "G-62S0L4Z9MN",
  };
} else if (Telco === "cricwick") {
  config = {
    apiKey: "AIzaSyAad55sYbMHVh9dIkVgq_F1QWGtFJeMgwg",
    authDomain: "crickettelenorzong.firebaseapp.com",
    databaseURL: "https://crickettelenorzong.firebaseio.com",
    projectId: "crickettelenorzong",
    storageBucket: "crickettelenorzong.appspot.com",
    messagingSenderId: "296181766295",
    appId: "1:296181766295:web:d92abadbe59f240b",
    measurementId: "G-06Q06TCMQH",
  };
}

var FireBase = firebase.initializeApp(config);
FireBase.analytics();
export default FireBase;
