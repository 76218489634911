import React from "react";
import { Helmet } from "react-helmet";
import { Globals } from "./constants";
import { canonicalLink } from "./HelperFunctions";

class MyStars11TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);

    let telco, domain;

    if (
      this.props &&
      this.props.telco &&
      this.props.telco.length > 0 &&
      this.props.domain &&
      this.props.domain.length > 0
    ) {
      telco = this.props.telco;
      domain = this.props.domain;
    } else {
      telco = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).telco
        : "";
      domain = Globals.appName;
    }

    this.state = {
      telco: telco,
      domain: domain,
    };
  }

  render() {
    return (
      <div
        id="main-section"
        className="holds-the-iframe"
        style={{ backgroundColor: "#FFFFFF", zIndex: "0" }}
      >
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.state.domain.toLowerCase() === "jazzcricketweb" ||
        this.state.telco.toLowerCase() === "mobilink" ||
        this.state.telco.toLowerCase() === "warid" ||
        this.state.telco.toLowerCase() === "jazz" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/Terms & Conditions - JC Final.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : this.state.telco.toLowerCase().toLowerCase() === "ufone" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/Terms+%26+Conditions+-+Cricwick+Ufone+Final.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : this.state.telco.toLowerCase() === "zong" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/Terms+%26+Conditions+-+Cricwick+Zong+Final.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : this.state.telco.toLowerCase() === "telenor" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/Terms+%26+Conditions+-+Cricwick+TP+Final.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : this.state.telco.toLowerCase().toLowerCase() === "zain_kw" ||
        this.state.telco.toLowerCase().toLowerCase() === "zain_bh" ||
        this.state.telco.toLowerCase().toLowerCase() === "zain" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/CricWick-Zain-T%26C.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : this.state.telco.toLowerCase().toLowerCase() === "virgin" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/CricWick-Friendi-T&C.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : this.state.telco.toLowerCase().toLowerCase() === "stc" ||
        this.state.telco.toLowerCase().toLowerCase() === "mobily" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/CricWick-General-T%26C.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MyStars11TermsAndConditions;
