// import {Telco} from './telco',

export const DGlobals = { og: {}, login: {}, pk: {} };

// !!!!!!!!!!!~~~~~CREATE A FILE AND ADD TELCO~~~~~!!!!!!!!!!!
// !!!!!!!!!!!  src/telco.js                       !!!!!!!!!!!
// !!!!!!!!!!!  export const Telco = "mobilink",   !!!!!!!!!!!

const version = "2.0";

DGlobals.Telcos = [
  {
    //if(Telco :== 'mobilink') in the case Telco is Mobilink

    //Globals.ApiUrl    : "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    Api2: "https://mobisub.cricwick.mobi/main/",
    //NodeApi      : "https://staging.cricwick.net:13002/",
    NodeApi: "http://localhost:3002/",

    firebase: false,
    ssl: true,

    allowLowBalance: true,
    imgRightAppDownload: "/images/mobilink/cric-sites-web-banner.jpg",
    logo: "/images/mobilink/jazz_logo.png?v:1",
    title: "Jazz Cricket - Enjoy live cricket and watch key cricket moments",
    telco: "mobilink", //Telco,

    cssFor: "mobilink",
    product: "Jazz Cricket",
    reDirectSucessURL: "",
    serviceID: "",
    sucessRedirectFromDigital: "https://cricket.jazz.com.pk/?q=", // https://cricket.jazz.com.pk/?q=<msisdn> for Mobilink/Jazz/Warid

    ios: "https://itunes.apple.com/pk/app/jazz-cricket/id1371362811?mt:8",
    androidMarket: "market://details?id=com.Khaleef.CricWickMobilink",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWickMobilink",

    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "/images/mobilink/og-image.jpg",
    ogTitle: "Jazz Cricket",

    regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,

    serviceId: "-1",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "03xxxxxxxxx",
    loginPinPlaceholder: "XXXXX",
    loginEnterPin:
      "Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.",
    loginImage: "/images/mobilink/img-download.jpg",
    loginInvalidPhone: `Please enter phone number in following format 03xxxxxxxxx `,

    placeholder4x3: "/images/mobilink/placeholder4x3.jpg",
    placeholder16x9: "/images/mobilink/placeholder16x9.jpg",
    placeholderVideo4x3: "/images/mobilink/placeholderVideo4x3.jpg",
    placeholderPlain4x3: "/images/mobilink/placeholderPlain4x3.jpg",

    consentGatewayRedirection: false,
    cgUrl: "",
    telcoDisclaimerText:
      "Please enter the PIN code received in SMS. Prepaid users will be charged with PKR 2.38+Tax daily",
  },

  {
    //else if(Telco === 'ufone') in Case of Telco is Mobilink
    serviceId: "-1",

    regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
    // Globals.ApiUrl         : "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey      : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    Api2: "https://mobisub.cricwick.mobi/main/",
    NodeApi: "https://staging.cricwick.net:13002/",
    //  Globals.NodeApi        : "http://localhost:3002/",

    ssl: false,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/ufone/ufone_logo.jpg?v=1",
    imgRightAppDownload: "/images/ufone/cric-sites-web-banner.jpg",
    title: "Ufone Cricket - Enjoy live cricket and watch key cricket moments",
    telco: "ufone", //Telco,
    cssFor: "ufone",
    product: "Ufone Cricket",
    telcoDisclaimerText:
      "Please enter the PIN code received in SMS. Prepaid users will be charged with PKR 2.38+Tax daily",

    sucessRedirectFromDigital: "http://cricket.ufone.com/?q=", //"http://cricket.ufone.com/?q=<msisdn>" Ufone

    ios: "https://itunes.apple.com/pk/app/ufone-cricket/id1380145518",
    androidMarket: "market://details?id=com.ufone.cricket",
    android: "https://play.google.com/store/apps/details?id=com.ufone.cricket",
    ogTitle: "Ufone Cricket",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "/images/ufone/og-image.jpg",

    disableSideLogin: true,
    EnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "03xxxxxxxxx",
    loginSubscriptionPackages: true,
    loginPinPlaceholder: "XXXXX",
    loginEnterPin:
      "Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.",
    loginImage: "/images/ufone/img-download.jpg",
    loginInvalidPhone: `Please enter phone number in following format  : 03xxxxxxxxx  `,

    placeholder4x3: "/images/ufone/placeholder4x3.jpg",
    placeholder16x9: "/images/ufone/placeholder16x9.jpg",
    placeholderVideo4x3: "/images/ufone/placeholderVideo4x3.jpg",
    placeholderPlain4x3: "/images/ufone/placeholderPlain4x3.jpg",

    consentGatewayRedirection: false,
    cgUrl: "",
  },

  {
    serviceId: "-1",

    //else if(Telco === 'zain')
    //zain ksa, cric.sa.zain.com
    // Globals.ApiUrl    = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    Api2: "http://mobisub.cricwick.mobi/main/",
    //NodeApi      : "https://staging.cricwick.net:13002/",
    NodeApi: "http://localhost:3002/",

    ssl: false,
    firebase: false,

    allowLowBalance: true,
    logo: "/images/zain/cricwick-logo.jpg?v=" + version,
    telcoLogo: "/images/zain/telco_logo.png",
    imgRightAppDownload: "/images/zain/cric-sites-web-banner.jpg?v=11",
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    telco: "zain",
    cssFor: "zain",
    telcoDisclaimerText:
      "Zain: 5SR/week. You will be charged weekly. To cancel your subscription send U8 to 704421. If you are already a subscriber then we will just verify your phone number.",

    sucessRedirectFromDigital: "http://cric.sa.zain.com/?q=", // "http://cric.sa.zain.com/?q=<msisdn>" Zain

    product: "Cricwick",
    ios: "https://itunes.apple.com/us/app/cricwick/id1436495759?ls=1&mt=8",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "966xxxxxxxxx",
    loginPinPlaceholder: "XXXXX",
    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/zain/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format 966xxxxxxxxx`,

    placeholder4x3: "/images/zain/placeholder4x3.jpg",
    placeholder16x9: "/images/zain/placeholder16x9.jpg",
    placeholderVideo4x3: "/images/zain/placeholderVideo4x3.jpg",
    placeholderPlain4x3: "/images/zain/placeholderPlain4x3.jpg",

    cgUrl: "",
    consentGatewayRedirection: false,
  },

  {
    serviceId: "-1",

    // else if(Telco === "telenor")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload:
      "/images/cricwick/cric-sites-web-banner.jpg?v=" + version,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios = 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "03xxxxxxxxx",
    loginPinPlaceholder: "XXXXX",
    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format : 03xxxxxxxxx `,
    loginChooseTelco: "Please Select Your Network Operator",

    // Globals.login.chooseTelco = "Please Choose Your Operator",

    telco: "telenor",
    cssFor: "telenor",
    name: "telenor (PK)",
    b: "mobilink",
    flag: "/images/flags/pak.jpg",
    regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,

    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText: "",

    sucessRedirectFromDigital: "https://cricwick.net/?oid=101&q=", // "https://cricwick.net/?oid=101&q=<msisdn>"   Telenot
  },

  {
    serviceId: "-1",
    //else if(Telco === "zong")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 = "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi = "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload:
      "/images/cricwick/cric-sites-web-banner.jpg?v=" + version,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios = 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "03xxxxxxxxx",
    loginPinPlaceholder: "XXXXX",
    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format 03xxxxxxxxx `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco = "Please Choose Your Operator",

    telco: "zong",
    cssFor: "zong",
    name: "zong (PK)",
    b: "mobilink",
    flag: "/images/flags/pak.jpg",
    regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText: "",

    sucessRedirectFromDigital: "https://cricwick.net/?oid=102&q=", //"https://cricwick.net/?oid=102&q=<msisdn>" Zong
  },

  {
    serviceId: "115",
    //else if(Telco ==="mobily" )
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 = "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //NodeApi = "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload:
      "/images/cricwick/cric-sites-web-banner.jpg?v=" + version,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios = 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",

    AndroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",
    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "966xxxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco = "Please Choose Your Operator",

    telco: "mobily",
    cssFor: "mobily",
    name: "mobily (KSA)",
    b: "mobily",
    flag: "/images/flags/ksa.jpg",
    regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText:
      "Mobily: 0.66SR/daily. You will be charged daily. To cancel your subscription send U1 to 600159. If you are already a subscriber then we will just verify your phone number.",

    sucessRedirectFromDigital: "https://cricwick.net/?oid=103&q=", // "https://cricwick.net/?oid=103&q=<msisdn>" Mobily
  },

  {
    serviceId: "113",
    //else if(Telco === "stc" )
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 = "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi = "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios = 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "966xxxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco = "Please Choose Your Operator",

    telco: "stc",
    cssFor: "stc",
    b: "stc",
    flag: "/images/flags/ksa.jpg",
    regex: /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
    telco_message:
      "The service price is 1 SAR per day and renewed daily. To cancel your subscription please send U 8 to 801755.",
    name: "STC (KSA)",
    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText:
      "STC: 0.65SR/daily. You will be charged daily. To cancel your subscription send U 11 to 801755. If you are already a subscriber then we will just verify your phone number.",

    sucessRedirectFromDigital: "https://cricwick.net/?oid=104&q=", // "https://cricwick.net/?oid=104&q=<msisdn>" STC
  },

  {
    serviceId: "4",
    //else if(Telco === "zain_bh")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 = "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios = 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkAndroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "973xxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco : "Please Choose Your Operator",

    telco: "zain_bh",
    cssFor: "zain_bh",
    name: "Zain (BH)",
    b: "zain_bh",
    flag: "/images/flags/bhr.jpg",
    regex: /^9733\d{7}$|^03\d{7}$|^9661111111\d{2}$|^3\d{7}$/,
    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText:
      "You will subscribe to CricWick App for 0.10 BHD daily. New Users will be given a 1 day free trial, then will be charged the daily rate hereafter. To unsubscribe send UNSUB 1 to 94005 or use the unsubscribe button in the menu within app. Any enquiries, please contact: info@cricwick.net",

    sucessRedirectFromDigital: "https://cricwick.net/?oid=105&q=", //"https://cricwick.net/?oid=105&q=<msisdn>"  Zain Bahrain
  },
  {
    serviceId: "2",
    //else if(Telco === "zain_kw")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging

    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios : 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogdescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "965xxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco : "Please Choose Your Operator",

    telco: "zain_kw",
    cssFor: "zain_kw",
    name: "Zain (KW)",
    b: "zain_kw",
    flag: "/images/flags/kwt.jpg",
    regex: /^965\d{8}$|^05\d{8}$|^5\d{8}$|^9661111111\d{2}$/,
    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText:
      "You will subscribe to CricWick App for 0.10 KD daily. New Users will be given a 1 day free trial, then will be charged the daily rate hereafter. To unsubscribe send UNSUB 5 to 94010 or use the unsubscribe button in the menu within app. Any enquiries, please contact: info@cricwick.net",

    sucessRedirectFromDigital: "https://cricwick.net/?oid=105&q=", //"https://cricwick.net/?oid=106&q=<msisdn>" Zain Kuwait
  },

  {
    serviceId: "103",
    //else if(Telco === "ooredoo")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios : 'market://',
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "974xxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco : "Please Choose Your Operator",

    telco: "ooredoo",
    cssFor: "ooredoo",
    name: "Ooredoo (QA)",
    b: "ooredoo",
    flag: "/images/flags/qtr.jpg",
    regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
    cgUrl: "",
    consentGatewayRedirection: false,
    telcoDisclaimerText: "", // no Disclammer

    sucessRedirectFromDigital: "https://cricwick.net/?oid=107&q=", // "https://cricwick.net/?oid=107&q=<msisdn>" //Ooredoo Qatar
  },

  {
    serviceId: "-1",
    //else if(Telco ::= "ooredoo_kw" )
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,
    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios : 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "974xxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco : "Please Choose Your Operator",

    cssFor: "ooredoo_kw",

    telco: "ooredoo_kw",
    name: "Ooredoo (KW)",
    b: "ooredoo_kw",
    regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
    flag: "/images/flags/kwt.jpg",
    cgRedirectionUrl: `${window.location.origin}/authentication/`,
    consentGatewayRedirection: true,
    telcoDisclaimerText: "", // No Message in List

    cgUrl: `http://galaxylp.mobi-mind.net/?Id=703,CRICKWICK,965,2695,45,${
      window.location.origin
    }${"/authentication/"}${",0,41903,4545"}`,

    sucessRedirectFromDigital: "https://cricwick.net/?oid=108&q=", // https://cricwick.net/?oid=108&q=<msisdn>  Ooredoo Kuwait
  },

  {
    serviceId: "-1",
    //else if(Telco === "bbh")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging

    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios : 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "974xxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    } `,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco : "Please Choose Your Operator",

    telco: "bbh",
    cssFor: "bbh",
    name: "Batelco (BH)",
    b: "bbh",
    regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
    flag: "/images/flags/bhr.jpg",
    cgRedirectionUrl: `${window.location.origin}${"/authentication/"}`,
    consentGatewayRedirection: true,
    telcoDisclaimerText:
      "Zain users will be chared with 0.1 BHD Daily.To unsubscribe send UNSUB 1 to 94005 Batelco: 0.7BHD/week",

    cgUrl: `http://galaxylp.mobi-mind.net/?Id=701,CRICKWICK,973,2695,361,${
      window.location.origin
    }${"/authentication/"}${",0,42601,4542&IURL=http://galaxylp.mobi-mind.net/Cricwick/banner.jpg&MM_languageId=3"}`,

    sucessRedirectFromDigital: "https://cricwick.net/?oid=109&q=", // https://cricwick.net/?oid=109&q=<msisdn>  https://cricwick.net/?oid=109&q=<msisdn>
  },

  {
    serviceId: "-1",
    //else if(Telco === "viva_kw")
    // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
    ApiUrl: "https://back.cricwick.net/", //live
    // Globals.pusherKey : "96a11f3e2f71d6b34e4f",//staging
    pusherKey: "44382e5777aebbc67aad", //live
    // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
    NodeApi: "http://localhost:3002/", //local
    //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

    ssl: true,
    firebase: false,

    allowLowBalance: true,

    logo: "/images/cricwick/cricwick-logo.jpg?v=1",
    imgRightAppDownload: `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
    title: "Cricwick - Enjoy live cricket and watch key cricket moments",

    product: "Cricwick",

    // Globals.ios : 'market://',
    ios: "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    android:
      "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick",
    androidMarket: "market://details?id=com.Khaleef.cricwick",
    pkios:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroid:
      "https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong",
    pkandroidMarket: "market://details?id=com.Khaleef.CricWick.TelenorZong",

    ogTitle: "Cricwick",
    ogDescription: "Enjoy live cricket and watch key cricket moments",
    ogImage: "",

    loginEnterNumber: "Please Enter Your Number",
    loginPhonePlaceholder: "974xxxxxxxx",
    loginPinPlaceholder: "XXXXX",

    loginEnterPin: "Please enter the Pin code you received in SMS.",
    loginImage: `/images/cricwick/img-download.jpg?v=${version}`,
    loginInvalidPhone: `Please enter phone number in following format ${
      DGlobals.loginPhonePlaceholder
    }`,
    loginChooseTelco: "Please Select Your Network Operator",
    // Globals.login.chooseTelco : "Please Choose Your Operator",

    // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,18,"+ window.location.origin+"/authentication/" +",0,41904,2395",
    telco: "viva_kw",
    cssFor: "viva_kw",
    name: "Viva (KW)",
    b: "viva_kw",
    flag: "/images/flags/kwt.jpg",
    regex: /^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,

    consentGatewayRedirection: true,
    cgRedirectionUrl: `${window.location.origin}${"/authentication/"}`,
    telcoDisclaimerText: "",

    cgUrl: `http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,361,${
      window.location.origin
    }${"/authentication/"}${",0,41904,2395"}`,

    sucessRedirectFromDigital: "https://cricwick.net/", // https://cricwick.net/?oid=110&q=<msisdn>// Viva Kuwait
  },

  //     {
  //         serviceId           : '36',
  //         //else if(Telco === "comviva_du")
  //         // Globals.ApiUrl = "http://54.245.34.7:8080/",//staging
  //         ApiUrl : "https://back.cricwick.net/",//live
  //         // Globals.pusherKey = "96a11f3e2f71d6b34e4f",//staging
  //         pusherKey : "44382e5777aebbc67aad",//live
  //         // Globals.Api2 : "https://mobisub.cricwick.mobi/main/",
  //         NodeApi : "http://localhost:3002/", //local
  //         //Globals.NodeApi : "https://staging.cricwick.net:13002/",//ssl live

  //         ssl : true,
  //         firebase : false,

  //         allowLowBalance : true,

  //         logo : "/images/cricwick/cricwick-logo.jpg?v=1",
  //         imgRightAppDownload : `/images/cricwick/cric-sites-web-banner.jpg?v=${version}`,
  //         title : "Cricwick - Enjoy live cricket and watch key cricket moments",

  //         product : "Cricwick",

  //         // Globals.ios : 'market://',
  //         ios : 'https://play.google.com/store/apps/details?id=com.Khaleef.cricwick',
  //         android : 'https://play.google.com/store/apps/details?id=com.Khaleef.cricwick',
  //         androidMarket : 'market://details?id=com.Khaleef.cricwick',
  //         pkios : 'https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong',
  //         pkandroid : 'https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong',
  //         pkandroidMarket : 'market://details?id=com.Khaleef.CricWick.TelenorZong',

  //         ogTitle : "Cricwick",
  //         ogDescription : "Enjoy live cricket and watch key cricket moments",
  //         ogImage : "",

  //         loginEnterNumber : 'Please Enter Your Number',
  //         loginPhonePlaceholder : "971xxxxxxxxx",
  //         loginPinPlaceholder : 'XXXXX',

  //         loginEnterPin : 'Please enter the Pin code you received in SMS.',
  //         loginImage : `/images/cricwick/img-download.jpg?v=${version}`,
  //         loginInvalidPhone : `Please enter phone number in following format ${DGlobals.loginPhonePlaceholder} ` ,
  //         loginChooseTelco : "Please Select Your Network Operator",
  //         // Globals.login.chooseTelco : "Please Choose Your Operator",

  //         telco   : "du",
  //         cssFor  : "comviva_du",
  //         name    : "DU (UAE)",
  //         b       : "zain",
  //         regex   : /^9661111111\d{2}$|971\d{9}$|05\d{8}$/,
  //         flag    : '/images/flags/uae.jpg',
  //         consentGatewayRedirection: false,
  //         telcoDisclaimerText:"You will subscribe to Cricwick for 1 AED Daily. If you are already susbcribed it will be just a phone verification.",

  //         cgUrl   :'',

  //         sucessRedirectFromDigital: "https://cricwick.net/?oid=111&q="
  //    }
];

DGlobals.placeholder4x3 = "/images/cricwick/placeholder4x3.jpg";
DGlobals.placeholder16x9 = "/images/cricwick/placeholder16x9.jpg";
DGlobals.placeholderVideo4x3 = "/images/cricwick/placeholderVideo4x3.jpg";
DGlobals.placeholderPlain4x3 = "/images/cricwick/placeholderPlain4x3.jpg";
