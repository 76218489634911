import React, { Component } from "react";
import JWPlayerOnScrollViewResize from "./JWPlayerOnScrollViewResize";
import ReactJWPlayer from "react-jw-player";
import { Helmet } from "react-helmet";
import { canonicalLink } from "./HelperFunctions";

class Live extends Component {
  constructor(props) {
    super(props);
  }

  playerReady(e) {
    if (!this.isMobile) JWPlayerOnScrollViewResize();
  }

  playerError(e) {}

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        <div className="card p-3">
          <h1>DUGOUT Live</h1>
        </div>
        <div className="card p-2">
          <div className="player-container mb-2 w-100">
            <div className="player-position">
              <ReactJWPlayer
                playerId="player"
                playerScript="https://content.jwplatform.com/libraries/Spb3cNmq.js"
                file="https://studio.cricwick.net/mobile/dougout2022/playlist.m3u8"
                onReady={this.playerReady}
                onError={this.playerError}
                isMuted={false}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Live;
