import React, { Component } from "react";
import axios from "axios";
import { getCDNUrl, loginUser } from "./HelperFunctions";
import { Globals } from "./constants";
const queryString = require("query-string");

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.apiPath = Globals.NodeApi + "api/";
  }

  async componentWillMount() {
    let cgObject = JSON.parse(localStorage.getItem("cgObject")),
      redirectTo = "/login",
      logRequest = { cgObject: cgObject, params: this.props.location.search };

    let params = queryString.parse(this.props.location.search);

    if (!cgObject && params.CGOPERATORID === "42202") {
      cgObject = {
        telco: "omantel",
        referer: "/",
      };
    }
    if (!cgObject && params.CGOPERATORID === "42203") {
      cgObject = {
        telco: "ooredoo_oman",
        referer: "/",
      };
    }

    // axios.get(this.apiPath+"log?function=auth_callback&req="+JSON.stringify(logRequest));
    setTimeout(async () => {
      if (cgObject) {
        let phone = "";
        let serverResponse = {};
        if (cgObject.telco === "ooredoo_oman") {
          if (params.Status && params.Status.toLowerCase() === "success") {
            phone = params.MSISDN;

            let generic_response = {};

            generic_response.call_type = "EvinaFlow";
            generic_response.subscribed = 1;
            generic_response.phone = phone;
            generic_response.telco = cgObject.telco;
            localStorage.setItem("user_logged_in", true);
            localStorage.setItem("user", JSON.stringify(generic_response));
            loginUser(generic_response);

            // window.location.href = cgObject.referer;
            // return false;//remove this
            window.location.href = "/";
          } else if (params.Reason && params.Reason.length > 0) {
            console.log(params.Reason.toString());
          }
        }

        if (
          cgObject.telco === "ooredoo_kw" ||
          cgObject.telco === "bbh" ||
          cgObject.telco === "viva_kw" ||
          cgObject.telco === "omantel"
        ) {
          // const parsed = queryString.parse(params);
          if (params.CGMSISDN) {
            phone = params.CGMSISDN;

            if (params.CGSTATUS == 0 || params.CGSTATUS == 5) {
              let generic_response = {};
              generic_response.call_type = "CGStatus";
              generic_response.subscribed = 1;
              generic_response.phone = phone;
              generic_response.telco = cgObject.telco;
              localStorage.setItem("user_logged_in", true);
              localStorage.setItem("user", JSON.stringify(generic_response));
              loginUser(generic_response);

              // window.location.href = cgObject.referer;
              // return false;//remove this
              redirectTo = cgObject.referer;
            } else {
              try {
                const url =
                  this.apiPath +
                  "appstart?telco=" +
                  cgObject.telco +
                  "&phone=" +
                  phone +
                  "&app_name=" +
                  `${
                    localStorage.getItem("domain")
                      ? localStorage.getItem("domain")
                      : Globals.telco
                        ? Globals.appName
                        : Globals.appName
                  }`;
                serverResponse = await axios.get(url, {});
                serverResponse = serverResponse.data;
              } catch (err) {
                console.log("authentication Error logging in", err);
              }

              if (serverResponse) {
                // axios.get(this.apiPath+"log?function=auth_callback_appstart&req="+JSON.stringify(logRequest)+"&res="+JSON.stringify(serverResponse));
                let generic_response = {};
                if (
                  Object.keys(serverResponse).length === 0 &&
                  serverResponse.constructor === Object
                ) {
                } else if (!serverResponse.status) {
                } else {
                  generic_response = serverResponse.generic_response;
                }
                if (generic_response.subscribed) {
                  generic_response.phone = phone;
                  generic_response.telco = cgObject.telco;
                  localStorage.setItem("user_logged_in", true);
                  localStorage.setItem(
                    "user",
                    JSON.stringify(generic_response)
                  );
                  loginUser(generic_response);

                  // window.location.href = cgObject.referer;
                  // return false;//remove this
                  redirectTo = cgObject.referer;
                }
              } else {
                console.log("authentication no server response");
              }
            }
          } else {
            console.log("authentication", "no msisdn");
          }
        } else {
          console.log("authentication invalid telco");
        }
      } else {
        console.log("authentication cgobject not found");
      }

      localStorage.removeItem("cgObject");
      window.location.href = redirectTo;
    }, 5000);
  }

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        <div className="main-loader card min-height-80vh">
          <img
            width="64"
            height="64"
            src={getCDNUrl("/images/loader_v2.gif")}
            alt=""
          />
        </div>
      </section>
    );
  }
}

export default Authentication;
