import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getCDNUrl,
  space2hyphen,
  space2hyphenForSlug,
  VideoCardDescription,
} from "./HelperFunctions";
import axios from "axios";
import BackgroundImage from "react-background-image-loader";
import { Globals } from "./constants";
import { canonicalLink } from "./HelperFunctions";

import { Helmet } from "react-helmet";

class Stories extends Component {
  constructor(props) {
    super(props);

    var loadTime =
      window.performance.timing.domContentLoadedEventEnd -
      window.performance.timing.navigationStart;
    this.state = {
      customCssTransition: "h-transition",
      isLoading: true,
      apiPath: Globals.ApiUrl + "api/",
      page: 1,
      per_page: 10,
      pagesEnd: false,
      articles: [],
      type: "",
      label: "",
      articlesClass: "",
      newsClass: "",
    };

    this.nextPage = this.nextPage.bind(this);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    let type, label;
    if (this.props.type && this.props.type === "stories") {
      type = "stories";
      label = "Stories";
      this.setState({
        type: type,
        label: label,
        articlesClass: "border-bottom border-danger",
        newsClass: "",
      });
    }

    let apiResp,
      articles = [],
      apiPath = this.state.apiPath,
      page = this.state.page,
      per_page = this.state.per_page,
      url =
        this.state.apiPath +
        type +
        "?page=" +
        page.toString() +
        "&per_page=" +
        per_page +
        "&app_name=" +
        `${localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : Globals.appName}`;

    try {
      apiResp = await axios.get(url);
    } catch (err) {
      console.log("Error fetching articles");

      ////// window.location.href = '/404notfound';
    }

    if (apiResp.status == 200) {
      articles = apiResp.data.data;
      this.setState({
        articles: articles,
        customCssTransition: "v-transition",
        isLoading: false,
      });
    } else {
      console.log("Error fetching articles");
    }
  }

  getArticles() {
    return this.state.articles.map((article, i) => {
      return (
        <div className="mt-3">
          <Link
            key={"articles-" + i}
            className=""
            to={
              "/stories/" +
              article.id +
              "/" +
              space2hyphenForSlug(article.title)
            }
          >
            <StoriesCard2
              data={article}
              ateeb={"xyz"}
              type={this.state.label}
            />
          </Link>
        </div>
      );
    });
  }

  async nextPage(e) {
    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName("loading-more-results");
      if (elem.length) elem[0].classList.remove("d-none");

      let resp,
        articles = this.state.articles,
        apiPath = this.state.apiPath,
        page = this.state.page + 1,
        per_page = this.state.per_page,
        type = this.state.type,
        url =
          apiPath +
          type +
          "?page=" +
          page.toString() +
          "&per_page=" +
          per_page +
          "&app_name=" +
          `${localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : Globals.appName}`;

      resp = [];
      try {
        resp = await axios.get(url);
      } catch (err) {
        console.log("error fetching next page");
      }

      if (resp.status === 200) {
        if (elem.length) elem[0].classList.add("d-none");

        if (resp.data && resp.data.data.length > 0) {
          const Articles = this.state.articles.slice();
          resp.data.data.forEach((obj, i) => {
            Articles.push(obj);
          });
          this.setState({ page: page, articles: Articles });
        } else if (resp.data.data.length === 0) {
          this.setState({ pagesEnd: true });
        }
      } else {
        if (elem.length) elem[0].classList.add("d-none");
      }
    }
  }

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        <Helmet>
          <title>
            Cricket Teams and Player Stories with Gossip, Humour and all that
            Spice - {Globals.product}
          </title>
          <meta
            name="description"
            content={
              "Cricket Teams and Player Stories, Humor and Spice by Cricket Fanatics from all over the World Only On " +
              Globals.product
            }
          />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>

        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src={getCDNUrl("/images/loader_v2.gif")} alt="" />
          </div>
        ) : (
          <div />
        )}

        <div className={this.state.customCssTransition}>
          <div className="card p-3">
            <div className="d-flex overflow-x-auto ">
              <h1
                className={
                  this.state.newsClass + " font-weight-semi-bold p-2"
                }
              >
                Stories
              </h1>
            </div>
          </div>

          <div className="card mt-2 p-3 stories-list-card">
            <div className="pt-3">
              {this.state.articles && this.state.articles.length > 0 ? (
                this.getArticles()
              ) : (
                <div />
              )}
              {/* <StoriesCard image={this.state.newsImage}></StoriesCard> */}
              {/* <StoriesCard image={this.state.newsImage2}></StoriesCard>
	          	<StoriesCard image={this.state.videosImage}></StoriesCard> */}
              {/* <div className="row p-3">
		          	<StoriesFullWidthCardSm image={this.state.videosImage2}></StoriesFullWidthCardSm>
		          	<StoriesFullWidthCardSm image={this.state.videosImage3}></StoriesFullWidthCardSm>
	          	</div>
	          	<div className="row">

	          		<StoriesFullWidthCard image={this.state.newsImage}></StoriesFullWidthCard>
          		</div> */}
            </div>
          </div>
          {!this.state.pagesEnd && this.state.articles.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img src={getCDNUrl("/images/loader_v2.gif?v=2")} alt="" height="30" />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </section>
    );
  }
}

const StoriesFullWidthCard = (props) => (
  <div className="col-12">
    <div className="w-100 position-relative">
      <div
        className="sixteen-by-nine bg-center-cover"
        style={props.large_image}
      />
      <div className="fa fa-play play-btn-lg" />
    </div>
    <div className="py-2">
      <h2 className="font-weight-normal text-red">Match Reports</h2>
      <h2 className="font-weight-normal text-secondary">
        Pakistan vs Australia in UAE
      </h2>
      <h3 className="text-black">
        A case for Pakistan to play all three openers in UAE?
      </h3>
    </div>
  </div>
);

const StoriesFullWidthCardSm = (props) => (
  <div className="col-6 p-1">
    <div className="w-100 position-relative">
      <div
        className="sixteen-by-nine bg-center-cover"
        style={props.large_image}
      />
      <div className="fa fa-play play-btn-lg play-btn-sm" />
    </div>
    <div className="py-2">
      <h2 className="font-weight-normal text-red">Match Reports</h2>
      <h2 className="font-weight-normal text-secondary">
        Pakistan vs Australia in UAE
      </h2>
      <h2 className="font-weight-normal text-black">
        A case for Pakistan to play all three openers in UAE?
      </h2>
    </div>
  </div>
);

const getSummary = (props) => {
  let summary;
  if (props.data.summary) {
    summary = props.data.summary;
  } else if (props.data.body) {
    summary = stripTags(props.data.body);
  }
  return summary;
};

const getBackgroundImg = (props) => {
  let url = props.data.thumbnail || "";
  if (
    url == null ||
    url == undefined ||
    url == "" ||
    url.indexOf("/missing.png") !== -1
  )
    url = props.data.image;
  return url;
};

const stripTags = (string) => {
  var StrippedString = string.replace(/(<([^>]+)>)/gi, "");
  return StrippedString;
};

export const StoriesCard = (props) => (
  <div className="d-flex my-2">
    <div className="w-30">
      <BackgroundImage
        className="four-by-three bg-center-cover"
        src={getBackgroundImg(props)}
        placeholder={Globals.placeholder4x3}
      />
    </div>
    <div className="pl-3 flex-fill">
      <VideoCardDescription
        showDescription={true}
        showDate={true}
        showRedTitle={props.type}
        data={props.data}
        title={props.data.title}
        description={getSummary(props)}
      />
    </div>
  </div>
);

export const StoriesCard2 = (props) => (
  <div className="d-flex my-2">
    <div className="w-30">
      <BackgroundImage
        className="four-by-three bg-center-cover"
        src={getBackgroundImg(props)}
        placeholder={Globals.placeholder4x3}
      />
    </div>
    <div className="pl-3 flex-fill">
      <VideoCardDescription
        showDescription={true}
        showDate={true}
        showRedTitle={true}
        data={props.data}
        title={props.data.title}
        description={getSummary(props)}
      />
    </div>
  </div>
);

export default Stories;
