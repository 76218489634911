import React, { Component } from "react";
import Loading from "./common/loading";
import { Globals } from "../constants";
import { Link } from "react-router-dom";
import axios from "axios";
import { space2hyphen, space2hyphenForSlug } from "../HelperFunctions";

class LeftUpdatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   seriesApiPath: Globals.ApiUrl + 'api/v2/series/featured',
      //   seriesApiResp: [],
      updateApiPath: Globals.ApiUrl + "api/news?page=1&per_page=6",
      updateApiResp: [],

      error: null, //by Tariq
      isLoading: true, //by Tariq
      isLoaded: false, //by Tariq
      counter: 0, //by Tariq

      //   phone: '',
      //   pin: '',
      //   inputName: 'phone',
      //   inputValue: '',
      //   phonePlaceholder: Globals.login.phonePlaceholder,
      //   pinSent: false,
      //   pinPlaceholder: Globals.login.pinPlaceholder,
      //   task: 'phone',
      //   user: [],
      //   redirect: false,
      //   btnTxt: 'Continue',
      //   btnDisbaled: false,
      // apiPath: 'https://mobisub.cricwick.mobi/main/',
      //   message: '',
      //   heading: Globals.login.enterNumber,
      //   enterPin: Globals.login.enterPin,
      // heading: 'Please Enter Your Jazz Number. Prepaid users will be charged 2.38 per day.',
      //   strings: {
      //     somethingWentWrong: 'Something went wrong. Please try again later!',
      //     invalidPin: 'Invalid Pincode!',
      //     invalidPhone: Globals.login.invalidPhone,
      //   },
    };
  }

  async componentDidMount() {
    await this.getSeriesRecords();
    if (this.state.error) {
      await this.knockingNetWorking();
    }
  }

  //========================New Method By Tariq =================================
  getSeriesRecords = async () => {
    let updatesUrl = this.state.updateApiPath,
      updatesResp;
    try {
      updatesResp = await axios.get(updatesUrl);
      const { data } = await updatesResp.data;
      this.setState(() => {
        return {
          updateApiResp: data, //updatesResp.data.data,
          error: null,
          isLoading: false,
          isLoaded: true,
          error: null,
        };
      });
    } catch (error) {
      console.log("error fetching series");
      this.setState(() => {
        return { error: error, isLoaded: false };
      });
    }
  };

  //========================================================================

  knockingNetWorking = async () => {
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        this.getSeriesRecords();
      }, 15000);
      if (this.state.isLoaded) return;
    }
  };

  //==================================End========================================
  render() {
    const { updateApiResp, isLoading, error } = this.state;
    if (isLoading) {
      return (
        <div className="latest-updates card px-3 py-3 mt-3">
          <h2 className="pb-3">Latest Updates</h2>
          <Loading />
        </div>
      );
    } else if (error) {
      return (
        <div className="latest-updates card px-3 py-3 mt-3">
          <h2 className="pb-3">Latest Updates</h2>
          <Loading />
        </div>
      );
    } else {
      return (
        <div className="latest-updates card px-3 py-3 mt-3">
          <p
            className="pb-3 mb-0"
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
          >
            Latest Updates
          </p>
          <ul style={{ paddingLeft: 0 + "px" }}>
            {updateApiResp.map((update, i) => (
              <div key={"lu-" + i} className="card-body p-0 pb-4 mb-4">
                {/*<a href={"/stories/news/"+update.id}>{update.title}</a>*/}
                <Link
                  to={
                    "/news/" +
                    update.id +
                    "/" +
                    space2hyphenForSlug(update.title)
                  }
                >
                  {" "}
                  {update.title}{" "}
                </Link>
              </div>
            ))}
          </ul>
        </div>
      );
    }
  }
}

export default LeftUpdatesList;
