import React, { Component } from "react";

import "./trivia.css";
import "../App.css";
import { Globals } from "../constants";
import { Form, FormGroup, Input, FormText, ModalFooter } from "reactstrap";
import { Modal, ModalHeader } from "reactstrap";

import InputMask from "react-input-mask";
import { getCDNUrl } from "../HelperFunctions";
class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.msisdn =
      this.decode(this.props.msisdn).length > 0
        ? this.decode(this.props.msisdn)
        : "";
    this.telco = this.decode(this.props.telco);
    this.token = localStorage.getItem("x-access-token")
      ? localStorage.getItem("x-access-token")
      : "";
    this.xAccessToken = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).xAccessToken
      : "";
    this.msisdnUser = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user")).phone
      : "";

    this.state = {
      name: "",
      cnic: "",
      cnicIssueDate: new Date(),
      amount: 0,
      showSuccessMessage: false,
      amountInWallet: this.props.walletCash,
      showAmountErrorMessage: true,
      showAmountExceedErrorMessage: false,
      showNameErrorMessage: false,
      showCNICErrorMessage: false,
      shouldFormBeDisabled: true,
      isLoading: true,
      isTransactionMade: false,
      errorMessage: "",
    };

    this.loginAPIPath = Globals.NodeApi + "api/";

    this.amountErrorMessage = "";
    this.amountExceedErrorMessage =
      "Payout amount can not be greater than the Wallet Cash amount";
    this.nameErrorMessage = "Please enter your name";
    this.cnicErrorMessage = "Please enter a valid CNIC number";

    this.cnicRegex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;
    this.msisdnValidationRegex = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;

    this.onClickSave = this.onClickSave.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.isWebView = window.location.pathname.includes("payment-methods")
      ? true
      : false;
  }

  async componentDidMount() {
    if (this.msisdn && this.validatePhone(this.msisdn)) {
      if (!this.token) {
        this.getUserCNICDetails();
      } else {
        this.getUserCNICDetails();
      }
    }
  }

  decode(str) {
    return str && str.length > 0 ? atob(str) : "";
  }

  validatePhone(phoneNumber) {
    if (!phoneNumber) {
      return false;
    } else if (!this.msisdnValidationRegex.test(phoneNumber)) {
      return false;
    } else {
      return true;
    }
  }

  async getUserCNICDetails() {
    let user = JSON.parse(localStorage.getItem("user"));

    this.setState({
      name: user.name ? user.name : "",
      cnic: user.cnic ? user.cnic : "",
      cnicIssueDate: user.cnicIssueDate ? user.cnicIssueDate : "",
      isLoading: false,
    });
  }

  handleAmountErrors(value) {
    if (this.isAmountValid(value)) {
      this.setState({
        showAmountErrorMessage: false,
        showAmountExceedErrorMessage: false,
      });
    } else {
      if (value > this.props.walletCash) {
        this.setState({ showAmountExceedErrorMessage: true });
      }
      this.setState({ showAmountErrorMessage: true });
    }
  }

  handleInputChange(name, value) {
    if (name === "amount") {
      this.handleAmountErrors(value);
    }

    this.setState({ [name]: value }, () => {
      this.validateForm();
    });
  }

  showModal() {
    return (
      <SuccessModal
        showModal={this.state.showSuccessMessage}
        toggleModal={this.toggleModal}
      />
    );
  }

  toggleModal() {
    this.setState({ showSuccessMessage: false });
    if (!this.isWebView) {
      this.backPressed();
    }
  }

  backPressed() {
    this.props.backPressed(this.state.isTransactionMade);
  }

  isAmountValid(amount) {
    if (parseInt(amount) <= 0 || amount === "") {
      return false;
    } else if (
      parseInt(amount) >= parseInt(this.props.minimumPayout) &&
      parseInt(amount) <= parseInt(this.props.walletCash)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isCNICValid() {
    let test = this.cnicRegex.test(this.state.cnic);
    if (!test) {
      this.setState({ showCNICErrorMessage: true });
    } else {
      this.setState({ showCNICErrorMessage: false });
    }
    return test;
  }

  validateForm() {
    if (
      this.state.name.length > 0 &&
      this.isCNICValid() === true &&
      this.state.cnicIssueDate.length > 0 &&
      this.isAmountValid(this.state.amount)
    ) {
      this.setState({ shouldFormBeDisabled: false });
    } else {
      this.setState({ shouldFormBeDisabled: true });
    }
  }

  updateProfile = async () => {
    let token = this.xAccessToken;

    let requestOptions = {
      method: "POST",
      headers: {
        "x-access-token": token,
        "Content-type": "application/json; charset=UTF-8",
        msisdn: this.msisdnUser,
        app: localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : ""
      },
      body: JSON.stringify({
        name: this.state.name,
        cnic: this.state.cnic,
        cnicIssueDate: this.state.cnicIssueDate,
      }),
    };

    let respUpdateUserProfile = await fetch(
      `${Globals.UserManagment}api/v1/users/update-user-profile`,
      requestOptions
    );
    let responseUpdateUserProfile = await respUpdateUserProfile.json();
    if (responseUpdateUserProfile && responseUpdateUserProfile.status === 1) {
      this.afterSaveClicked();
    }
  };

  afterSaveClicked = () => {
    this.setState(
      {
        showSuccessMessage: true,
        isTransactionMade: true,
      },
      () => this.props.onPayoutClicked(0)
    );
  };

  async onClickSave() {
    this.setState({
      shouldFormBeDisabled: true,
    });

    let token = this.xAccessToken;
    //
    if (!token) {
      //get token
    }

    let requestOptions = {
      method: "POST",
      headers: {
        "x-access-token": token,
        "Content-type": "application/json; charset=UTF-8",
        msisdn: this.msisdnUser,
        platform: `web`,
        app: localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : ""
      },
      body: JSON.stringify({
        amount: parseInt(this.state.amount),
      }),
    };

    var resp = await fetch(
      `${Globals.UserManagment}api/v1/transactions/withdraw`,
      requestOptions
    );
    let response = await resp.json();
    if (response && response.status === 1) {
      let user = JSON.parse(localStorage.getItem("user"));
      if (
        user.name !== this.state.name ||
        user.cnic !== this.state.cnic ||
        user.cnicIssueDate !== this.state.cnicIssueDate
      )
        this.updateProfile();
      else this.afterSaveClicked();
    } else if (response && response.status === 0)
      this.setState({
        errorMessage: response.message,
      });
  }

  render() {
    return (
      <div id="main-section">
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src={getCDNUrl("/images/loader_v2.gif")} alt="" />
          </div>
        ) : (
          <div />
        )}
        {this.state.showSuccessMessage && this.showModal()}
        {!this.state.isLoading && (
          <div className="register-login card p-5 pb-5 px-lg-5 py-lg-4">
            <span>
              {!this.isWebView && (
                <span
                  onClick={() => this.backPressed()}
                  className="mt-2 mx-3"
                  style={{ cursor: "pointer" }}
                >
                  <img src={getCDNUrl("/images/trivia/back-btn.png")} width="16px" alt="" />
                </span>
              )}
              <span
                style={{
                  textAlign: "center",
                  marginBottom: "5px",
                  fontSize: "1.6rem",
                  lineHeight: "1.2 !important",
                  fontWeight: "600",
                }}
              >
                Please Enter Your Details to Complete Payout Process
              </span>
            </span>
            <Form className="p-5 pb-5 px-lg-5 py-lg-4">
              <FormGroup>
                <FormText
                  color="#b91414"
                  style={{ fontSize: "14px", fontWeight: "500" }}
                >
                  Amount in Wallet:
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#b91414",
                    }}
                  >
                    {" "}
                    {this.props.walletCash}
                  </span>
                </FormText>
              </FormGroup>
              <FormGroup>
                <div className="my-2" style={{ fontSize: "16px" }}>
                  Full Name (As per CNIC)
                </div>
                <Input
                  className="payout-input"
                  type="text"
                  value={this.state.name}
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  onChange={(e) =>
                    this.handleInputChange("name", e.target.value)
                  }
                />
                {this.state.name &&
                  this.state.name.length === 0 && (
                    <FormText color="#b91414">{this.nameErrorMessage}</FormText>
                  )}
              </FormGroup>
              <FormGroup>
                <div className="my-2" style={{ fontSize: "16px" }}>
                  CNIC Number
                </div>
                <InputMask
                  className="payout-input"
                  mask="99999-9999999-9"
                  value={this.state.cnic}
                  name="cnic"
                  id="cnic"
                  placeholder="Enter your CNIC number"
                  onChange={(e) =>
                    this.handleInputChange("cnic", e.target.value)
                  }
                />
                {this.state.showCNICErrorMessage && (
                  <FormText color="#b91414">{this.cnicErrorMessage}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <div className="my-2" style={{ fontSize: "16px" }}>
                  CNIC Issuance Date
                </div>
                <br />
                <input
                  type="date"
                  value={this.state.cnicIssueDate}
                  data-date-inline-picker="true"
                  onChange={(e) =>
                    this.handleInputChange("cnicIssueDate", e.target.value)
                  }
                  className="payout-input"
                  id="cnicIssueDatePicker"
                />
              </FormGroup>
              <FormGroup>
                <div className="my-2" style={{ fontSize: "16px" }}>
                  Withdrawal Amount
                </div>
                <Input
                  className="payout-input"
                  min="1000"
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder={`Amount Should Be Greater Than ${
                    this.props.minimumPayout
                  }`}
                  onChange={(e) =>
                    this.handleInputChange("amount", e.target.value)
                  }
                />
                {this.state.showAmountErrorMessage && (
                  <p className={`text-danger`}>{this.amountErrorMessage}</p>
                )}
                {this.state.showAmountExceedErrorMessage && (
                  <p className={`text-danger`}>
                    {this.amountExceedErrorMessage}
                  </p>
                )}
                {this.state.errorMessage && (
                  <p className={`text-danger`}>{this.state.errorMessage}</p>
                )}
              </FormGroup>
              <br />
              <button
                type="button"
                onClick={this.onClickSave}
                disabled={this.state.shouldFormBeDisabled}
                className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-5 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
              >
                Save
              </button>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

const SuccessModal = (props) => {
  const showModal = props;
  return (
    <Modal isOpen={showModal} backdrop={true} keyboard={true} centered={true}>
      <ModalHeader>
        <h2>Payout Success!</h2>
        <p>
          Dear User, we have received your request. Your cash prize will be
          transferred to you in 7 to 10 working days through easypaisa. Keep
          playing & winning.
        </p>
      </ModalHeader>
      <ModalFooter>
        {
          <div
            onClick={props.toggleModal}
            style={{
              color: "#F9F9FB",
              lineHeight: "42px",
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
            }}
            className="wallet-button my-2"
          >
            Close
          </div>
        }
      </ModalFooter>
    </Modal>
  );
};

export default PaymentMethods;
