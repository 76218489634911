import React from "react";

const Loading = () => (
  <div style={{ textAlign: "center" }}>
    <img
      style={{ height: 20 + "px", width: 80 + "px" }}
      src={require("../../images/loadingSide.gif")}
      alt="......."
    />
  </div>
);

export default Loading;
