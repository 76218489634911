import React from "react";
import { Helmet } from "react-helmet";
import { canonicalLink } from "./HelperFunctions";

class TelenorFaqs extends React.Component {
  render() {
    return (
      <div className="card" style={{ minHeight: "600px" }}>
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        <div style={{ fontSize: "14px" }} className="px-3 mt-3">
          <br />
          <div className="text-left">
            <b>1. What are the Service Charges?</b>
            <p>Telenor users will be charged with PKR 2.5 + 19.5% Tax Daily</p>

            <br />
            <b>2. If I uninstall the application. Will the charging stop?</b>
            <p>
              No, charging will not stop untill you unsubscribe the service.
            </p>

            <br />
            <b>3. How do I unsubscribe?</b>
            <p>
              You can unsubscribe the service by opting an unsubscribe option
              available in the app menu. <br />
              Or you can call the customer service center and ask to unsubscribe
              Cricwick.
              <br />
              Or <a href="https://cricwick.net/tp/unsub">click</a> here to
              unsubscribe.{" "}
            </p>

            <br />
            <b>4. Videos keep on buffering</b>
            <p>
              You must check your internet connectivity or if it is connected
              you should check the internet speed.
            </p>

            <br />
            <b>5. Live Streaming not available.</b>
            <p>Cricwick offer live stream for selected matches only.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default TelenorFaqs;
