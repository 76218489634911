import Axios from "axios";
import React, { Component } from "react";

import { Globals } from "../constants";

class EasyPaisaBundles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      bundleList: [],
      selectedBundle: {},
    };

    this.apiPath = Globals.NodeApi + "api/";
  }
  componentDidMount() {
    this.getEasyPaisaBundles();
  }

  onBundleSelected(selectedObject) {
    this.setState({
      selectedBundle: selectedObject,
    });

    this.props.onBundleSelected(selectedObject);
  }

  async getEasyPaisaBundles() {
    let apiURL = this.apiPath + "get_bundles_by_telco?telco=easypaisa";

    let apiResponse = await Axios.get(apiURL);
    if (apiResponse.status === 200) {
      if (
        apiResponse.data &&
        apiResponse.data.remote_response &&
        apiResponse.data.remote_response.bundles &&
        apiResponse.data.remote_response.bundles.length > 0
      ) {
        this.setState({
          bundleList: apiResponse.data.remote_response.bundles,
          isLoading: false,
        });
      }
    }
  }

  renderBundles() {
    return this.state.bundleList.map((bundle, i) => {
      return (
        <>
          {/* {(bundle.id === this.state.selectedBundle.id) && 
                        <i className="fa fa-check-circle pull-right" 
                                        style={{color: '#b91414', marginTop: '3.5%', marginRight: '3.5%'}}>
                        </i>} */}
          <div
            key={i}
            className={
              bundle.id === this.state.selectedBundle.id ? "mt-5" : "mt-5"
            }
            id="bundle-box"
            onClick={() => this.onBundleSelected(bundle)}
            style={{
              border:
                bundle.id === this.state.selectedBundle.id
                  ? "#b91414 1px solid"
                  : "",
            }}
          >
            <div className="d-flex overflow-x-aut0 col-12">
              <span className="col-6 bundle-text mt-2">{bundle.title}</span>
              <span className="col-4 bundle-price mt-2">
                <span
                  className="price-line-through"
                  style={{ fontWeight: "normal", fontSize: "18px" }}
                >
                  {i === 0 ? 16.66 : 71.6}
                </span>{" "}
                {bundle.price_point}
              </span>
              <span
                className="ml-1 mt-2"
                style={{
                  fontSize: "12px",
                  paddingTop: "19px",
                  paddingLeft: "0px !important",
                }}
              >
                PKR + Tax
              </span>
              <span style={{ fontSize: "10px", paddingTop: "22px" }}>*</span>
            </div>
            {bundle.offer_text &&
              bundle.offer_text.length > 0 && (
                <p className="bundle-subtext">{bundle.offer_text}</p>
              )}
          </div>
        </>
      );
    });
  }

  render() {
    return (
      <>
        <h1>Select Plans:</h1>
        {this.renderBundles()}
        <div className="mb-5" />
      </>
    );
  }
}

export default EasyPaisaBundles;
