import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Globals } from "../constants";
import { getCDNUrl, getUnsubPath, getRegionFlags } from "../HelperFunctions";
import Axios from "axios";
import ConfirmUnsub from "../newLoginFlow/confirmUnsub";
import EditUser from "../newLoginFlow/editUser";
import "../css/cricwick.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import wallet from "../images/wallet.png";
import arrow from "../images/arrow.png";
import edit from "../images/edit.png";
import userPic from "../images/default-UI.png";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsubBtnTxt: "Unsubscribe",
      unsubBtnDisabled: false,
      unsubPath: "",
      unsubPopUp: false,
      editPopUp: false,
      userData: null,
      regionFlags: getRegionFlags(localStorage.getItem("countryCode")),
    };

    this.unsub = this.unsub.bind(this);
    const User = localStorage.getItem("user");
    this.parseData = JSON.parse(User);
  }

  componentDidMount() {
    this.getUserProfile();
    if (this.state.userData) {
      console.log("userData is: " + this.state.userData.user.avatarUrl);
    }
  }

  authHeader() {
    let token;
    let msisdn;
    let user = JSON.parse(localStorage.getItem("user"));
    token = user.xAccessToken;
    msisdn = user.phone;

    return {
      "x-access-token": token,
      msisdn: msisdn,
      app: localStorage.getItem("domain")
        ? localStorage.getItem("domain")
        : Globals.telco
          ? Globals.appName
          : "",
    };
  }

  getUserProfile = async () => {
    const response = await Axios.get(
      `${Globals.UserManagment}api/v1/users/get-user-profile`,
      {
        headers: this.authHeader(),
      }
    );
    if (response.data.status === 1 && response.data.data) {
      let userData = response.data.data;
      this.setState({
        userData: userData,
      });
    }
    //return userData;
  };

  logout(e) {
    localStorage.removeItem("user");
    localStorage.removeItem("user_logged_in");
    localStorage.removeItem("isSocialProfileAttached");
    localStorage.removeItem("countryCode");
    //window.location.reload();
    window.location.href = "/";
    // this.props.history.push(`/`)
  }

  async unsub(e) {
    let url = e.target.getAttribute("unsubpath");
    this.setState(
      { unsubBtnTxt: "Please wait ...", unsubBtnDisabled: true },
      async () => {
        let resp = {},
          user = JSON.parse(localStorage.getItem("user"));
        try {
          resp = await Axios.get(url);
          resp = await Axios.get(
            `${Globals.UserManagment}api/v1/users/unsub-user`,
            {
              headers: {
                "x-access-token": user.xAccessToken,
                msisdn: user.phone,
                app: localStorage.getItem("domain")
                  ? localStorage.getItem("domain")
                  : Globals.telco
                    ? Globals.appName
                    : "",
              },
            }
          );
        } catch {
          console.log("tahir unsub failed");
        }

        this.logout();
      }
    );
  }

  render() {
    let unsubPathObj1;

    return (
      <div
        //className="modal fade"
        //id="welcomeUserModal"
        //tabIndex="-1"
        //role="dialog"
        //aria-labelledby="exampleModalLabel"
        //aria-hidden="true"
        id="main-section"
      >
        <div
        //className="modal-dialog"
        //role="document"
        //style={{ marginTop: "150px" }}
        >
          <div className="">
            {" "}
            {/*modal-content login-dialogue*/}
            <div
              className="modal-body p-0 pb-2"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="">
                <div className="divide-content" style={{ minHeight: "auto" }}>
                  <div
                    className="text-center"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      zIndex: "1",
                      paddingBottom: "10px",
                    }}
                  >
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        zIndex: "1",
                        paddingBottom: "10px",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center Redbg p-3">
                        <p className="text-white" style={{ fontSize: "20px" }}>
                          Account Details
                        </p>
                        {/* {localStorage.getItem("countryCode") == "sa" || localStorage.getItem("countryCode") == "SA" || localStorage.getItem("domain") == "CricwickMena" ? ( */}
                        {!this.state.regionFlags.editEnabledInProfile ? null : (
                          <img
                            className=""
                            width={19}
                            height={19}
                            src={edit}
                            alt=""
                            onClick={() =>
                              this.setState({
                                editPopUp: true,
                              })
                            }
                          />
                        )}
                      </div>

                      <div className="inner-text-login text-left Redbg pt-0">
                        <row className="d-flex pb-3">
                          <img
                            className="rounded-circle"
                            width={60}
                            height={60}
                            src={
                              this.state.userData &&
                              this.state.userData.user &&
                              this.state.userData.user.avatarUrl
                                ? this.state.userData.user.avatarUrl
                                : "https://asset.cricvids.co/cricwick-assets/images/default-UI.png"
                            }
                            alt={""}
                          />
                          <div className="column ml-3 justify-content-center">
                            <span className="status text-white">
                              {this.state.userData
                                ? this.state.userData.user.name
                                : null}
                            </span>
                            <span className="msisdn text-white">
                              {this.state.userData
                                ? this.state.userData.user.msisdn
                                : null}
                            </span>
                          </div>
                        </row>
                      </div>
                      <EditUser
                        open={this.state.editPopUp}
                        changeOpen={(open) =>
                          this.setState({
                            editPopUp: open,
                          })
                        }
                        //editType={this.state.userData}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-left user-profile">
                    {Globals.telco != "mobilink" &&
                    this.state.userData &&
                    this.state.userData.wallet.availableBalance ? (
                      <div className="Greenbg ml-3 mr-3">
                        <div className="user-wallet text-left p-3">
                          <p className="status mb-0 d-flex text-center align-items-center">
                            Wallet
                          </p>
                          <Link to="/wallet">
                            <span
                              className="status justify-content-center align-items-center"
                              style={{ fontSize: "24px", color: "#136F2F" }}
                            >
                              <img
                                className="pr-1"
                                src={wallet}
                                height={24}
                                width={24}
                              />
                              {this.state.userData
                                ? this.state.userData.wallet.availableBalance
                                : 0}
                              <span className="m-f-14 pl-1">PKR</span>

                              <i
                                className="fa fa-angle-right float-right mt-2 pl-2"
                                aria-hidden="true"
                              />
                            </span>
                          </Link>
                        </div>
                      </div>
                    ) : null}

                    <span className="status text-left p-3 pt-4">
                      Subscription Details:{" "}
                    </span>

                    <div className="user text-left pr-3 pl-3">
                      <p>Status</p>
                      <span
                        className="Greenbg text-center pr-2 pl-2 m-f-14"
                        style={{
                          color: "#136F2F",
                          borderRadius: "24px",
                          height: "fit-content",
                        }}
                      >
                        {this.parseData
                          ? this.parseData.subscribed === 1
                            ? "Active"
                            : "Not Active"
                          : "Not Active"}
                      </span>
                    </div>
                    {/* {localStorage.getItem("countryCode") == "sa" || localStorage.getItem("countryCode") == "SA" || localStorage.getItem("domain") == "CricwickMena" ? ( */}
                    {!this.state.regionFlags.editEnabledInProfile ? null : (
                      <>
                        <div className="user text-left pr-3 pl-3">
                          <p>Type</p>
                          <span className="status m-f-14">
                            {this.state.userData
                              ? this.state.userData.user.subscriptionType === 0
                                ? "Daily"
                                : this.state.userData.user.subscriptionType ===
                                  1
                                  ? "Weekly"
                                  : this.state.userData.user
                                      .subscriptionType === 2
                                    ? "Monthly"
                                    : this.state.userData.user
                                        .subscriptionType === 3
                                      ? "Biweekly"
                                      : this.state.userData.user
                                          .subscriptionType === 4
                                        ? "3 months"
                                        : this.state.userData.user
                                            .subscriptionType === 5
                                          ? "6 months"
                                          : this.state.userData.user
                                              .subscriptionType === 6
                                            ? "1 year"
                                            : "undefined"
                              : "undefined"}
                          </span>
                        </div>

                        <div className="user text-left pr-3 pl-3">
                          <p>Terminate sub</p>
                          <span
                            className="status m-f-14"
                            style={{ color: "#E15858" }}
                            onClick={
                              this.parseData
                                ? this.parseData.subscribed === 1
                                  ? ((unsubPathObj1 = getUnsubPath(
                                      this.parseData
                                    )),
                                    unsubPathObj1.status === 1 &&
                                    unsubPathObj1.path
                                      ? this.unsub
                                      : null)
                                  : null
                                : null
                            }
                          >
                            Unsubscribe
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    className="logout btn font-weight-bold m-f-14 mb-3 mt-5"
                    onClick={this.logout}
                  >
                    {`LOGOUT`}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserProfile);
