import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { StoriesCard } from "./Stories";
import ImageLoader from "react-load-image";
import { Helmet } from "react-helmet";
import { Globals } from "./constants";
import { space2hyphen, updatedCanonical, checkIfOlderThanTwoMonths } from "./HelperFunctions";
import { getCDNUrl } from "./HelperFunctions";

class StoriesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customCssTransition: "h-transition",
      isLoading: true,
      apiPath: Globals.ApiUrl + "api/",
      apiResp: [],
      articles: [],
      label: "",
      page: 1,
      per_page: 6,
      type: "",
      id: "",
      articlesClass: "",
      newsClass: "",
      inviteCode: this.props.match.match.params.code,
      shareOn: "image",
    };
    this.getDataFromApi = this.getDataFromApi.bind(this);
  }

  LogActivity() {
    this.LogUserActivity();
  }

  async LogUserActivity() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        inviteCode: this.state.inviteCode,
        activity: "share",
        contentType: "article",
        contentId: this.props.match.match.params.id,
      }),
    };
  }

  async componentDidMount() {
    this.getDataFromApi(this.props.match.match.params.id);

    this.myElement.addEventListener("DOMSubtreeModified", () => {
      window.twttr.widgets.load(document.getElementById("tweetss"));
    });
  }

  componentWillUnmount() {
    this.myElement.removeEventListener("DOMSubtreeModified", null);
  }

  async getDataFromApi(contentId) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let type = this.props.type,
      id = contentId,
      url = this.state.apiPath + type + "/" + id,
      articlesUrl =
        this.state.apiPath +
        type +
        "?page=1&per_page=4&app_name=" +
        `${localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : Globals.appName}`,
      articles = [],
      apiResp,
      label,
      resp;

    resp = [];

    if (type == "articles") {
      label = "Article";
      this.setState({
        articlesClass: "border-bottom border-danger",
        newsClass: "",
        label: label,
      });
    } else if (type === "stories") {
      label = "Stories";
      this.setState({
        newsClass: "border-bottom border-danger",
        articlesClass: "",
        label: label,
      });
    } else {
      label = "News";
      this.setState({
        newsClass: "border-bottom border-danger",
        articlesClass: "",
        label: label,
      });
    }

    try {
      resp = await axios.get(url);
    } catch (error) {
      console.log("error fetching " + type);
    }

    if (resp.status === 200) {
      if ((resp.data.body) && (resp.data.body.length > 0)) {
        if (this.state.inviteCode && this.state.inviteCode.length > 0) {
          this.LogActivity();
        }
      }
      else {
        window.location.href = "/404notfound";
      }
      this.setState(
        {
          apiResp: resp.data,
          type: type,
          id: id,
          customCssTransition: "v-transition",
          isLoading: false,
        }
        // () => console.log("Stories set state", this.state)
      );
    } else {
      console.log("error fetching " + type + "!");
    }

    try {
      apiResp = await axios.get(articlesUrl);
    } catch (err) {
      console.log("Error fetching articles", err);
    }

    if (apiResp.status === 200) {
      articles = apiResp.data.data;

      this.setState({ articles: articles });
    } else {
      console.log("Error fetching articles");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.match.params.id !== nextProps.match.match.params.id) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.getDataFromApi(nextProps.match.match.params.id);
    }
  }

  getArticles() {
    return this.state.articles.map((article, i) => {
      return (
        <Link
          // key={"articles-" + i}
          key={article.title}
          className=""
          to={
            "/" +
            this.state.type +
            "/" +
            article.id +
            "/" +
            space2hyphen(article.title)
          }
        >
          <StoriesCard
            data={article}
            image={this.state.newsImage}
            type={this.state.label}
          />
        </Link>
      );
    });
  }

  getShortString(apiResp) {
    if (apiResp.summary) {
      let str = apiResp.summary;
      return str;
    } else {
      let str = apiResp.body ? apiResp.body : "";
      return str;
    }
  }

  removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  getImageForFacebookShare(apiResp) {
    let imageFB =
      apiResp[
        this.state.shareOn === "image"
          ? `${this.state.shareOn}`
          : `${this.state.shareOn}_image_url`
      ];
    if (
      imageFB == null ||
      imageFB == undefined ||
      imageFB == "" ||
      imageFB.indexOf("/missing.png") !== -1
    )
      imageFB = apiResp.image;

    if (this.state.type === "stories") {
      imageFB = apiResp.thumbnail;
    }
    return imageFB;
  }

  render() {
    var title = this.state.apiResp.title;
    //var description = this.state.apiResp.body
    //var image = this.state.apiResp.image
    //const {news_title,news_image,news_detail} = this.props;
    let schema = {
      "@context": "https://schema.org",
      "@type": "Article",
      headline: `${this.state.apiResp.title}`,
      mainEntityOfPage: `${window.location.href}-cricwicknet`,
      image: [this.state.apiResp.image, this.state.apiResp.full_image],
      datePublished: this.state.apiResp.created_at,
      // dateModified: "2015-02-05T09:20:00+08:00",
      author: [
        {
          "@type": "Person",
          name: this.state.apiResp.by,
          url: window.location.href,
          contentUrl: window.location.href,
        },
      ],
      articleBody: this.state.apiResp.body,
    };

    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        <Helmet>
          <title>{this.state.apiResp.title}</title>
        </Helmet>
        <Helmet>
          <meta property="og:url" content={"Cricket!"} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={this.state.apiResp.title} />
          <meta
            property="og:description"
            content={this.removeHTML(
              this.getShortString(this.state.apiResp)
            ).substring(0, 100)}
          />
          {/* {this.state.apiResp.summary ? this.state.apiResp.summary : this.state.apiResp.body} /> */}
          <meta
            property="og:image"
            content={this.getImageForFacebookShare(this.state.apiResp)}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
          <link rel="canonical" href={updatedCanonical(title)} />
          {/* {
            checkIfOlderThanTwoMonths(this.state.apiResp) && 
            <meta name="robots" content="noindex, nofollow" />
          } */}
        </Helmet>

        

        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src={getCDNUrl("/images/loader_v2.gif")} alt="" />
          </div>
        ) : (
          <div />
        )}

        <div className={this.state.customCssTransition}>
          <div className="card p-3">
            <div className="d-flex overflow-x-auto">
              {this.props.type === "stories" ? (
                <h2 className={"font-weight-semi-bold p-2 m-f-14"}>
                  <Link className="alert-heading" to="/stories">
                    Stories
                  </Link>
                </h2>
              ) : (
                <>
                  <h2
                    className={
                      this.state.newsClass + " font-weight-semi-bold p-2 m-f-14"
                    }
                  >
                    <Link className="alert-heading" to="/updates/news">
                      Latest News
                    </Link>
                  </h2>

                  <h2
                    className={
                      this.state.articlesClass +
                      " font-weight-semi-bold p-2 m-f-14 ml-4 "
                    }
                  >
                    <Link className="alert-heading" to="/updates/articles">
                      Articles
                    </Link>
                  </h2>
                </>
              )}

              {/* <h4 className="font-weight-semi-bold p-2 ml-4 text-secondary">Articles</h4> */}
            </div>
          </div>

          <div className="card mt-2 p-3">
            <div className="article border-bottom pb-4 mb-3">
              <div className="d-flex">
                <div className="ml-auto navbar-expand">
                  <ul className="navbar-nav ">
                    <li className="nav-item">
                      <a
                        data-original-title="Facebook"
                        className="nav-link text-secondary h1"
                        onClick={() => {
                          this.setState(
                            {
                              shareOn: "facebook",
                            },
                            () =>
                              window.open(
                                "http://www.facebook.com/sharer/sharer.php?u=" +
                                  window.location.href +
                                  `${""}`,
                                "_blank"
                              )
                          );
                        }}
                      >
                        <i class="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        data-original-title="twitter"
                        className="nav-link text-secondary h1"
                        onClick={() => {
                          this.setState(
                            {
                              shareOn: "twitter",
                            },
                            () =>
                              window.open(
                                "https://twitter.com/intent/tweet?text=" +
                                  this.state.apiResp.title +
                                  " " +
                                  window.location.href +
                                  `${"/"}`,
                                "_blank"
                              )
                          );
                        }}
                      >
                        <i class="fa fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <h1 className="article-title text-black m-f-14">
                {this.state.apiResp.title}
              </h1>
              <div className="article-image">
                <img className="w-100" src={this.state.apiResp.image} />
                {/* <ImageLoader src={this.state.apiResp.image}>
                  <img className="w-100" />
                  
                  <Preloader />
                  <div>Error!</div>
                </ImageLoader> */}
              </div>
              <div className="article-credits my-3 fs-1p2 pb-2 border-bottom text-medium-grey d-flex m-f-12">
                <span className="">{this.state.apiResp.by}</span>
                <span className="ml-auto">{getDate(this.state.apiResp)}</span>
              </div>
              <div className="text-black fs-1p2 m-f-12">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.apiResp.body }}
                  ref={(myElement) => (this.myElement = myElement)}
                  id={`tweetss`}
                />
              </div>
            </div>

            <div className="">
              <h3 className="text-uppercase text-black font-weight-normal mb-3">
                Related {this.props.type}
              </h3>
              {this.state.articles && this.state.articles.length > 0 ? (
                this.getArticles()
              ) : (
                // ( <div></div> ):
                <div />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function Preloader(props) {
  return <img className="w-100" src={Globals.placeholder16x9} />;
}

const getDate = (props) => {
  let datestr,
    date = "";
  if (typeof props !== "undefined" && props.created_at) {
    datestr = props.created_at;
    date = new Date(datestr).toDateString().split(" ");
    date = date[2] + " " + date[1] + ", " + date[3];
  }

  return date;
};

export default StoriesDetail;
