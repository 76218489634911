import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { getCDNUrl, getUnsubPath } from "../HelperFunctions";
import { Globals } from "../constants";
import Axios from "axios";
import { withRouter } from "react-router-dom";

class ConfirmUnsub extends Component {
  state = {
    showModal: false,
    paymentType:
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).paymentType,
  };

  openModal = () => {
    this.setState({ showModal: !this.state.showModal }, () =>
      this.props.changeOpen(this.state.open)
    );
  };

  componentDidMount() {
    if (this.props.open)
      this.setState({
        showModal: this.props.open,
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open)
      this.setState({
        showModal: this.props.open,
      });
  }

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_logged_in");
    localStorage.removeItem("isSocialProfileAttached");
    localStorage.removeItem("countryCode");
    localStorage.setItem("login", true);
    window.location.reload();
  };

  unSub = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let unsubPathObj = getUnsubPath(JSON.parse(localStorage.getItem("user")));

    if (unsubPathObj.status === 1 && unsubPathObj.path) {
      let url = unsubPathObj.path;
      this.setState(
        { unsubBtnTxt: "Please wait ...", unsubBtnDisabled: true },
        async () => {
          let resp = {};
          try {
            resp = await Axios.get(url);
            resp = await Axios.get(
              `${Globals.UserManagment}api/v1/users/unsub-user`,
              {
                headers: {
                  "x-access-token": user.xAccessToken,
                  msisdn: user.phone,
                  app: localStorage.getItem("domain")
                    ? localStorage.getItem("domain")
                    : Globals.telco
                      ? Globals.appName
                      : "",
                },
              }
            );
          } catch {
            console.log("tahir unsub failed");
          }

          this.logout();
        }
      );
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.state.showModal}
        toggle={this.openModal}
        backdrop={true}
      >
        <ModalHeader toggle={this.openModal} style={{ borderBottom: "0px" }} />
        <ModalBody>
          <div className="text-center">
            <div
              className={`d-flex justify-content-center align-items-center mt-3`}
            >
              <img
                src={getCDNUrl(`/images/cricwick/cricwick-logo.jpg?v=3`)}
                alt={`Cricwick logo`}
                width={`150px`}
                height={`auto`}
              />
            </div>
            <div>
              <h1
                className="head mt-5"
                style={{
                  marginTop: 0,
                }}
              >
                Dear User,
              </h1>
            </div>
            <div className="">
              <h1
                className={`modalDescription`}
                style={{
                  lineHeight: `50px`,
                }}
              >
                {this.state.paymentType &&
                this.state.paymentType.toLowerCase() !== "googleplay"
                  ? `In order to Choose a new Subscription Package, we are going to unsubscribe you from your current package.`
                  : `Unsub kerne ke liye Google Play pe apni subscription Cancel kerein.`}
              </h1>
              <br />
              <h1
                style={{
                  lineHeight: `30px`,
                  fontWeight: "500",
                  wordSpacing: `10px`,
                  color: "grey",
                }}
                className={`useUrduFont`}
                dir="rtl"
              >
                ({" "}
                {this.state.paymentType &&
                this.state.paymentType.toLowerCase() !== "googleplay"
                  ? `ھم آپکو موجودہ پیکج سے انسبسکرائب کر رہے ہیں تاکے آپ نئے پیکج پے سبسکرائب کر سکیں `
                  : ``}
                )
              </h1>
            </div>
          </div>
        </ModalBody>
        {this.state.paymentType &&
        this.state.paymentType.toLowerCase() !== "googleplay" ? (
          <div className={`d-flex justify-content-center w-100 pb-4`}>
            <div className={`d-flex flex-column`}>
              <button
                className="submit border-0 btn bg-red text-white rounded-0 text-uppercase font-weight-bold"
                onClick={() => this.unSub()}
              >
                {`OK`}
              </button>
              <button
                className="submit border-0 btn bg-red text-white rounded-0 text-uppercase font-weight-bold"
                onClick={this.openModal}
              >
                {`Cancel`}
              </button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  }
}

export default withRouter(ConfirmUnsub);
