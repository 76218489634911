import React, { Component } from "react";
import { MainCarouselSwipe } from "./InitializePlugins";
import {
  MatchScorecardInningsDetails,
  getMatchStateText,
  getTimeInAmPm,
  getDateWithoutYear,
  space2hyphen,
  space2hyphenForSlug,
} from "./HelperFunctions";
import { ApiUrl, pusherKey } from "./constants";
// import { MatchScorecardInningsDetails, getMatchStateText } from './HelperFunctions';
import Loading from "./components/common/loading";
import { Globals } from "./constants";
import axios from "axios";
// import Pusher from 'pusher-js';
import { Link } from "react-router-dom";
import FireBase, { FireBaseRefId } from "./FirebaseConfig";
import { Helmet } from "react-helmet";
import { canonicalLink } from "./HelperFunctions";

class Maincarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveMatchResp: { live_matches: [] },
      customCssTransition: "h-transition",
      leftHandleClass: "",
      rightHandleClass: "",

      error: null, //by Tariq
      isLoading: true, //by Tariq
      isLoaded: false, //by Tariq
      counter: 0, //by Tariq
      totalMatches: 0,
    };
    this.handleSwipe = MainCarouselSwipe.bind(this);
    this.windowLoadListener = false;
    this.liveMatchHandler = this.liveMatchHandler.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getFromApi = this.getFromApi.bind(this);
    this.showHandles = this.showHandles.bind(this);
    this.hideHandles = this.hideHandles.bind(this);
    this.scrollright = this.scrollright.bind(this);
    this.scrollleft = this.scrollleft.bind(this);

    //+++++++++++++ FIREBASE EVENS ******************
    this.InitMatches = this.InitMatches.bind(this);
  }

  liveMatchHandler(data) {
    const liveMatchesList = this.state.liveMatchResp.live_matches || [];
    const liveMatches = liveMatchesList.map(
      (obj, i) => (data.id === obj.id ? data : obj)
    );
    let liveMatchResp = this.state.liveMatchResp;
    liveMatchResp.live_matches = liveMatches;
    // this.setState({ liveMatchResp: liveMatchResp });
    this.setState({ liveMatchResp: liveMatchResp });
  }

  refresh(data) {
    this.getFromApi();
  }

  async getFromApi() {
    const url =
      Globals.ApiUrl +
      "api/v3/main/live_matches?telco=" +
      this.props.telco +
      "&app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;
    let response = {};
    try {
      response = await axios.get(url);
      //this.setState({liveMatchResp: response.data, customCssTransition: 'v-transition'});
      //this.setState({liveMatchResp: response.data, customCssTransition: 'v-transition'});

      await this.setState(() => {
        return {
          liveMatchResp: response.data,
          customCssTransition: "v-transition",
          error: null,
          isLoading: false,
          isLoaded: true,
          counter: 0,
        };
      });
    } catch (error) {
      console.log("MC err", error);
      this.setState(() => {
        return { error: error, isLoaded: false };
      });
    }
  }

  componentDidMount = async () => {
    FireBase.database()
      .ref(FireBaseRefId)
      .on("value", this.InitMatches);
  };
  componentWillUnMount = () => {
    if (this.windowLoadListener)
      window.removeEventListener("load", this.handleSwipe); //remove listener if listening
  };

  //===================== FIREBASE START ===================================

  async AddMatch(id, callback) {
    var SampleData = {
      isInnings: false,
      isMatch: false,
      break_type: null,
      chose_to: null,
      day: null,
      format: "",
      id: 0,
      innings: [],
      live_match_state: null,
      live_stream_url: null,
      match_start: null,
      match_state: "",
      series: {},
      session: null,
      teamA: {},
      teamB: {},
      team_1_id: 0,
      team_2_id: 0,
      title: "",
      toss_won_by_id: null,
      venue: {},
    };

    await FireBase.database()
      .ref(`l_m_${id}`)
      .once("value", (snapShotm) => {
        if (snapShotm.val() !== null) {
          SampleData.isMatch = true;
          Object.assign(SampleData, snapShotm.val());
        }
      });
    await FireBase.database()
      .ref(`l_i_${id}`)
      .once("value", (snapShoti) => {
        if (snapShoti.val() !== null) {
          SampleData.isInnings = true;
          if (snapShoti.val()[0] === "null") {
            SampleData.innings = [];
          } else {
            SampleData.innings = snapShoti.val();
          }
        }
      });

    callback(SampleData);
  }

  async InitMatches(snapShot) {
    // also using for reseting the matches state
    if (snapShot.val() !== null) {
      // assuming l_m_ids can't be null
      let currentMatchIds = snapShot.val() || [];
      this.setState({
        totalMatches: currentMatchIds.length,
      });
      let Data = [];
      this.setState(() => {
        return {
          isLoaded: false,
        };
      });
      for (var i = 0; i < currentMatchIds.length; i++) {
        //**TURN OFF THE CONNECTION IF EXIST **/
        FireBase.database()
          .ref(`l_m_${currentMatchIds[i]}`)
          .off("value");
        FireBase.database()
          .ref(`l_i_${currentMatchIds[i]}`)
          .off("value");

        this.AddMatch(currentMatchIds[i], (SampleData) => {
          Data.push(SampleData);
          this.setState(() => {
            return {
              liveMatchResp: { live_matches: Data },
              customCssTransition: "v-transition",
              error: null,
              isLoading: false,
              isLoaded: true,
              counter: 0,
            };
          });
          // if (SampleData.isInnings && SampleData.isMatch) {
          //     Data.push(SampleData);
          // }else{
          //     console.log("junk ",SampleData," id ",currentMatchIds[i]);
          // }
        });

        FireBase.database()
          .ref(`l_m_${currentMatchIds[i]}`)
          .on("value", (SnapShot) => this.UpdateMatchInfo(SnapShot)); // make function to update match must be on event
        FireBase.database()
          .ref(`l_i_${currentMatchIds[i]}`)
          .on("value", (SnapShot) => this.UpdateInningsInfo(SnapShot)); // make funtion to update innings with in a match must be on
      }
      // can also be check if currentMatches.length === 0 show loading again
    } else {
    }
  }
  async UpdateMatchInfo(SnapShot) {
    if (SnapShot.val() !== null && this.state.isLoaded) {
      var matchId = SnapShot.ref_.path.pieces_[0].match(/(\d+)/)[0]; // may there is better way
      let Data = this.state.liveMatchResp.live_matches;
      for (var i = 0; i < Data.length; i++) {
        if (Data[i].id == matchId.toString()) {
          let value = SnapShot.val();
          Object.assign(Data[i], value);
        }
      }

      this.setState(() => {
        return {
          liveMatchResp: { live_matches: Data },
          customCssTransition: "v-transition",
          error: null,
          isLoading: false,
          isLoaded: true,
          counter: 0,
        };
      });
    }
  }
  async UpdateInningsInfo(SnapShot) {
    if (SnapShot.val() !== null && this.state.isLoaded) {
      var matchId = SnapShot.ref_.path.pieces_[0].match(/(\d+)/)[0]; // may there is better way
      let Data = this.state.liveMatchResp.live_matches;
      for (var i = 0; i < Data.length; i++) {
        if (Data[i].id == matchId) {
          let value = SnapShot.val();
          Object.assign(Data[i].innings, value);
        }
      }

      this.setState(() => {
        return {
          liveMatchResp: { live_matches: Data },
          customCssTransition: "v-transition",
          error: null,
          isLoading: false,
          isLoaded: true,
          counter: 0,
        };
      });
    }
  }
  //===================== FIREBASE END =====================================

  //========================================================================

  knockingNetWorking = async () => {
    let i = 0;
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        this.getFromApi();
        i = i + 1;
        this.setState(() => {
          return { counter: i };
        });
      }, 15000);

      if (this.state.isLoaded) return;
    }
  };

  //==================================End========================================

  showHandles() {
    var scrollwidth = document.getElementsByClassName("cardss")[0].scrollWidth;
    var clientwidth = document.getElementsByClassName("cardss")[0].clientWidth;
    if (scrollwidth > clientwidth) {
      this.setState({ leftHandleClass: "show" });
      this.setState({ rightHandleClass: "show" });
    }
  }

  hideHandles() {
    this.setState({ leftHandleClass: "" });
    this.setState({ rightHandleClass: "" });
  }

  scrollleft() {
    var left = document.getElementsByClassName("cardss")[0];
    left.scrollLeft = left.scrollLeft - 219;
  }

  scrollright() {
    var right = document.getElementsByClassName("cardss")[0];
    right.scrollLeft = right.scrollLeft + 219;
  }

  render() {
    const { selectedMatchId } = this.props;
    if (this.state.totalMatches === 0) {
      return null;
    } else if (this.state.isLoading) {
      return (
        <div
          id="main-carousel-container"
          className="container  zi-1 position-relative"
        >
          <div className="card main-carousel" style={{ minHeight: 95 + "px" }}>
            <h2 className="p-3">Loading Matches....</h2>
            <Loading />
          </div>
        </div>
      );
    }
    //  else if (this.state.error) {
    //     return (<div className="card main-carousel">
    //         <h5 className="p-3">npm startLoading Matches....</h5>
    //         <Loading />
    //     </div>);
    // }
    else {
      return (
        <div
          id="main-carousel-container"
          className="container  zi-1 position-relative"
          onMouseEnter={this.showHandles}
          onMouseLeave={this.hideHandles}
        >
          <div
            className="carousel-left position-absolute"
            onClick={this.scrollleft}
          >
            <i
              className={
                "fa fa-angle-left on-hover " + this.state.leftHandleClass
              }
            />
          </div>
          <div
            className="carousel-right position-absolute"
            onClick={this.scrollright}
          >
            <i
              className={
                "fa fa-angle-right on-hover " + this.state.rightHandleClass
              }
            />
          </div>
          <div className={"cardss " + this.state.customCssTransition}>
            {this.state.liveMatchResp.hasOwnProperty("live_matches") ? (
              this.state.liveMatchResp.live_matches.map((obj, i) =>
                getMatchCard(obj, i, selectedMatchId)
              )
            ) : (
              <div />
            )}
          </div>
        </div>
      );
    }
  }
}

const getMatchCard = (obj, i, selectedMatchId) =>
  obj.match_state === "Scheduled" ? (
    <Link
      to={{
        pathname:
          "/match/" +
          obj.id +
          "/summary/1/" +
          space2hyphenForSlug(
            obj.teamA.name + " vs " + obj.teamB.name + " " + obj.title
          ),
        state: { matchId: obj.id },
      }}
      key={"lv-match-c-" + i}
      className="card main-carousel"
    >
      <div className="card-body m-f-11 py-2 px-2 topline">
        {/* <span className="font-weight-semi-bold">{getMatchStateText(obj)}</span> */}
        <span>{" " + obj.title + " at "}</span>
        <span className="text-dark-grey">
          {obj.venue.title ? obj.venue.title : ""}
        </span>
        <span>{" on "}</span>
        <span className="text-dark-grey">
          {getDateWithoutYear(obj.match_start) +
            ", " +
            getTimeInAmPm(obj.match_start)}
        </span>
      </div>
      {MatchScorecardInningsDetails(obj, 1, true)}
      {MatchScorecardInningsDetails(obj, 2, true)}
    </Link>
  ) : (
    <Link
      to={{
        pathname:
          "/match/" +
          obj.id +
          "/summary/1/" +
          space2hyphenForSlug(
            obj.teamA.name + " vs " + obj.teamB.name + " " + obj.title
          ),
        state: { matchId: obj.id },
      }}
      key={"lv-match-c-" + i}
      className={
        selectedMatchId === obj.id
          ? "card main-carousel selected-container"
          : "card main-carousel"
      }
    >
      <div className="card-body m-f-11 py-2 px-2 topline">
        <span className="font-weight-semi-bold red">
          {" "}
          {Globals.telco === "zong" ? (
            <span className="font-weight-semi-bold red fs-1">
              {getMatchStateText(obj) !== "live"
                ? " " + getMatchStateText(obj) + " "
                : ""}
            </span>
          ) : (
            getMatchStateText(obj)
          )}
        </span>
        <div className="clearfix" />
        <span>{" " + (obj.title ? obj.title : "") + " at "}</span>
        <span className="text-dark-grey">
          {" "}
          {obj.venue ? obj.venue.title : ""}
        </span>
      </div>
      {MatchScorecardInningsDetails(obj, 1, true)}
      {MatchScorecardInningsDetails(obj, 2, true)}
    </Link>
  );

export default Maincarousel;
