export const VideosSwipe = () => {
  const DomObject = window.$(".vc-list");

  DomObject.swipe({
    //Generic swipe handler for all directions
    swipe: function(
      event,
      direction,
      distance,
      duration,
      fingerCount,
      fingerData
    ) {
      let $this = window.$(this);
      if (direction === "right") {
        //go left
        $this.animate(
          {
            scrollLeft: "-=211",
          },
          300,
          "easeOutQuad"
        );
      }
      if (direction === "left") {
        //go right
        $this.animate(
          {
            scrollLeft: "+=211",
          },
          300,
          "easeOutQuad"
        );
      }
    },
    // Default is 75px, set to 0 for demo so any distance triggers swipe
    threshold: 0,
    excludedElements: "a",
  });
};

export const MainCarouselSwipe = () => {
  const $this = window.$("#main-carousel-container .cardss");

  $this.swipe({
    //Generic swipe handler for all directions
    swipe: function(
      event,
      direction,
      distance,
      duration,
      fingerCount,
      fingerData
    ) {
      // let $this = $("#main-carousel-container .cards");

      //console.log("You swiped " + direction );
      if (direction === "right") {
        //go left
        $this.animate(
          {
            scrollLeft: "-=219",
          },
          300,
          "easeOutQuad"
        );
      }
      if (direction === "left") {
        //go right
        $this.animate(
          {
            scrollLeft: "+=219",
          },
          300,
          "easeOutQuad"
        );
      }
    },
    //Default is 75px, set to 0 for demo so any distance triggers swipe
    threshold: 0,
    excludedElements: "a",
  });
};

export const BallByBallSwipe = () => {
  const $this = window.$("#main-section .bbb-container");

  $this.swipe({
    //Generic swipe handler for all directions
    swipe: function(
      event,
      direction,
      distance,
      duration,
      fingerCount,
      fingerData
    ) {
      if (direction === "right") {
        //go left
        $this.animate(
          {
            scrollLeft: "-=320",
          },
          300,
          "easeOutQuad"
        );
      }
      if (direction === "left") {
        //go right
        $this.animate(
          {
            scrollLeft: "+=320",
          },
          300,
          "easeOutQuad"
        );
      }
    },
    //Default is 75px, set to 0 for demo so any distance triggers swipe
    threshold: 0,
    excludedElements: "a",
  });
};

export const Calendar = () => {
  window.$("#search-videos").datepicker({
    changeMonth: true,
    changeYear: true,
    showButtonPanel: true,
    dateFormat: "MM yy",
    showOn: "button",
    onClose: function(dateText, inst) {
      window
        .$(this)
        .datepicker(
          "setDate",
          new Date(inst.selectedYear, inst.selectedMonth, 1)
        );
    },
  });

  window.$("#btn-calendar").click(function() {
    window.$("#search-videos").datepicker("show");
  });
};

export const initVideosPage = () => {
  VideosSwipe();
  Calendar();
};
