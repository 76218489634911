import React, { Component } from "react";
import axios from "axios";
import { getCDNUrl, loginUser } from "./HelperFunctions";
import { Globals } from "./constants";
import { Link } from "react-router-dom";
const queryString = require("query-string");

class AuthenticationForZain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      subscriptionSuccessful: false,
      msisdn: "",
    };

    this.apiPath = Globals.NodeApi + "api/";
  }

  async componentDidMount() {
    let cgObject = JSON.parse(localStorage.getItem("cgObject")),
      redirectTo = "/login",
      logRequest = { cgObject: cgObject, params: this.props.location.search };

    let params = queryString.parse(this.props.location.search);
    this.setState({
      isLoading: true,
    });

    setTimeout(async () => {
      //   http://localhost:3000/authentication_zain/?status=success&token=TOKEN:359d19d8-063e-464e-86d7-7c721d003d82&correlator=1_106_xyz&auth_method=sms_pin
      if (cgObject) {
        if (params.status === "success") {
          let url =
            "https://staging.cricwick.net:13002/api/create_subscription_by_token?web_user=1&service_id=1&telco=zain_kw&token=" +
            params.token;

          let serverResponse = await axios.get(url, {});
          serverResponse = serverResponse.data.remote_response;
          this.setState({
            isLoading: false,
          });
          if (serverResponse.subscribed_status) {
            this.setState({
              subscriptionSuccessful: true,
              isLoading: false,
              msisdn: serverResponse.msisdn,
            });

            let userObject = {
              call_type: "SLA_ZAIN",
              subscribed: 1,
              phone: serverResponse.msisdn,
              telco: "zain_kw",
            };

            localStorage.setItem("user_logged_in", true);
            localStorage.setItem("user", JSON.stringify(userObject));
            loginUser(userObject);
          } else {
            localStorage.removeItem("cgObject");
            this.setState({ subscriptionSuccessful: false, isLoading: false });
            window.location.href = redirectTo;
          }
        } else {
          localStorage.removeItem("cgObject");
          window.location.href = redirectTo;
        }
      } else {
        console.log("Authentication Failed");
        localStorage.removeItem("cgObject");
        window.location.href = redirectTo;
      }
    }, 5000);
  }

  openApp = () => {
    window.location.href =
      "https://cricwick.net/zain-sla-welcome/" +
      this.state.msisdn +
      "/1/zain_kw";
  };

  openWeb = () => {
    window.location.href = "/";
  };

  renderAppDeeplinkForZainKW = () => {
    return (
      <div className="card">
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          Welcome To CricWick
        </div>
        <div
          className="text-center mb-3"
          style={{ fontSize: "1.7rem", fontWeight: "600" }}
        >
          Do you want to open the app or continue with the Web?
        </div>
        <button
          className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
          onClick={this.openApp}
        >
          Open App
        </button>
        <p className="text-center nav-link" onClick={this.openWeb}>
          Continue to Web
        </p>
      </div>
    );
  };

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          ""
        )}
        {this.state.subscriptionSuccessful && this.renderAppDeeplinkForZainKW()
        //    :
        //     <div
        //   className="text-center mb-3"
        //   style={{ fontSize: "1.7rem", fontWeight: "600" }}>
        //     Something Went Wrong
        // </div>
        }
      </section>
    );
  }
}

export default AuthenticationForZain;
