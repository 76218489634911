import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/common.css";

import BackgroundImage from "react-background-image-loader";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { canonicalLink } from "./HelperFunctions";

import axios from "axios";

import { initVideosPage } from "./InitializePlugins";
import { Globals } from "./constants";
import {
  space2hyphen,
  getDateUpdated,
  space2hyphenForSlug,
  getCDNUrl,
} from "./HelperFunctions";

import { Helmet } from "react-helmet";

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customCssTransition: "h-transition",
      isLoading: true,
      apiPath: Globals.ApiUrl + "api/v4/",
      //apiPath: 'http://testing.cricwick.net:8080/api/v4/',
      page: 0,
      pagesEnd: false,
      featuredVideos: [],
      playlists: [],
      series: [],
      font1p3: { fontSize: "1.3em" },
      font27px: { fontSize: "27px" },
      height268: { height: "238px" },
      height234: { height: "206px" },
      cursorPointer: { cursor: "pointer" },
    };

    this.externalScriptsHandler = initVideosPage.bind(this);
    this.nextPage = this.nextPage.bind(this);

    this.windowLoadListener = false;
  }

  componentWillMount = () => {
    const featured = this.state.featuredVideos.slice(),
      playlists = this.state.playlists.slice(),
      series = this.state.series.slice();

    this.getVideosFromAPI().then((response) => {
      response.data.forEach((data) => {
        if (data.type === "featured") {
          data.data.forEach((video) => featured.push(video));
        } else if (data.type === "play_list") {
          data.data.forEach((video) => playlists.push(video));
        } else if (data.type === "series") {
          series.push(data);
        }
      });

      this.setState({
        customCssTransition: "v-transition",
        isLoading: false,
        featuredVideos: featured,
        playlists: playlists,
        series: series,
      });
    });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (document.readyState === "complete") {
      // if document has finished loading all the scripts
      this.externalScriptsHandler.call(); // call videos swipe initialization
    } else {
      // if document is still loading scripts i.e readyState === 'loading'
      this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
      window.addEventListener("load", this.externalScriptsHandler); // add listener
    }
  }

  componentWillUnMount = () => {
    if (this.windowLoadListener) {
      window.removeEventListener("load", this.externalScriptsHandler); //remove listener if listening
    }
  };

  getVideosFromAPI() {
    let apiPath = this.state.apiPath;
    let page = this.state.page + 1;

    let videosApiURL =
      apiPath + "video_lists?page=" + page + "&app_name=" + `${localStorage.getItem("domain") ? localStorage.getItem("domain") : Globals.telco ? Globals.appName : Globals.appName}`;

    this.setState({
      page: page,
    });

    try {
      return axios.get(videosApiURL);
    } catch (error) {
      return [];
    }
  }

  async nextPage(e) {
    if (!this.state.pagesEnd) {
      const featured = this.state.featuredVideos.slice(),
        playlists = this.state.playlists.slice(),
        series = this.state.series.slice();

      let elem = document.getElementsByClassName("loading-more-results");

      if (elem.length) {
        elem[0].classList.remove("d-none");
      }

      this.getVideosFromAPI().then((response) => {
        if (response.status === 200) {
          if (elem.length) {
            elem[0].classList.add("d-none");
          }

          if (response.data && response.data.length > 0) {
            response.data.forEach((data) => {
              if (data.type === "featured") {
                data.data.forEach((video) => featured.push(video));
              } else if (data.type === "play_list") {
                data.data.forEach((video) => playlists.push(video));
              } else if (data.type === "series") {
                series.push(data);
              }
            });

            this.setState({
              featuredVideos: featured,
              playlists: playlists,
              series: series,
            });
          } else if (response.data.length === 0) {
            this.setState({ pagesEnd: true });
          }
        } else {
          if (elem.length) {
            elem[0].classList.add("d-none");
          }
        }
      });
    }
  }

  renderFeaturedVideos() {
    return (
      <FeaturedVideosContainer featuredVideos={this.state.featuredVideos} />
    );
  }

  renderPlayLists() {
    return (
      <PlaylistsContainer
        height268={this.state.height268}
        height234={this.state.height234}
        playlists={this.state.playlists}
      />
    );
  }

  renderSeries() {
    return this.state.series.map((series, i) => {
      return (
        <SeriesContainer
          key={i}
          height268={this.state.height268}
          height234={this.state.height234}
          series={series}
        />
      );
    });
  }

  render() {
    return (
      <section id="main-section" className="align-self-start">
        <Helmet>
          <title>
          Watch Cricket Highlights, Player Interviews & more Videos on {Globals.product}
          </title>
          <meta
            name="description"
            content={
              Globals.product +
              " is the Prime Destination for Watching Match Highlights, Interviews, Special Features, Latest Events and Many More Cricket Videos."
            }
          />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src={getCDNUrl("/images/loader_v2.gif")} alt="" />
          </div>
        ) : (
          <div />
        )}
        <div className={this.state.customCssTransition}>
          <div className="card p-3">
            <div className="d-flex overflow-x-auto">
              <h1 className="font-weight-semi-bold p-2 m-f-14 ">Videos</h1>
            </div>
          </div>

          <div id="all-videos" className="mt-2">
            <div className="card px-3 py-0">
              {this.state.featuredVideos && this.state.featuredVideos.length > 0
                ? this.renderFeaturedVideos()
                : ""}
              {this.state.playlists && this.state.playlists.length > 0
                ? this.renderPlayLists()
                : ""}
              {this.state.series && this.state.series.length > 0
                ? this.renderSeries()
                : ""}
            </div>
          </div>
          {!this.state.pagesEnd && this.state.series.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img src={getCDNUrl("/images/loader_v2.gif")} alt="" height="30" />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </section>
    );
  }
}

const FeaturedVideosContainer = (props) => (
  <div className="mt-2">
    <Carousel
      className="carousel-wrapper"
      infiniteLoop={true}
      autoPlay={true}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      style={props.height268}
    >
      {getFeaturedVideos(props)}
    </Carousel>
  </div>
);

const getFeaturedVideos = (props) => {
  return props.featuredVideos.map((video, i) => {
    return <FeaturedVideo key={"vc-" + i} data={video.data[0]} />;
  });
};

const FeaturedVideo = (props) => (
  <Link
    className="text-primary"
    to={"/video/" + props.data.id + "/" + space2hyphenForSlug(props.data.title)}
  >
    <BackgroundImage
      className="vc-item-img sixteen-by-nine bg-center-cover  position-relative"
      src={props.data.thumb}
      placeholder={Globals.placeholderPlain4x3}
    />
  </Link>
);

const PlaylistsContainer = (props) => (
  <div
    className="latest-videos videos-collection-container py-2 mt-2"
    style={props.height234}
  >
    <div className="videos-collection mt-2">
      <div className="vc-list" style={props.height234}>
        {getPlayLists(props)}
        <Link className="text-primary" to={"/playlists"}>
          <button className="btn more-icon">
            <img src={getCDNUrl("/images/more_ico.png")} />
          </button>
        </Link>
        <div className="clearfix" />
      </div>
    </div>
  </div>
);

const getPlayLists = (props) => {
  if (props.playlists && props.playlists.length > 0) {
    return props.playlists.map((playlist, i) => {
      return <Playlist key={i} playlist={playlist.data[0]} />;
    });
  }
};

const Playlist = (props) => {
  return (
    <Link
      className="text-primary"
      to={
        "/playlist/" +
        props.playlist.id +
        "/video/" +
        props.playlist.top_list_item.video.id +
        "/" +
        space2hyphenForSlug(props.playlist.top_list_item.video.title)
      }
    >
      <div className="vc-item px-0 pr-1">
        <BackgroundImage
          className="vc-item-img sixteen-by-nine bg-center-cover  position-relative"
          src={props.playlist.thumb}
          placeholder={Globals.placeholderPlain4x3}
        >
          <div className="plist-stack">
            <div className="plist-container">
              <p>
                <img
                  className="plist-icon"
                  src={getCDNUrl("/images/playlist_icon.svg")}
                />
                <br />
                <bold>{props.playlist.total_videos}</bold>
                <br />
                Videos
              </p>
            </div>
          </div>
        </BackgroundImage>
        <div
          className="vc-item-h2 font-weight-bold px-2 truncate-v2 m-f-13 mt-2"
          style={{ fontSize: "14px", height: "auto" }}
        >
          {props.playlist.title}
        </div>
        <div
          className="vc-item-h1 mt-1 mb-2 px-2 text-nowrap text-truncate m-f-12"
          style={{ fontSize: "12px" }}
        >
          {props.playlist.description}
        </div>
        <div
          className="vc-item-h1 mt-1 mb-2 px-2 text-nowrap text-truncate m-f-9"
          style={{ fontSize: "10px" }}
        >
          {getDateUpdated(props.playlist.top_list_item.video.created_at)}
        </div>
      </div>
    </Link>
  );
};

const SeriesContainer = (props) => (
  <div
    className="latest-videos videos-collection-container py-2 mt-2"
    style={props.height268}
  >
    <div className="videos-collection mt-2">
      <div className="vc-header mb-2">
        <div className="d-flex align-items-end">
          <Link
            to={
              "/series/" +
              props.series.series_id +
              "/" +
              space2hyphen(props.series.label) +
              "/videos"
            }
          >
            <div className="vc-title font-weight-bold text-truncate m-f-14">
              {props.series.label}
            </div>
          </Link>
        </div>
      </div>
      <div className="vc-list" style={props.height234}>
        {getSeries(props)}
        <Link
          className="text-primary"
          to={
            "/series/" +
            props.series.series_id +
            "/" +
            space2hyphen(props.series.label) +
            "/videos"
          }
        >
          <button className="btn more-icon">
            <img src={getCDNUrl("/images/more_ico.png")} />
          </button>
        </Link>
        <div className="clearfix" />
      </div>
    </div>
  </div>
);

const getSeries = (props) => {
  if (props.series.data && props.series.data.length > 0) {
    return props.series.data.map((video, i) => {
      return (
        <SeriesVideos
          key={i}
          seriesId={props.series.series_id}
          seriesName={space2hyphen(props.series.label)}
          video={video.data[0]}
        />
      );
    });
  }
};

const SeriesVideos = (props) => {
  return (
    <Link
      className="text-primary"
      to={
        "/series/" +
        props.seriesId +
        "/" +
        props.seriesName +
        "/videos/" +
        props.video.id +
        "/" +
        space2hyphenForSlug(props.video.title)
      }
    >
      <div className="vc-item px-0 pr-1">
        <BackgroundImage
          className="vc-item-img sixteen-by-nine bg-center-cover  position-relative"
          src={props.video.thumb}
          placeholder={Globals.placeholderPlain4x3}
        >
          <div className="fa fa-play-circle play-btn-lg play-btn-sm" />
        </BackgroundImage>
        <div
          className="vc-item-h2 px-2 truncate-v2 font-weight-semi-bold m-f-13 mt-1"
          style={{ fontSize: "14px" }}
        >
          {props.video.title}
        </div>
        <div
          className="vc-item-h1 mt-1 mb-2 px-2 text-nowrap text-truncate m-f-12"
          style={{ fontSize: "12px" }}
        >
          {props.video.description}
        </div>
        <div
          className="vc-item-h1 mt-1 mb-2 px-2 text-nowrap text-truncate m-f-9"
          style={{ fontSize: "10px" }}
        >
          {getDateUpdated(props.video.created_at)}
        </div>
      </div>
    </Link>
  );
};

export default Videos;
