import React, { Component } from "react";
import { SeriesNav } from "./Series";
import axios from "axios";
import { hyphen2space, canonicalLink } from "./HelperFunctions";
import { getCDNUrl } from "./HelperFunctions";
import { Globals } from "./constants";

import { Helmet } from "react-helmet";

class SeriesPointsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesTitle: "",
      isLoading: true,
      pointsTable: [],
      seriesId: this.props.match.params.id,
    };
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    let seriesTitle = this.props.match.params.title;
    this.setState({ seriesTitle: seriesTitle });

    try {
      // const url =
      //   Globals.ApiUrl +
      //   "api/series_points_table/" +
      //   this.props.match.params.id;
      const url = `${Globals.ApiUrl}api/series_pool_points_table/${
        this.props.match.params.id
      }`;
      const response = await axios.get(
        // `http://34.208.53.52:8080/api/series_pool_points_table/807`
        url
      );
      if (response.status === 200) {
        this.setState({
          pointsTable: response.data.data.reverse(),
          isLoading: false,
        });
        let elem = document.getElementsByClassName("none-message");
        if (elem.length > 0) {
          elem[0].classList.remove("d-none");
        }
      } else;
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <section id="main-section" className="px-lg-0 jc-inner-page">
        <Helmet>
          <title>
            {hyphen2space(this.state.seriesTitle)} Points Table for All Teams -{" "}
            {Globals.product}
          </title>
          <meta
            name="description"
            content={
              "Check out the" +
              hyphen2space(this.state.seriesTitle) +
              " points table for all teams with matches, wins, losses and net run rates on " +
              Globals.product
            }
          />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div />
        )}
        <div className="card p-3">
          <h1 class="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">
            {hyphen2space(this.state.seriesTitle)} Points Table
          </h1>
          <SeriesNav path={this.props.match} />
        </div>
        <div className="card mt-2 p-3">
          {this.state.pointsTable.length > 0 ? (
            <div>
              {this.state.pointsTable.map((pointTable, index) => {
                return (
                  <div
                    key={`${pointTable.title}`}
                    className={`${index !== 0 ? "mt-5" : ""}`}
                  >
                    <div>
                      <h2
                        className="p-3"
                        style={{
                          // backgroundColor: "#CF2736",
                          backgroundColor: "#c20406",
                          color: "white",
                        }}
                      >
                        {pointTable.title}
                      </h2>
                    </div>
                    {<PointsTable data={pointTable.points_table} />}
                  </div>
                );
              })}
            </div>
          ) : (
            <h2 className="mb-0 none-message d-none">
              Points table not available at the moment
            </h2>
          )}
        </div>
        {this.state.seriesId &&
          this.state.seriesId.toString() === "1452" && (
            <div className="card mt-2 p-3">
              <h2>
                KPL 2022 Points Table & Team Standing, Kashmir Premier League
              </h2>
              <p>
                The second season of KPL will be played in Muzaffarabad from
                August 11 to August 25. KPL will feature seven teams, including
                Rawalakot Hawks, Kotli Lions, Mirpur Royals, Muzaffarabad
                Tigers, Overseas Warriors, Jammu Janbaz, and Bagh Stallions.
              </p>
              <h2>KPL Points, Tournament Format</h2>
              <p>
                The team will bag two points for each win. Points will be shared
                in case a match is washed away by rain. In case two teams have
                equal points on the points table after the league matches are
                concluded, their promotion to the next stage would depend on
                their net run rate.
              </p>
              <h2>KPL 2022 Qualification to Play-Offs</h2>
              <p>
                After the league stage, the two teams would play a qualified.
                The winner of the qualifier would make it to the final. The
                teams on the 3rd and 4th positions will battle in an eliminator.
                The loser will be eliminated, and the winner will get to play
                the losing team of the qualifier for a shot at the final.
              </p>
            </div>
          )}
      </section>
    );
  }
}
export const PointsTable = (props) => (
  <table className="mb-3 points-table fs-1p2">
    <tbody>
      <PointsTableTitle />
      {props.data.map((obj, i) => (
        <PointsTableRow
          key={"pt-" + i}
          data={obj}
          showPlayerDetailText={false}
        />
      ))}
    </tbody>
  </table>
);

const PointsTableTitle = () => (
  <tr>
    <td
      valign="middle"
      className="text-black w-25 font-weight-normal py-3 px-1 pl-3"
    >
      Teams
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      Mat
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      Won
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      Lost
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      Ties
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      NR
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      Pts
    </td>
    <td
      valign="middle"
      className="text-black font-weight-normal py-3 px-1"
      align="center"
    >
      NRR
    </td>
  </tr>
);

const PointsTableRow = (props) => (
  <tr>
    <td
      valign="middle"
      className="w-50 font-weight-normal px-1 py-1 pl-3 text-black"
    >
      {props.data.name}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.match_played}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.match_won}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.match_lost}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.match_tied}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.match_no_result}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.points}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.net_run_rate}
    </td>
  </tr>
);
export default SeriesPointsTable;
