import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { Globals } from "../constants";
import { getCDNUrl } from "../HelperFunctions";

class EasypaisaLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.props.onLoaderClose();
  }

  render() {
    const { showModal } = this.state;
    return (
      <Modal isOpen={showModal} backdrop={true}>
        <ModalBody className="modal-body-easypaisa mt-lg-5 ml-2 mr-2">
          <div className="text-center mb-3">
            <span className="">
              <img
                style={{ width: "60%" }}
                src={getCDNUrl("/images/cricwick/cricwick-logo.jpg?v=3")}
                alt=""
              />
            </span>
          </div>
          <div className="bg text-center">
            {!this.props.errorMessage &&
              this.props.errorMessage.length <= 0 && (
                <div style={{ marginTop: "8%" }}>
                  <h2 className="head">
                    Please wait
                    <span className="ml-1">
                      <img src={getCDNUrl("./images/loader_v2.gif")} alt="" />
                    </span>
                  </h2>
                  <h2 style={{ marginTop: "0px" }} className="head">
                    We are processing you request
                  </h2>
                </div>
              )}
            <div className="easypaisa-pocket mb-4 ml-4 mr-4">
              <h2 style={{ fontWeight: "bold" }}>
                Save your Easypaisa account now!
              </h2>
              <h2 style={{ fontWeight: "normal" }}>
                1. Ensure your Easypaisa account is Active
              </h2>
              <h2 style={{ fontWeight: "normal" }}>
                2. Successful transactions will automatically save your
                Easypaisa account for future use
              </h2>
              <h2 style={{ fontWeight: "normal" }}>
                3. To confirm your payment:
              </h2>
              <ul>
                <li className="easypaisa-pocket-ul">
                  Telenor customers: Input your 5-digit PIN into the USSD prompt
                </li>
                <li className="easypaisa-pocket-ul">
                  For other networks: Login to your Easypaisa app Tap on the
                  top-left menu to approve the payment in "My Approvals"
                </li>
              </ul>
            </div>
            <p
              className="message mt-5 text-danger text-center"
              style={{ fontSize: "16px" }}
            >
              {this.props.errorMessage}
            </p>
            <br />
            {this.props.errorMessage &&
              this.props.errorMessage.length > 0 && (
                <button
                  className="border-0 w-50 btn bg-red text-white rounded-0 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
                  onClick={() => this.closeModal()}
                >
                  Close
                </button>
              )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default EasypaisaLoader;
