import React from "react";
import Loading from "../Loading";
import { withRouter } from "react-router-dom";
import { Globals } from "../constants";
import cash from "../images/cash.png";
import "./trivia.css";
import "../App.css";
import Lottie from "react-lottie";
import jccoin from "../images/jccoin.png";
import menaCoin from "../images/menaCoin.png";
import coin from "../images/coin.png";
import menaWallet from "../images/mena-wallet.png";
import MakingMoney from "../lotties/50494-fake-3d-vector-coin";
import walletCoins from "../lotties/walletcoins.json";
import googleAd from "../images/ad.png"; //images/ad.jpg

import PaymentMethods from "./PaymentMethods";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getCDNUrl } from "../HelperFunctions";
import clock from "../images/clock.png";
import man from "../images/mann.png";
import { Modal, ModalBody } from "reactstrap";
import QRCode from "react-qr-code";
import tick from "../images/view-claim-tick.png";
import { kFormatter, formatDate } from "../HelperFunctions";
import payoutArrow from "../images/payout-arrow.png";
import { getWallet } from "../HelperFunctions";
import GoogleLogin from "react-google-login";

class ContestWallet extends React.Component {
  constructor(props) {
    super(props);
    let MSISDN = "";
    if (!localStorage.getItem("user")) {
      window.location.href = "/login";
    } else {
      MSISDN = JSON.parse(localStorage.getItem("user")).phone;
    }
    this.state = {
      isLoading: true,
      currentPage: 0,
      prizesData: [],
      msisdn: MSISDN,
      token: JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).xAccessToken
        : "",
      wallet: {},
      page: 1,
      availableCoins: 0,
      pagesEnd: false,
      tabsIndex: 0,
      prizeOpened: -1,
      transactions: [],
      redemption: [],
      invite: [],
      restrictApi: false,
      isTransactionsLoading: true,
      minimumPayout: 0,
      uniqueData: [],
      gameCard: false,
      voucherCard: false,
      gameId: null,
      gameName: null,
      gameTitle: null,
      gameDescription: null,
      gameImageUrl: null,
      setModal: false,
      voucherCode: null,
      voucherImageUrl: null,
      expiryDate: null,
      price: null,
      productName: null,
      productDescription: null,
      phonenumber: null,
      address: null,
      imageUrl: null,
      productCard: false,

      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: MakingMoney,
        rendererSettings: {},
      },
      defaultLottie: {
        loop: true,
        autoplay: true,
        animationData: walletCoins,
        rendererSettings: {},
      },
    };

    this.wrapperRef = React.createRef();
    this.backPressed = this.backPressed.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  closeModal() {
    this.setState({
      setModal: false,
    });
  }

  //get payout wallet info
  getPayoutWalletInfo = async (page) => {
    let limit = 15;
    let resp, data;

    if (page === 0) {
      resp = await fetch(
        `https://ums-prod.cricwick.net/api/v1/transactions/get_user_transactions?page=${page}&limit=${limit}`,
        {
          headers: {
            "x-access-token": this.state.token,
            msisdn: this.state.msisdn,
            app: localStorage.getItem("domain")
              ? localStorage.getItem("domain")
              : Globals.telco
                ? Globals.appName
                : "",
          },
        }
      );
      data = await resp.json();

      this.setState({
        wallet: data ? data.data.wallet : 0,
      });
    }
  };

  //get Winning
  getWinning = async () => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    const resp = await fetch(
      `${Globals.UCS}transactions?userId=${
        user.id
      }&mainType=${"contest"}&subType=${"winning"}`,
      {
        headers: {
          "x-access-token": this.state.token,
          msisdn: this.state.msisdn,
          app: localStorage.getItem("domain")
            ? "mena"
            : Globals.telco == "mobilink"
              ? "jazzcricket"
              : "cricwick",
        },
      }
    );

    const data = await resp.json();

    const contest = [];
    const duplicate =
      data.data &&
      data.data.filter((item) => {
        //remove duplicate from data
        const isDuplicate = contest.includes(item.details.cwMatchId);
        if (!isDuplicate) {
          contest.push(item.details.cwMatchId);
          return true;
        }
        return false;
      });

    this.setState({
      isLoading: false,
      transactions: data.data,
      isTransactionsLoading: false,
      uniqueData: duplicate,
    });

    return data;
    //this.MainWallet();
  };

  getInvites = async () => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    const resp = await fetch(
      `${Globals.UCS}transactions?userId=${
        user.id
      }&mainType=${"invite"}&subType=${"inviter"}`,
      {
        headers: {
          "x-access-token": this.state.token,
          msisdn: this.state.msisdn,
          app: localStorage.getItem("domain")
            ? "mena"
            : Globals.telco == "mobilink"
              ? "jazzcricket"
              : "cricwick",
        },
      }
    );

    const data = await resp.json();

    this.setState({
      isLoading: false,
      invite: data ? data.data : "",
      isTransactionsLoading: false,
    });

    return data;
  };

  //get winnings, redemptions and invite info
  getWalletAndTransactionInfo = async (page) => {
    let limit = 10;

    let user = localStorage.getItem("user");
    user = JSON.parse(user);

    const resp = await fetch(
      `${Globals.UCS}transactions?userId=${
        user.id
      }&mainType=${"redemption"}&page=${page}`,
      {
        headers: {
          "x-access-token": this.state.token,
          msisdn: this.state.msisdn,
          app: localStorage.getItem("domain")
            ? "mena"
            : Globals.telco == "mobilink"
              ? "jazzcricket"
              : "cricwick",
        },
      }
    );

    const data = await resp.json();

    this.setState({
      isLoading: false,
      page: page,
      restrictApi: false,
      pagesEnd: data.data ? data.data.length < limit : false,
      redemption: data.data,
      isTransactionsLoading: false,
    });
    return data;
  };

  async componentDidMount() {
    if (!this.state.token) {
      window.href = "/login";
    } else {
      this.getPayoutWalletInfo(0);
      this.getWalletAndTransactionInfo(0); // get redemptions
      await this.getWinning(); //get winnings data
      await this.getInvites(); //get invite data
      let coins = await getWallet();
      this.setState({
        availableCoins: coins,
      });
    }
    // var ifr = document.getElementById("iframe")
    // console.log("ifr", ifr, document)
    // ifr.contentWindow.postMessage("inviteFriends", '*');
  }

  backPressed(wasTransactionMade) {
    //redirect to page 0
    this.setState({
      isLoading: false,
      currentPage: 0,
    });
    if (wasTransactionMade) {
      window.location.reload();
    }
  }

  viewTransactionPressed() {
    //redirect to redemption page
    this.setState({
      isLoading: true,
      currentPage: 1,
    });
  }

  //move to next page
  async nextPage() {
    if (!this.state.pagesEnd) {
      this.setState(
        {
          restrictApi: true,
        },
        () => this.getWalletAndTransactionInfo(this.state.page + 1)
      );
    }
  }

  //move to payout screen
  viewPayoutPressed() {
    this.setState({
      isLoading: false,
      currentPage: 2,
    });
  }

  encrypt(str) {
    return btoa(str);
  }

  //game redemption card
  openViewGameCardClaim() {
    this.setState({
      gameCard: true,
      setModal: true,
      voucherCard: false,
      productCard: false,
    });
  }

  //voucher redemption card
  openViewVoucherCardClaim() {
    this.setState({
      setModal: true,
      gameCard: false,
      voucherCard: true,
      productCard: false,
    });
  }

  //product redemption card
  openViewProductCardClaim() {
    this.setState({
      setModal: true,
      gameCard: false,
      voucherCard: false,
      productCard: true,
    });
  }

  //transactions tab
  TransactionsTab = () => {
    return (
      <div className="">
        <div className="mx-auto wallet-view-transaction pt-2">
          <span className="text-center d-flex justify-content-center align-items-center pt-4 pb-4">
            <img
              src={clock}
              alt=""
              height={"12px"}
              width={"12px"}
              style={{ marginRight: "5px" }}
            />
            Only two months history will retain
          </span>
          {this.state.redemption && this.state.redemption.length > 0
            ? this.state.redemption.map((transaction) => {
                return (
                  <div className="ml-2 pt-2" key={transaction.id}>
                    <div style={{ color: "#343434" }} className="row">
                      <div className="d-flex col-8 text-left">
                        <div
                          className="ml-2 my-auto d-flex"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{ fontSize: "14px", fontWeight: "bold" }}
                            className="transaction-status my-auto pt-2 pb-2 text-capitalize"
                          >
                            {transaction.details.details.mainType === "game" ? (
                              <span
                                style={{ padding: "10px", paddingLeft: "0" }}
                                onClick={() => {
                                  this.setState({
                                    gameId:
                                      transaction.details.details.userDetails
                                        .pubgplayerid,
                                    gameName:
                                      transaction.details.details.userDetails
                                        .pubgname,
                                    gameImageUrl:
                                      transaction.details.details.imageUrl,
                                    gameTitle:
                                      transaction.details.details.title,
                                    gameDescription:
                                      transaction.details.details.description,
                                  });
                                  this.openViewGameCardClaim();
                                }}
                              >
                                {transaction.details.details.title
                                  ? transaction.details.details.title
                                  : "unknown"}{" "}
                                {transaction.details.details.mainType
                                  ? transaction.details.details.mainType
                                  : null}{" "}
                                claimed
                              </span>
                            ) : transaction.details.details.mainType ===
                            "voucher" ? (
                              <span
                                style={{ padding: "10px", paddingLeft: "0" }}
                                onClick={() => {
                                  this.setState({
                                    voucherCode:
                                      transaction.details.details.voucherCode,
                                    expiryDate:
                                      transaction.details.details.expiryDate,
                                    price: transaction.details.details.price,
                                    voucherImageUrl:
                                      transaction.details.details.imageUrl,
                                  });
                                  this.openViewVoucherCardClaim();
                                }}
                              >
                                {transaction.details.details.title
                                  ? transaction.details.details.title
                                  : "unknown"}{" "}
                                {transaction.details.details.mainType
                                  ? transaction.details.details.mainType
                                  : null}{" "}
                                claimed
                              </span>
                            ) : transaction.details.details.mainType ===
                            "product" ? (
                              <span
                                style={{ padding: "10px", paddingLeft: "0" }}
                                onClick={() => {
                                  this.setState({
                                    productName:
                                      transaction.details.details.userDetails
                                        .name,
                                    phonenumber:
                                      transaction.details.details.userDetails
                                        .phonenumber,
                                    address:
                                      transaction.details.details.userDetails
                                        .address,
                                    imageUrl:
                                      transaction.details.details.imageUrl,
                                    productDescription:
                                      transaction.details.details.description,
                                  });
                                  this.openViewProductCardClaim();
                                }}
                              >
                                {transaction.details.details.title
                                  ? transaction.details.details.title
                                  : "unknown"}{" "}
                                {transaction.details.details.mainType
                                  ? transaction.details.details.mainType
                                  : null}{" "}
                                claimed
                              </span>
                            ) : (
                              <span
                                style={{ padding: "10px", paddingLeft: "0" }}
                              >
                                Unknown Claimed
                              </span>
                            )}
                          </span>
                          <span
                            style={{
                              fontSize: "10px",
                              fontWeight: "normal",
                              opacity: "0.5",
                            }}
                          >
                            {formatDate(
                              transaction.details.details.redemptionTimeInMS
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-4 text-right">
                        <div
                          className="mr-3"
                          style={{
                            fontSize: "16px",
                            fontWeight: "700",
                            lineHeight: "27px",
                            color:
                              transaction.transactionType === "credit"
                                ? ""
                                : "#b91414",
                          }}
                        >
                          {Globals.telco == "mobilink" ? (
                            <img
                              className="mr-1"
                              src={jccoin}
                              alt=""
                              height={"12px"}
                              width={"19px"}
                            />
                          ) : Globals.telco == "zain" ||
                          localStorage.getItem("domain") == "CricwickMena" ? (
                            <img
                              className="mr-1"
                              src={menaCoin}
                              alt=""
                              height={"12px"}
                              width={"19px"}
                            />
                          ) : (
                            <img
                              className="mr-1"
                              src={coin}
                              alt=""
                              height={"12px"}
                              width={"19px"}
                            />
                          )}
                          {transaction.transactionType === "credit" ? "+" : "-"}
                          {kFormatter(transaction.details.details.price)}
                          {transaction.details.details.mainType === "game" ? (
                            <img
                              className="ml-1"
                              src={payoutArrow}
                              alt=""
                              height={"10px"}
                              width={"8px"}
                              onClick={() => {
                                this.setState({
                                  gameId:
                                    transaction.details.details.userDetails
                                      .pubgplayerid,
                                  gameName:
                                    transaction.details.details.userDetails
                                      .pubgname,
                                  gameImageUrl:
                                    transaction.details.details.imageUrl,
                                  gameTitle: transaction.details.details.title,
                                  gameDescription:
                                    transaction.details.details.description,
                                });
                                this.openViewGameCardClaim();
                              }}
                            />
                          ) : transaction.details.details.mainType ===
                          "voucher" ? (
                            <img
                              className="ml-1"
                              src={payoutArrow}
                              alt=""
                              height={"10px"}
                              width={"8px"}
                              onClick={() => {
                                this.setState({
                                  voucherCode:
                                    transaction.details.details.voucherCode,
                                  expiryDate:
                                    transaction.details.details.expiryDate,
                                  price: transaction.details.details.price,
                                  voucherImageUrl:
                                    transaction.details.details.imageUrl,
                                });
                                this.openViewVoucherCardClaim();
                              }}
                            />
                          ) : transaction.details.details.mainType ===
                          "product" ? (
                            <img
                              className="ml-1"
                              src={payoutArrow}
                              alt=""
                              height={"10px"}
                              width={"8px"}
                              onClick={() => {
                                this.setState({
                                  productName:
                                    transaction.details.details.userDetails
                                      .name,
                                  phonenumber:
                                    transaction.details.details.userDetails
                                      .phonenumber,
                                  address:
                                    transaction.details.details.userDetails
                                      .address,
                                  ImageUrl:
                                    transaction.details.details.imageUrl,
                                  productDescription:
                                    transaction.details.details.description,
                                });
                                this.openViewProductCardClaim();
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                );
              })
            : null}
          {!this.state.pagesEnd &&
          !this.state.restrictApi &&
          this.state.redemption &&
          this.state.redemption.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img
                  src={getCDNUrl("/images/loader_v2.gif")}
                  alt=""
                  height="30"
                />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  };

  //winnings tab
  PrizesTab = () => {
    return (
      <div>
        <div className="mx-auto wallet-view-transaction">
          <span className="text-center d-flex justify-content-center align-items-center pt-4 pb-4">
            <img
              src={clock}
              alt=""
              height={"12px"}
              width={"12px"}
              style={{ marginRight: "5px" }}
            />
            Only two months history will retain
          </span>
          {this.state.uniqueData &&
            this.state.uniqueData.map((transaction, i) => {
              return (
                <div
                  className="ml-2 pt-2"
                  key={transaction.id}
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ color: "#343434" }} className="row">
                    <div className=" col-12 text-left d-flex justify-content-between">
                      <div className={`ml-1`}>
                        <div
                          className="ml-2 mb-4"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {transaction.details.matchTitle}
                        </div>
                        <div
                          className="ml-2 mb-4"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {this.state.transactions.map((contestData) => {
                            if (
                              contestData.details.cwMatchId ===
                                transaction.details.cwMatchId &&
                              (contestData.details.coins !== null ||
                                contestData.details.coins !== "null" ||
                                contestData.details.coins !== 0 ||
                                contestData.details.coins !== undefined)
                            ) {
                              return (
                                <div className="mb-4">
                                  {contestData.details.contestTitle}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div
                        className="mr-4"
                        style={{
                          fontSize: "10px",
                          fontWeight: "normal",
                        }}
                      >
                        <div className="mb-4" style={{ opacity: "0.5" }}>
                          {formatDate(transaction.details.createdAt)}
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            opacity: 1,
                          }}
                        >
                          {this.state.transactions.map((contestData) => {
                            if (
                              contestData.details.cwMatchId ===
                                transaction.details.cwMatchId &&
                              (contestData.details.coins !== null ||
                                contestData.details.coins !== "null" ||
                                contestData.details.coins !== 0 ||
                                contestData.details.coins !== undefined)
                            ) {
                              return (
                                <div className="mb-4">
                                  {Globals.telco == "mobilink" ? (
                                    <img
                                      className="mr-2"
                                      src={jccoin}
                                      width={"19px"}
                                      height={"12px"}
                                      alt=""
                                    />
                                  ) : Globals.telco == "zain" ||
                                  localStorage.getItem("domain") ==
                                    "CricwickMena" ? (
                                    <img
                                      className="mr-2"
                                      src={menaCoin}
                                      width={"19px"}
                                      height={"12px"}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="mr-2"
                                      src={coin}
                                      width={"19px"}
                                      height={"12px"}
                                      alt=""
                                    />
                                  )}
                                  {kFormatter(contestData.details.coins)}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              );
            })}
          {!this.state.pagesEnd &&
          !this.state.restrictApi &&
          this.state.uniqueData &&
          this.state.uniqueData.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img
                  src={getCDNUrl("/images/loader_v2.gif")}
                  alt=""
                  height="30"
                />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  };

  //invite info card
  returnInviteInfoCard = (
    imageSrc = null,
    label = "",
    subLabel = "",
    style = {
      bgCl: "#FDF2F2",
      cl: "#B91414",
    }
  ) => {
    return (
      <div
        className={`text-left d-flex w-100 mr-4 ml-4`}
        style={{
          padding: "10px 20px",
          backgroundColor: "red",
          width: "contain",
          borderRadius: "5px",
          backgroundColor: style.bgCl,
        }}
      >
        <div className="w-50 d-flex">
          <div className={`my-auto ml-2`}>
            <img src={man} alt={`Money Bag`} width={32} height={`auto`} />
          </div>
          <div className={`ml-1`}>
            <div
              className="ml-2"
              style={{ fontSize: "19px", fontWeight: "800", color: style.cl }}
            >
              {label}
            </div>
            <div
              className="ml-2"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              {subLabel}
            </div>
          </div>
        </div>
        <div
          className="w-50 d-flex justify-content-end align-items-end"
          style={{ flexDirection: "column" }}
        >
          <a
            href="/fantasy/1"
            style={{
              color: "#B91414",
              borderBottom: "1px solid #B91414",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Invite Friends
          </a>
          <p>200k Coins per joiner</p>
        </div>
      </div>
    );
  };

  //invite history
  returnInviteHistoryRow = (description, date, prizePoint) => {
    return (
      <div
        className={`d-flex justify-content-between`}
        style={{
          padding: "20px 30px",
          borderTop: `1px solid #EDF7FE`,
        }}
      >
        <div>
          <div style={{ fontSize: "16px", fontWeight: "600" }}>
            {description}
          </div>
          <div
            style={{
              fontSize: "10px",
              fontWeight: "normal",
              opacity: "0.5",
            }}
          >
            {/* {formatDate(date)} */}
          </div>
        </div>
        <div style={{ fontSize: "19px", fontWeight: "800", color: "#0BBA9D" }}>
          {Globals.telco == "mobilink" ? (
            <img
              className="mr-1"
              width={"19px"}
              height={"12px"}
              src={jccoin}
              alt=""
            />
          ) : Globals.telco == "zain" ||
          localStorage.getItem("domain") == "CricwickMena" ? (
            <img
              className="mr-1"
              width={"19px"}
              height={"12px"}
              src={menaCoin}
              alt=""
            />
          ) : (
            <img
              className="mr-1"
              width={"19px"}
              height={"12px"}
              src={coin}
              alt=""
            />
          )}
          {kFormatter(`${prizePoint}`)}
        </div>
      </div>
    );
  };

  //invited tab
  InvitedTab = () => {
    return (
      <div>
        <div className="mx-auto wallet-view-transaction">
          <div className={`d-flex justify-content-center align-items-center`}>
            <div
              className={`d-flex justify-content-between mt-3 mb-3`}
              style={{
                width: "100%",
              }}
            >
              {this.returnInviteInfoCard(
                "moneyBag.png",
                this.state.invite && this.state.invite.length,
                "People Joined",
                {
                  bgCl: "#FDF2F2",
                  cl: "#B91414",
                }
              )}
            </div>
          </div>
          {this.state.invite && this.state.invite.length > 0
            ? this.state.invite.map((transaction) => {
                return !transaction.isInvite
                  ? this.returnInviteHistoryRow(
                      transaction.details.description,
                      transaction.details.createdAt,
                      transaction.details.coins
                    )
                  : null;
              })
            : null}
          {!this.state.pagesEnd &&
          !this.state.restrictApi &&
          this.state.invite &&
          this.state.invite.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img
                  src={getCDNUrl("/images/loader_v2.gif")}
                  alt=""
                  height="30"
                />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  };

  prize = () => {
    let prize = this.state.transactions[this.state.prizeOpened];
    return (
      <div
        className=" mb-5"
        style={{
          background: "#FFFFFF",
          borderTopLeftRadius: "35px",
          borderTopRightRadius: "35px",
        }}
      >
        <div className="mx-auto ">
          <div className="mt-4 d-flex justify-content-end">
            <div
              style={{
                fontSize: "20px",
                cursor: `pointer`,
              }}
              onClick={() =>
                this.setState({
                  prizeOpened: -1,
                })
              }
            >
              <i className="fa fa-times-circle mr-3" aria-hidden="true" />
            </div>
          </div>
          <div className="mt-4 text-center">
            <div className="mb-3" style={{ margin: "auto", width: "35%" }}>
              <img
                className="img-fluid"
                src={
                  prize.winningType !== "cash"
                    ? getCDNUrl(`/images/wallet/giftBox.png`)
                    : getCDNUrl(`/images/wallet/moneyBag.png`)
                }
                width={30}
                height={`auto`}
              />
            </div>
            <div>
              <span
                className="d-inline-block"
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: "#343434",
                }}
              >
                {prize.winningTitle}
              </span>
              <span
                className="d-inline-block mr-2"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#343434",
                }}
              >
                {prize.winningType !== "cash" ? `` : `PKR`}
              </span>
            </div>
            <div className={`w-100 mt-3`} style={{ width: "10%" }}>
              <div className={`d-flex justify-content-between mt-2`}>
                <div className={`ml-4`}>Match</div>
                <div className={`mr-4`}>{prize.matchTitle}</div>
              </div>
              <div className={`d-flex justify-content-between mt-2`}>
                <div className={`ml-4`}>Rank</div>
                <div className={`mr-4`}>{prize.winningRank}</div>
              </div>
              <div className={`d-flex justify-content-between mt-2`}>
                <div className={`ml-4`}>Date</div>
                <div className={`mr-4`}>{formatDate(prize.createdAt)}</div>
              </div>
            </div>
            {prize.winningType !== "cash" ? (
              <div className="my-2 mt-3 d-flex justify-content-center">
                <span>
                  <div
                    onClick={() => {
                      if (prize.winningType === "cash")
                        this.viewPayoutPressed();
                      else if (prize.winningUrl)
                        window.open(`${prize.winningUrl}`);
                    }}
                    style={{
                      color: "#F9F9FB",
                      lineHeight: "42px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                    className="wallet-button my-2"
                  >
                    {prize.winningType !== "cash" ? `Claim` : `Payout`}
                  </div>
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  //main wallet screen
  MainWallet() {
    return (
      <div id="main-section" className={`w-100`}>
        <Helmet />
        {this.state.isLoading ? (
          <Loading />
        ) : (
          //coins in wallet section
          <React.Fragment>
            {/* {Globals.telco !== "mobilink" ? ( */}
            <div
              className="card mb-5"
              style={{
                background: "transparent",
                border: "none",
                maxHeight: "400px",
                height: "max-content",
              }}
            >
              <div className="mx-auto ">
                <div className=" my-4 wallet-card text-center">
                  <div
                    className="py-3 mb-4"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#343434",
                    }}
                  />
                  <div
                    className="mb-3"
                    style={{ margin: "auto", width: "35%" }}
                  >
                    <div
                      className={`d-flex justify-content-center align-items-center mt-5 mb-5`}
                    >
                      {localStorage.getItem("domain") == "CricwickMena" ||
                      Globals.telco == "zain" ? (
                        <>
                          <img src={menaWallet} alt="" />
                          <Lottie
                            options={this.state.defaultLottie}
                            height={90}
                            style={{ position: "absolute" }}
                          />
                        </>
                      ) : (
                        <Lottie
                          options={this.state.defaultOptions}
                          height={90}
                          style={{ position: "absolute" }}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <span
                      className="d-inline-block"
                      style={{
                        fontSize: "36px",
                        fontWeight: "bold",
                        color: "#006343",
                      }}
                    >
                      {this.state.availableCoins
                        ? kFormatter(this.state.availableCoins)
                        : 0}
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontWeight: "bold",
                      color: "#006343",
                    }}
                  >
                    Coins in Wallet
                  </div>
                    <div className="my-2 mt-4 pb-4 text-center mx-auto">
                    {/* {localStorage.getItem("domain") != "CricwickMena" && Globals.telco != "zain" ? ( */}
                    <span>
                      <div
                        onClick={() => (window.location.href = "/redeemprize")}
                        style={{
                          color: "#F9F9FB",
                          lineHeight: "42px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          marginLeft: "23%",
                        }}
                        className="wallet-button my-2"
                      >
                        Claim Prizes
                      </div>
                    </span>
                     {/* ): null} */}
                  </div>
                </div>
              </div>
            </div>
            {/* // ) : null} */}
          </React.Fragment>
        )}
        {/* payout section */}
        {this.state.wallet &&
        Globals.telco !== "mobilink" &&
        this.state.wallet.availableBalance > 0 ? (
          <div className={`${this.state.customCssTransition} mr-4 ml-4 pb-3`}>
            <div
              className="card p-3 d-flex align-items-center"
              style={{ flexDirection: "row" }}
            >
              <img
                className="img-fluid"
                src={getCDNUrl("/images/trivia/assets/wallet.png")}
                width={"28px"}
                height={"27px"}
              />
              <div className="d-flex pl-4" style={{ flexDirection: "column" }}>
                <span
                  className="d-inline-block"
                  style={{
                    fontSize: "36px",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {this.state.wallet ? this.state.wallet.availableBalance : 0}
                  <span
                    className="d-inline-block mr-2"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "#343434",
                    }}
                  >
                    PKR
                  </span>
                </span>
                <span style={{ color: "#B91414" }}>
                  *You can only withdraw increments of <b>50</b>
                </span>
              </div>
              <div className="my-2 mt-2 text-center ml-auto">
                <span>
                  <div
                    onClick={() => this.viewPayoutPressed()}
                    style={{
                      color: "#B91414",
                      lineHeight: "42px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                    className="contest-wallet-button my-2"
                  >
                    Payout
                  </div>
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.isTransactionsLoading ? (
          <Loading />
        ) : (
          <div className="px-lg-0 jc-inner-page align-self-start w-100 m-0">
            {this.state.isLoading ? (
              <div className="main-loader card min-height-80vh">
                <img
                  width="64"
                  height="64"
                  src={getCDNUrl("/images/loader_v2.gif")}
                  alt=""
                />
              </div>
            ) : (
              <div />
            )}

            {/* tabs */}
            <div className={`${this.state.customCssTransition} w-100`}>
              <div className="card pt-3 pr-3 pl-3 pb-0">
                <div className="d-flex overflow-x-auto justify-content-between">
                  <Link
                    to={`/wallet`}
                    onClick={() =>
                      this.setState({
                        tabsIndex: 0,
                      })
                    }
                  >
                    <h1
                      style={
                        this.state.tabsIndex === 0
                          ? {
                              lineHeight: "1.5",
                              borderBottom: "3px solid #b91414",
                            }
                          : { lineHeight: "1.5" }
                      }
                      className={
                        this.state.tabsIndex === 0
                          ? "border-danger text-danger font-weight-bold p-2  m-f-14 mb-0"
                          : "font-weight-bold p-2  m-f-14"
                      }
                    >
                      Winnings
                    </h1>
                  </Link>
                  <Link
                    to={`/wallet`}
                    onClick={() =>
                      this.setState({
                        tabsIndex: 1,
                        prizeOpened: -1,
                      })
                    }
                  >
                    <h1
                      style={
                        this.state.tabsIndex === 1
                          ? {
                              lineHeight: "1.5",
                              borderBottom: "3px solid #b91414",
                            }
                          : { lineHeight: "1.5" }
                      }
                      className={
                        this.state.tabsIndex === 1
                          ? "border-danger text-danger font-weight-bold p-2  m-f-14 mb-0"
                          : "font-weight-bold p-2  m-f-14"
                      }
                    >
                      Redemptions
                    </h1>
                  </Link>
                  {(Globals.telco !== "mobilink" && Globals.telco !== "zain" && localStorage.getItem("domain")!== "CricwickMena") ? (
                    <Link
                      to={`/wallet`}
                      onClick={() =>
                        this.setState({
                          tabsIndex: 2,
                        })
                      }
                    >
                      <h1
                        style={
                          this.state.tabsIndex === 2
                            ? {
                                lineHeight: "1.5",
                                borderBottom: "3px solid #b91414",
                              }
                            : { lineHeight: "1.5" }
                        }
                        className={
                          this.state.tabsIndex === 2
                            ? "border-danger text-danger font-weight-bold p-2  m-f-14 mb-0"
                            : "font-weight-bold p-2  m-f-14"
                        }
                      >
                        Invited
                      </h1>
                    </Link>
                  ) : null}
                </div>
              </div>

              <div className="">
                {this.state.prizeOpened !== -1
                  ? this.prize()
                  : this.state.tabsIndex === 0
                    ? this.PrizesTab()
                    : this.state.tabsIndex === 1
                      ? this.TransactionsTab()
                      : this.InvitedTab()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  //payout
  ViewPayout() {
    return (
      <PaymentMethods
        msisdn={this.encrypt(this.state.msisdn)}
        walletCash={this.state.wallet.availableBalance}
        backPressed={this.backPressed}
        minimumPayout={this.state.minimumPayout}
        onPayoutClicked={this.getWalletAndTransactionInfo}
      />
    );
  }

  //to close modal by clicking on it
  onOverlayClick(e) {
    this.closeModal();
    e.stopPropagation();
  }

  render() {
    return (
      <>
        {this.state.setModal && (
          <Modal
            isOpen={this.state.setModal}
            onClick={(e) => this.onOverlayClick(e)}
            className="d-flex justify-content-center"
            style={{ maxWidth: "390px", width: "100%" }}
          >
            {/* game card */}
            {this.state.gameCard ? (
              <ModalBody
                className="modal-body p-0"
                onClick={() => this.closeModal()}
              >
                <div className="col text-center justify-content-center p-0">
                  <div className="pb-1">
                    <img
                      src={this.state.gameImageUrl}
                      alt=""
                      width={"100%"}
                      maxWidth={"390px"}
                      height={"83px"}
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                    <div className="row align-items-center justify-content-center">
                      {/* {Globals.telco == "mobilink" ? (
                      <img
                        src={jccoin}
                        alt=""
                        style={{ paddingTop: "1.3rem", marginRight: "3px" }}
                      />
                      ): (
                        <img
                        src={coin}
                        alt=""
                        style={{ paddingTop: "1.3rem", marginRight: "3px" }}
                      />
                      )} */}
                      <h4 className="font-weight-bold red pt-4 mb-0">
                        {this.state.gameDescription}
                      </h4>
                    </div>
                  </div>
                  <div
                    className="d-flex p-4 justify-content-between"
                    style={{ flexDirection: "row" }}
                  >
                    <h2 className="w-40" style={{ textAlign: "start" }}>
                      Player ID
                    </h2>
                    <h2 className="w-60" style={{ textAlign: "start" }}>
                      {this.state.gameId}
                    </h2>
                  </div>
                  <div
                    className="d-flex p-4 justify-content-between"
                    style={{ flexDirection: "row" }}
                  >
                    <h2 className="w-40" style={{ textAlign: "start" }}>
                      Name
                    </h2>
                    <h2 className="w-60" style={{ textAlign: "start" }}>
                      {this.state.gameName}
                    </h2>
                  </div>

                  <p
                    className="p-4 ml-4 mr-4"
                    style={{ background: "rgba(255, 153, 102, 0.15)" }}
                  >
                    <p
                      className="mb-0 font-weight-bold"
                      style={{ color: "#E86726" }}
                    >
                      You will receive your {this.state.gameDescription} in{" "}
                      {this.state.gameTitle} with in a week.
                    </p>
                  </p>
                </div>
              </ModalBody>
            ) : this.state.voucherCard ? ( //voucher card
              <ModalBody
                className="modal-body p-0"
                onClick={() => this.closeModal()}
              >
                <div className="col text-center justify-content-center p-0">
                  <div className="pb-1">
                    <img
                      src={this.state.voucherImageUrl}
                      alt=""
                      width={"100%"}
                      maxWidth={"390px"}
                      height={"83px"}
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                    <h4 className="font-weight-bold pt-4 mb-0">VOUCHER</h4>
                  </div>
                  <div className="col pb-3">
                    <h5 className="red mt-2 pb-4">
                      Pkr. <b>{this.state.price}</b>
                    </h5>
                    <QRCode
                      value={this.state.voucherCode}
                      style={{ height: "136px", width: "136px" }}
                      size={136}
                      viewBox={`0 0 136 136`}
                    />
                    <p className="mb-2 mt-4">Voucher Code</p>
                    <h2 className="font-weight-bold">
                      {" "}
                      {this.state.voucherCode}{" "}
                      <img
                        src={tick}
                        height={"10px"}
                        width={"10px"}
                        onClick={() => {
                          navigator.clipboard.writeText(this.state.voucherCode);
                        }}
                      />
                    </h2>
                    <p className="red">
                      Expires on {formatDate(this.state.expiryDate)}
                    </p>
                  </div>
                </div>
              </ModalBody>
            ) : this.state.productCard ? ( //product card
              <ModalBody className="modal-body p-0">
                <div className="col text-center justify-content-center p-0">
                  <div className="pb-1">
                    <img
                      src={this.state.imageUrl}
                      alt=""
                      width={"100%"}
                      maxWidth={"390px"}
                      height={"83px"}
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                    <h4 className="font-weight-bold red pt-4 mb-0">
                      {this.state.productDescription}
                    </h4>
                  </div>
                  <div
                    className="d-flex p-4 justify-content-between"
                    style={{ flexDirection: "row" }}
                  >
                    <h2 className="w-40" style={{ textAlign: "start" }}>
                      Name
                    </h2>
                    <h2 className="w-60" style={{ textAlign: "start" }}>
                      {this.state.productName}
                    </h2>
                  </div>

                  <div
                    className="d-flex p-4 justify-content-between"
                    style={{ flexDirection: "row" }}
                  >
                    <h2 className="w-40" style={{ textAlign: "start" }}>
                      Number
                    </h2>
                    <h2 className="w-60" style={{ textAlign: "start" }}>
                      {this.state.phonenumber}
                    </h2>
                  </div>

                  <div
                    className="d-flex p-4 justify-content-between"
                    style={{ flexDirection: "row" }}
                  >
                    <h2 className="w-40" style={{ textAlign: "start" }}>
                      Address
                    </h2>
                    <h2 className="w-60" style={{ textAlign: "start" }}>
                      {this.state.address}
                    </h2>
                  </div>
                  <p
                    className="p-4 ml-4 mr-4"
                    style={{ background: "rgba(255, 153, 102, 0.15)" }}
                  >
                    <p
                      className="mb-0 font-weight-bold"
                      style={{ color: "#E86726" }}
                      onClick={() => this.closeModal()}
                    >
                      You will receive your prize with in a week.
                    </p>
                  </p>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexDirection: "column" }}
                  >
                    {/* <button
                      className="bg-red b my-2 text-light pr-4 pl-4 border-0 font-weight-bold p-3 mb-4"
                      style={{
                        borderRadius: "79px",
                        fontSize: "14px",
                        width: "198px",
                      }}
                      onClick={() => this.closeModal()}
                    >
                      Confirm
                    </button> */}
                  </div>
                </div>
              </ModalBody>
            ) : null}
          </Modal>
        )}
        <React.Fragment>
          {Globals.telco !== "mobilink" ? (
            <Helmet>
              <title>{`Cricwick - Wallet`}</title>
              <meta name="description" content={`Cricwick - Wallet`} />
            </Helmet>
          ) : null}
          {this.state.currentPage === 0
            ? this.MainWallet()
            : this.state.currentPage === 1
              ? this.ViewTransaction()
              : this.state.currentPage === 2
                ? this.ViewPayout()
                : null}
        </React.Fragment>
      </>
    );
  }
}

export default withRouter(ContestWallet);
