import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "react-background-image-loader";

import axios from "axios";

import { Globals } from "./constants";
import {
  getCDNUrl,
  VideoDescriptionCardNew,
  canonicalLink,
} from "./HelperFunctions";

import { Helmet } from "react-helmet";

class Playlists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      apiPath: Globals.ApiUrl + "api/v4/",
      page: 0,
      pagesEnd: false,
      playlists: [],
    };

    this.nextPage = this.nextPage.bind(this);

    //this.windowLoadListener = false;
  }

  componentWillMount = (async) => {
    this.getPlaylistsFromAPI().then((response) => {
      if (
        response.status === 200 &&
        response.data &&
        response.data.resp.length > 0
      ) {
        this.setState({
          isLoading: false,
          playlists: this.state.playlists.concat(response.data.resp),
        });
      }
    });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  getPlaylistsFromAPI() {
    let apiPath = this.state.apiPath;
    let page = this.state.page + 1;

    let playlistsApiURL =
      apiPath +
      "video_lists/get_video_list_items?list_id=2&type=play_list&page=" +
      page +
      "&app_name=" +
      `${
        localStorage.getItem("domain")
          ? localStorage.getItem("domain")
          : Globals.telco
            ? Globals.appName
            : Globals.appName
      }`;

    this.setState({ page: page });

    try {
      return axios.get(playlistsApiURL);
    } catch {
      return [];
    }
  }

  async nextPage(e) {
    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName("loading-more-results");

      if (elem.length) {
        elem[0].classList.remove("d-none");
      }

      this.getPlaylistsFromAPI().then((response) => {
        if (response.status === 200) {
          if (elem.length) {
            elem[0].classList.add("d-none");
          }

          if (response.data && response.data.resp.length > 0) {
            this.setState({
              playlists: this.state.playlists.concat(response.data.resp),
            });
          } else if (response.data.resp.length === 0) {
            this.setState({ pagesEnd: true });
          }
        } else {
          if (elem.length) {
            elem[0].classList.add("d-none");
          }
        }
      });
    }
  }

  renderPlaylists() {
    return this.state.playlists.map((playlist, i) => {
      return <Playlist key={i} playlist={playlist.data} />;
    });
  }

  render() {
    return (
      <section id="main-section" className="align-self-start">
        <Helmet>
          <title>
            Get the Latest Cricket Action and Watch Key Moments on{" "}
            {Globals.product}
          </title>
          <meta
            name="description"
            content={
              "Watch Match Highlights, Interviews, Special Features, Latest Events and more " +
              "- " +
              Globals.product +
              " Playlist"
            }
          />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img
              width="64"
              height="64"
              src={getCDNUrl("/images/loader_v2.gif")}
              alt=""
            />
          </div>
        ) : (
          <div />
        )}
        <div className="card p-3">
          <div className="d-flex overflow-x-auto">
            <h1 className={"font-weight-semi-bold p-2 m-f-14 "}>Playlists</h1>
          </div>
        </div>

        <div className="mt-2">
          <div className="card px-3 py-0">
            {this.state.playlists && this.state.playlists.length > 0
              ? this.renderPlaylists()
              : ""}
          </div>
        </div>
        {!this.state.pagesEnd && this.state.playlists.length ? (
          <div>
            <div className="text-center d-none loading-more-results">
              <img
                src={getCDNUrl("/images/loader_v2.gif")}
                alt=""
                height="30"
              />
            </div>
            <button
              id="more-results"
              onClick={this.nextPage}
              type="button"
              className="btn btn-primary d-none"
            >
              More Results
            </button>
          </div>
        ) : (
          <div />
        )}
      </section>
    );
  }
}

const Playlist = (props) => (
  <Link
    to={
      "/playlist/" +
      props.playlist.id +
      "/video/" +
      props.playlist.top_list_item.video.id +
      "/" +
      props.playlist.title
    }
    className="d-flex p-2 mvlist"
  >
    <div className="w-30 pr-3 position-relative">
      <BackgroundImage
        className="sixteen-by-nine bg-center-cover"
        src={props.playlist.thumb}
        placeholder={Globals.placeholderPlain4x3}
      >
        <div className="plist-stack">
          <div className="plist-container">
            <p>
              <img
                className="plist-icon"
                src={getCDNUrl("/images/playlist_icon.svg")}
              />
              <br />
              <bold>{props.playlist.total_videos}</bold>
              <br />
              Videos
            </p>
          </div>
        </div>
      </BackgroundImage>
    </div>
    <div className="mt-2">
      <VideoDescriptionCardNew
        title={props.playlist.title}
        description={props.playlist.description}
        data={props.playlist.top_list_item.video}
        showDescription={true}
        showDate={true}
        showViews={false}
      />
    </div>
  </Link>
);

export default Playlists;
