import React from 'react'
import Loading from './Loading'

import './trivia/trivia.css'
import './App.css'
import TermsnConditions from './TermsnConditions';
import { Helmet } from 'react-helmet';
import { canonicalLink } from "./HelperFunctions";


class AppTermsAndConditions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            tc: true,
            operatorName: this.props.operatorName ? this.props.operatorName : this.props.match.params.operator
        }
    }

    async componentDidMount() {
        // api call for data
    }
    FaqPressed() {
        var current = this.state.faq;
        this.setState({
            faq: !current
        })
    }
    MasterBlasterPressed() {
        var current = this.state.mbp;
        this.setState({
            mbp: !current
        })
    }
    GameRulesPressed() {
        var current = this.state.gr;
        this.setState({
            gr: !current
        })
    }
    TCPressed() {
        var current = this.state.tc;
        this.setState({
            tc: !current
        })
    }

    render() {

        return (
            ""
        );
    }
}

export default AppTermsAndConditions;