import React, { Component } from "react";
import { Row } from "reactstrap";
import { canonicalLink } from "./HelperFunctions";
import { Helmet } from "react-helmet";

class NotFound404 extends Component {
  render() {
    return (
      <section
        id="main-section"
        className="card h-auto px-lg-0 custom-match-scorecard jc-inner-page"
      >
        <Helmet>
          <link rel="canonical" href={canonicalLink()} />
          <title>404 - Page Not Found</title>
          <meta name="description" content={`404 - Page Not Found`} />
        </Helmet>
        <div className="err-img">
          <img src="/images/banner_error_404.jpg" />
        </div>
        <h1 className="m-5 text-center">
          Oops… looks like something went wrong! This page does not exist or has
          been moved.
        </h1>
        <h3 className="align-items-center text-center">
          Visit our{" "}
          <b>
            <a href="/">homepage</a>
          </b>
        </h3>
      </section>
    );
  }
}

export default NotFound404;
