import React, { Component } from "react";
import { getCDNUrl } from "../HelperFunctions";
import packageCardStyles from "./packageCard.module.css";
import mobile from "../images/mobile.png";

class TelcoPackageCard extends Component {
  state = {};
  render() {
    return (
      <div
        className={`d-flex justify-content-between mt-2 mb-4 p-4 ${
          this.props.onClick ? packageCardStyles.bundleBox1 : ``
        }`}
        id={`${packageCardStyles.bundleBox}`}
        style={{
          cursor: !this.props.onClick ? `` : `pointer`,
        }}
        onClick={() => {
          if (this.props.onClick) this.props.onClick();
        }}
      >
        <div className="d-flex flex-row">
          <div className={`d-flex justify-content-center align-items-center`}>
            <img src={mobile} alt="" width={40} height={30} />
          </div>
          <div>
            <div className={`d-flex justify-content-between `}>
              <div className="d-flex flex-column justify-content-start align-items-center">
                <div className="">
                  <span
                    className={`${packageCardStyles.packageDuration}`}
                    style={{ color: "#db4343" }}
                  >
                    {" "}
                    {`Pay by Mobile`}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`d-flex justify-content-between flex-wrap align-items-center mt-1`}
            >
              {this.props.telco === "mobilink" ? (
                <div
                  className={`d-flex justify-content-center align-items-center mr-3`}
                >
                  <img
                    src={getCDNUrl(`/images/telcos/jazz_logo.png`)}
                    alt=""
                    width={70}
                    height={35}
                  />
                </div>
              ) : (
                // <React.Fragment>
                //   <div
                //     className={`d-flex justify-content-center align-items-center mr-3`}
                //   >
                //     <img
                //       src={getCDNUrl(`/images/telcos/telenor_logo.png`)}
                //       alt=""
                //       width={70}
                //       height={35}
                //     />
                //   </div>

                //   <div
                //     className={`d-flex justify-content-center align-items-center mr-3`}
                //   >
                //     <img
                //       src={getCDNUrl(`/images/telcos/ufone_logo.png`)}
                //       alt=""
                //       width={70}
                //       height={35}
                //     />
                //   </div>

                //   <div
                //     className={`d-flex justify-content-center align-items-center mr-3`}
                //   >
                //     <img
                //       src={getCDNUrl(`/images/telcos/zong_logo.png`)}
                //       alt=""
                //       width={70}
                //       height={35}
                //     />
                //   </div>
                // </React.Fragment>
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  Subscribe Daily Package
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={`d-flex justify-content-center align-items-center`}>
          <span className="">
            <i className="fa fa-chevron-right" />
          </span>
        </div>
      </div>
    );
  }
}

export default TelcoPackageCard;
