import React, { Component } from "react";
import { Globals } from "../constants";

import { Helmet } from "react-helmet";
import { loginUser, storeUserfromIframe } from "../HelperFunctions";

class FantasyView extends Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user", user);
    this.xAccessToken = user ? user.xAccessToken : "";
    this.xAccessToken = window.btoa(this.xAccessToken); // bas64 encoding
    this.msisdn = user ? user.phone : "";
    this.isInvtie = this.props.match.params.isInvite;
    //console.log("user", user, user.xAccessToken)

    // testcricket.s3-website-us-east-1.amazonaws.com

    if (user) {
      this.fantasyVerificationURL = Globals.FantasyWebUrl + "authentication/"; // live
      this.fantasyVerificationURL = `${this.fantasyVerificationURL}${
        this.xAccessToken
      }/${this.msisdn}/${user.inviteCode}/${user.subscriptionStatus}/${
        user.id
      }/${user.nextClaimDate}/${user.checkInStreak}/${this.isInvtie}/${
        window.location.search
      }${window.location.search.length ? "&" : "?"}domain=${
        Globals.telco == "zain" || localStorage.getItem("domain")
          ? "mena"
          : Globals.telco == "mobilink"
            ? "jazzcricket"
            : "cricwick"
      }#utm_source=${Globals.appName}&utm_medium=web&utm_campaign=MyStars11`;
    } else {
      this.fantasyVerificationURL =
        Globals.FantasyWebUrl + "free-authentication/"; // live
      this.fantasyVerificationURL = `${this.fantasyVerificationURL}${
        window.location.search
      }${
        //fantasy.cricwick.net
        window.location.search.length ? "&" : "?"
      }domain=${
        Globals.telco == "zain" || localStorage.getItem("domain")
          ? "mena"
          : Globals.telco == "mobilink"
            ? "jazzcricket"
            : "cricwick"
      }#utm_source=${Globals.appName}&utm_medium=web&utm_campaign=MyStars11`;
    }
  }
  componentDidMount() {
    storeUserfromIframe();
  }
  render() {
    return (
      <div
        id="main-section"
        className="holds-the-iframe"
        style={{ backgroundColor: "#FFFFFF", zIndex: "0" }}
      >
        <Helmet>
          <title>
            Play Fantasy League for free and Win Exciting Cash Prizes -
            {Globals.product}
          </title>
          <meta
            name="description"
            content={
              "Play Fantasy League for free. Create your team today, beat the competition and win exciting cash prizes on MyStars11, only on " +
              Globals.product
            }
          />
        </Helmet>
        <iframe
          title="fantasy"
          src={this.fantasyVerificationURL}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        />
      </div>
    );
  }
}

export default FantasyView;
