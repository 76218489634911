// import { render } from '@testing-library/react';
import React from "react";
import Timer from "react-compound-timer/build";

export default function TimerComponent(props) {
  return (
    <Timer
      initialTime={props.timeRemaining}
      direction="backward"
      timeToUpdate={500}
      onStop={() => {
        window.location.reload();
      }}
      checkpoints={[
        {
          time: 0,
          callback: () => {
            window.location.reload();
          },
        },
      ]}
    >
      <Timer.Hours />h : <Timer.Minutes />m : <Timer.Seconds />s
    </Timer>
  );
}
