import React, { Component } from "react";
import { getCDNUrl } from "../HelperFunctions";

class FantasyAuthentication extends Component {
  componentDidMount() {
    let user = {
      call_type: "confirmpin",
      isEasypaisaSubscription: false,
      message: "",
      phone: this.props.match.params.msisdn,
      setTime: Date.now(),
      subscribed: 1,
      telco: this.props.match.params.telco,
      xAccessToken: this.props.match.params.token,
    };

    localStorage.setItem("user_logged_in", true);
    localStorage.setItem("user", JSON.stringify(user));

    setTimeout((window.location.href = "/fantasy"), 2000);
  }

  render() {
    return (
      <div className="main-loader card min-height-80vh">
        <img
          width="64"
          height="64"
          src={getCDNUrl("/images/loader_v2.gif")}
          alt=""
        />
      </div>
    );
  }
}

export default FantasyAuthentication;
