import React from "react";
import { Helmet } from "react-helmet";
import { Globals } from "./constants";
import { canonicalLink } from "./HelperFunctions";

class MyStars11FAQs extends React.Component {
  constructor(props) {
    super(props);

    let telco, domain;

    if (
      this.props &&
      this.props.telco &&
      this.props.telco.length > 0 &&
      this.props.domain &&
      this.props.domain.length > 0
    ) {
      telco = this.props.telco;
      domain = this.props.domain;
    } else {
      telco = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).telco
        : "";
      domain = Globals.appName;
    }

    this.state = {
      telco: telco,
      domain: domain,
    };
  }

  render() {
    return (
      <div
        id="main-section"
        className="holds-the-iframe"
        style={{ backgroundColor: "#FFFFFF", zIndex: "0" }}
      >
        <Helmet>
          <title>My Stars 11 - Frequently Answered Questions</title>
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta
            name="apple-mobile-web-app-title"
            content="Check out Frequently Questions for My Stars 11 Fantasy League on CricWick"
          />
          <link rel="canonical" href={canonicalLink()} />
        </Helmet>
        {this.state.domain.toLowerCase() === "jazzcricketweb" ? (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/My+Stars+11+FAQ+-+JC.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        ) : (
          <iframe
            title="pdf"
            src="https://cricwick-fantasy.s3-us-west-2.amazonaws.com/TermsAndConditions/My+Stars+11+FAQ+-+Cricwick.pdf"
            style={{ width: "100%", height: "1080px" }}
            frameBorder="0"
          />
        )}
      </div>
    );
  }
}

export default MyStars11FAQs;
